import React from 'react';
import { ListGroupItem, ListGroup, Media } from 'reactstrap';
import {
  makeExternalLink,
  getPresetByType,
  quickLinkPresets,
  allPreset,
  showLoading
} from '../Helpers';

const QuickLinkCard = ({ event }) => {
  const { links } = event[event.type];
  const logo = require('../../assets/images/loophq-icon.svg');
  const presetName = getPresetByType(event.type);
  const presets = quickLinkPresets[presetName] || allPreset;
  return (
    <>
      <ListGroup className="quickLinks mb-4">
        {links.map((ql, index) => {
          if(ql.link) {
            ql.link = makeExternalLink(ql.link);
          }         
          let iconItem = presets.find(icon => icon.id === ql.icon);
          if (!iconItem) {
            iconItem = logo;
          } else {
            iconItem = iconItem.icon;
          }
          return (
            <ListGroupItem
              className="quick-link-card"
              key={'quicklink_' + index}
            >
              <Media>
                <Media left>
                  <Media object src={iconItem} alt="" />
                </Media>
                <Media body>
                  <Media heading>{ql.title}</Media>
                  {ql.shortDescription}
                </Media>
                {ql.linkText && !!ql.linkText.trim().length && (
                  <Media right>
                    <a
                      className="btn btn-outline-primary btn-sm external-link"
                      href={makeExternalLink(ql.link)}
                      onClick={showLoading}
                    >
                      {ql.linkText}
                    </a>
                  </Media>
                )}
              </Media>
              {/*             
              <div className="row no-gutters">
                <div className="col-auto">
                  <img src={logo} alt="Logo" />
                  <span>{ql.title}</span>
                  <span>{ql.shortDescription}</span>
                </div>
                <div className="col">
                  <a className="btn btn-primary btn-sm" href={ql.link}>
                    {ql.linkText}
                  </a>
                </div>
              </div> */}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </>
  );
};

export default QuickLinkCard;
