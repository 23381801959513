import React, { Component } from 'react';
import { Input, Form, Container, Row, Col } from 'reactstrap';

import { Button } from '../../components';
import { toLocal } from '../Helpers';
import { db } from '../../../firebase/firebase';

class Start extends Component {
  state = {
    isBusy: false,
    isPlaying: false,
    progress: {
      answers: []
    },
    input: {
      email: '',
      name: ''
    }
  };

  componentDidMount() {
    this.checkProgress(true);
  }

  checkProgress = async (isInitial = true) => {
    const { event } = this.props;
    const { uid } = this.props;
    this.setState({ isBusy: true });
    try {
      const progressSnap = await db
        .collection('progress')
        .where('eventId', '==', event.id)
        .where('uid', '==', uid)
        .limit(1)
        .get();
      if (progressSnap.docs.length) {
        this.setState({
          isPlaying: true,
          progress: {
            id: progressSnap.docs[0].id,
            ...progressSnap.docs[0].data()
          }
        });
      } else if (!isInitial) {
        const {
          input: { email, name }
        } = this.state;
        const doc = db.collection('progress').doc();
        const progress = {
          id: doc.id,
          email,
          name,
          uid,
          isComplete: false,
          isMatch: event.type === 'MatchTest',
          eventId: event.id,
          answers: []
        };
        await doc.set(progress);
        this.setState({ progress, isPlaying: true });
      }
    } catch (exp) {
      console.log(exp.message);
    }
    this.setState({ isBusy: false });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const {
      input: { email, name }
    } = this.state;
    const { onStart, event } = this.props;
    const { collectEmail } = event[event.type];
    if (collectEmail === 'required' && (!email || !email.includes('@'))) {
      alert('Please enter your email.');
      return;
    }
    if (!name || !name.trim().length) {
      alert('Please enter your full name.');
      return;
    }
    onStart();
    this.checkProgress(false);
  };

  handleInput = ({ nativeEvent: { target } }) => {
    const { input } = this.state;
    input[target.id] = target.value;
    this.setState({ input });
  };

  handleAnswer = (index, answer) => {
    const { progress } = this.state;
    const { event } = this.props;
    if (answer && answer.trim()) {
      progress.answers[index] = answer;
      const isComplete = index === event[event.type].questions.length - 1;
      progress.isComplete = isComplete;
      db.collection('progress')
        .doc(progress.id)
        .update({
          isComplete,
          answers: progress.answers
        });
    }
  };

  render() {
    const { event, onDone, triviaText, Play } = this.props;
    const {
      isBusy,
      isPlaying,
      progress: { answers }
    } = this.state;
    const isTrivia = event.type === 'Trivia';
    const { questions, collectEmail } = event[event.type];
    if (isPlaying) {
      return (
        <Play
          onAnswer={this.handleAnswer}
          answers={answers}
          questions={questions}
          onDone={onDone}
        />
      );
    }
    return (
      <Container>
        <div className="closeBtnTrivia">
          <ion-icon name="close" onClick={onDone} />
        </div>
        <Row className="text-left justify-content-center pt-5 pb-4">
          <Col sm={12} md={8}>
            <h3 className="text-center">{event.title}</h3>
            <p className="mb-0">
              Number of questions: <strong>{questions.length}</strong>
            </p>
            {isTrivia && (
              <p className="mb-0">
                End time:{' '}
                <strong>{toLocal(event.utcEnd).format('hh:mm A')}</strong>
              </p>
            )}
            <Form onSubmit={this.handleSubmit}>
              {!isBusy && (
                <>
                  <div className="mt-2">
                    <label>Name</label>
                    <Input
                      type="text"
                      id="name"
                      required
                      placeholder="Enter your name"
                      className="mb-2 mt-1"
                      onChange={this.handleInput}
                    />
                  </div>
                  {collectEmail !== 'no' && (
                    <>
                      <div>
                        <label>
                          Email (
                          {collectEmail === 'required'
                            ? 'required by organizer'
                            : 'optional'}
                          )
                        </label>
                        <Input
                          type="email"
                          id="email"
                          required={collectEmail === 'required'}
                          placeholder="Enter your email address"
                          className="mb-2 mt-1"
                          onChange={this.handleInput}
                        />
                      </div>

                      <p className="text-center" />
                    </>
                  )}

                  {isTrivia && (
                    <p className="text-center">
                      All answers will auto save, so you can close the window
                      and come back later if you want.
                    </p>
                  )}
                </>
              )}
              <Button isBusy={isBusy} block color="primary" type="submit">
                {triviaText}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Start;
