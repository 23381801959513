import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'reactstrap';
import moment from 'moment';

import EventCard from './EventCard';
import { Button, withMessage } from '../../components';
import { db } from '../../../firebase/firebase';
import Start from '../trivia/Start';
import MatchResult from '../match/Result';
import Play from '../match/Play';
import { toLocal } from '../Helpers';

class MatchCard extends React.Component {
  state = {
    btnText: 'Start',
    result: null,
    isCalculating: false,
    isLoading: true,
    isModalOpen: false
  };

  async componentDidMount() {
    try {
      const {
        event,
        match: {
          params: { uid }
        }
      } = this.props;
      const progressSnap = await db
        .collection('progress')
        .where('eventId', '==', event.id)
        .where('uid', '==', uid)
        .limit(1)
        .get();
      const hasData = !!progressSnap.docs.length;
      this.setState({
        result: hasData && progressSnap.docs[0].data().result,
        isComplete: progressSnap.docs[0].data().isComplete,
        btnText: hasData ? 'Continue' : 'Start'
      });
    } catch (exp) {
      // ignore
    } finally {
      this.setState({ isLoading: false });
    }
  }

  startCalculation = async () => {
    // we will call the backend function to calculate result
    // this will update all user's result
    // this.setState({ isCalculating: true });
    const {
      // event,
      // match: {
      //   params: { uid }
      // },
      // showWarning,
      showSuccess
    } = this.props;
    showSuccess(
      'Match saved',
      'Please check your result after this has ended.'
    );
    this.setState({ isComplete: true });
    // try {
    //   const result = await functions.httpsCallable('calculateMatchResult')({
    //     id: event.id,
    //     uid
    //   });
    //   if (result.data.success) {
    //     this.setState({ result: result.data.data });
    //   } else {
    //     showWarning('No result', 'Internal error.');
    //   }
    // } catch (exp) {
    //   showError('Error occurred', exp.message);
    // } finally {
    //   this.setState({ isCalculating: false });
    // }
  };

  toggleModal = () =>
    this.setState(state => ({
      isModalOpen: !state.isModalOpen
    }));

  renderView = showResult => {
    const { event, showMessage } = this.props;
    const { isComplete, btnText, isLoading } = this.state;
    const utcStart = toLocal(event.utcStart);
    const isComing = moment().isBefore(utcStart);
    const isMessageShown = showMessage && !showResult;
    if (showMessage) {
      return null;
    }
    if (isComing && !isMessageShown) {
      return (
        <div className="mb-2 text-left">
          Starts at <strong>{utcStart.format('hh:mm A')}</strong>
        </div>
      );
    }
    if (!isComplete || showResult) {
      return (
        <Button
          outline={showResult}
          isBusy={isLoading}
          onClick={this.toggleModal}
          color="primary"
          disabled={isComplete && !showResult}
        >
          {showResult ? 'View result' : btnText}
        </Button>
      );
    }
    return (
      <div>
        <small>
          Results will be at
          <strong>
            {' ' /* it can take upto 2-3 mins for results to be visible */}
            {toLocal(event.utcEnd)
              .add(3, 'minutes')
              .format('hh:mm A')}
          </strong>
        </small>
      </div>
    );
  };

  render() {
    const {
      event,
      match: {
        params: { uid }
      },
      eventLocation,
      showMessage
    } = this.props;
    const {
      btnText,
      isModalOpen,
      isLoading,
      isCalculating,
      result
    } = this.state;
    const now = moment();
    const showResult =
      !isCalculating &&
      !isLoading &&
      (!!result || now.isAfter(toLocal(event.utcEnd), 'ms'));
    return (
      <EventCard
        event={event}
        showMessage={showMessage}
        eventLocation={eventLocation}
      >
        {this.renderView(showResult)}
        <Modal isOpen={isModalOpen}>
          {!showResult && (
            <Start
              event={event}
              uid={uid}
              triviaText={btnText}
              Play={Play}
              onStart={() => this.setState({ btnText: 'Continue' })}
              onDone={hasCompleted => {
                this.setState({ isModalOpen: false });
                if (hasCompleted) {
                  this.startCalculation();
                }
              }}
            />
          )}
          {!!showResult && (
            <MatchResult
              onClose={() => this.setState({ isModalOpen: false })}
              result={result || []}
            />
          )}
        </Modal>
      </EventCard>
    );
  }
}

export default withRouter(withMessage(MatchCard));
