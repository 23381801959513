import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Form, Input } from 'reactstrap';

import { auth, firebase, Collections } from '../../firebase';
import logo from './../assets/images/loophq-icon.svg';
import { Button } from '../components';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

class Login extends Component {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE,
      isLoading: false
    };
    this.navigateTo = this.navigateTo.bind(this);
  }

  navigateTo(e) {
    e.preventDefault();
    this.props.history.push('/signup');
  }

  onSubmit = async event => {
    const { email, password } = this.state;
    const { history } = this.props;
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await auth.doSignInWithEmailAndPassword(email, password);
      const userDocs = await firebase.db
        .collection(Collections.Users)
        .where('userEmail', '==', email)
        .limit(1)
        .get();
      if (!userDocs.docs.length) {
        // user's profile do not exist
        this.setState({
          isLoading: false,
          error: { message: 'The profile was not found.' }
        });
        return;
      }
      localStorage.setItem('profile', JSON.stringify(userDocs.docs[0].data()));
      setTimeout(() =>
        history.push({
          pathname: '/home',
          state: {
            email
          }
        })
      );
    } catch (exp) {
      this.setState({ isLoading: false, error: exp });
    }
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { email, password, error, isLoading } = this.state;

    const isInvalid = password === '' || email === '' || isLoading;

    return (
      <Container className="text-center">
        <div className="pb-2 pt-5 text-center">
          <img
            className="d-block mx-auto mt-2 mb-4"
            src={logo}
            alt=""
            width="72"
            height="72"
          />
          <h3>Login</h3>
        </div>
        <div className="row justify-content-center">
          <div className="col col-sm-8 col-md-6 col-lg-4">
            <Form
              className="form-login"
              autoComplete="off"
              onSubmit={this.onSubmit}
            >
              <Input
                name="email"
                type="email"
                id="email"
                value={email}
                onChange={this.onChange}
                bsSize="lg"
                className="form-control mb-3"
                placeholder="Email address"
                autoFocus="autoFocus"
              />
              <Input
                name="password"
                type="password"
                id="password"
                value={password}
                onChange={this.onChange}
                bsSize="lg"
                className="form-control mb-3"
                placeholder="Password"
              />

              <Button
                color="primary"
                block
                type="submit"
                isBusy={isLoading}
                disabled={isInvalid}
              >
                Login
              </Button>

              {error && (
                <p className="text-danger">
                  <br />
                  {error.message}
                </p>
              )}
              <p className="mt-4 mb-5 text-muted">
                <Link to="/forgot-password">
                  Forgot your email or password?
                </Link>
              </p>

              <Button
                onClick={this.navigateTo}
                color="secondary"
                block
                type="button"
              >
                Don't have an account? Sign up now
              </Button>

              <p className="mt-5 mb-3 text-muted">&copy; loopHQ</p>
            </Form>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(Login);
