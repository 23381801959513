import { firebase } from '../../firebase';
import { dispatchByType } from './Helpers';
import { BasicActionType } from '.';
import { showError } from './Message';

const mListeners = {};

type Condition = {
  method: 'where' | 'orderBy' | 'limit',
  operation: '==' | '>=' | '<=' | '<' | '>' | '=' | null,
  name: string,
  value: string
};

type ParamsType = {
  data: any,
  type: string,
  dispatch: Function,
  collection: string,
  action: BasicActionType,
  conditions: Array<Condition>
};

const applyCondition = (query, conditions) => {
  if (Array.isArray(conditions)) {
    for (const condition of conditions) {
      const { operation, name, value, method } = condition;
      query = query[method](
        ...[name, operation, value].filter(inp => inp !== undefined)
      );
    }
  }
  return query;
};

const firebaseAction = async (params: ParamsType) => {
  const { type, dispatch, collection, conditions, action, data } = params;
  dispatch({
    type,
    what: action.Start
  });
  const dbRef = firebase.db.collection(collection);
  try {
    // eslint-disable-next-line default-case
    switch (type) {
      case action.Add: {
        await dbRef.add(data);
        break;
      }
      case action.Remove: {
        await dbRef.doc(data.id).delete();
        break;
      }
      case action.Get: {
        if (typeof mListeners[collection] === 'function') {
          mListeners[collection]();
        }
        const conditionRef = applyCondition(dbRef, conditions);
        mListeners[collection] = conditionRef.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(docSnap => {
            dispatchByType(dispatch, action, docSnap);
          });
        });
        const snapshot = await conditionRef.get();
        dispatch({
          type,
          what: action.Success,
          data: snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        });
        break;
      }
      case action.Update: {
        const { id, ...updates } = data;
        await dbRef.doc(id).update(updates);
        break;
      }
    }
    // adding to state will be handled by onSnapshot
    // we just reset the busy state
    setTimeout(
      () =>
        dispatch({
          type,
          what: action.Reset
        }),
      1000
    );
  } catch (exp) {
    dispatch(showError('Error occurred!', exp.message));
    dispatch({
      type,
      what: action.Fail,
      message: exp.message
    });
    dispatch({
      type,
      what: action.Reset
    });
  }
};

export default firebaseAction;
