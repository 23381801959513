const newUserReducer = (
  state = {
    isNewUser: true,
    showBanner: true
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_NEW_USER': {
      return { ...state, isNewUser: action.payload };
    }
    case 'HIDE_BANNER': {
      return { ...state, showBanner: false };
    }
    default:
      return state;
  }
};

export default newUserReducer;
