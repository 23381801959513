import React from 'react';
import { ListGroupItem } from 'reactstrap';
import StarView from './Star';

const Review = ({ review }) => (
  <ListGroupItem className="text-left">
    <p className="mb-0 text-muted">{review.name}</p>
    <StarView stars={review.stars} onStar={() => false /* ignore */} />
    <p>{review.description}</p>
  </ListGroupItem>
);

export default Review;
