import React from 'react';
import { Input } from 'reactstrap';
import Select, { components } from 'react-select';
import { quickLinkPresets, allPreset } from '../Helpers';
import { Button } from '../../components';

const { Option } = components;

const iconOption = presets => props => (
  <Option {...props}>
    <img
      alt={props.data.label}
      style={{ width: 40, height: 40 }}
      src={presets.find(icon => icon.id === props.value).icon}
    />
    &nbsp;
    {props.data.label}
  </Option>
);

const QuickLinkOptionForm = ({
  methods: { onInput, onDelete },
  preset,
  link: { icon, title, shortDescription, link, linkText }
}) => {
  const presets = quickLinkPresets[preset] || allPreset;
  const Option = iconOption(presets);
  const options = presets.map(option => ({
    label: option.title,
    value: option.id
  }));
  return (
    <div style={{ borderBottom: '3px solid #cfcfcf' }} className="mb-2">
      <label>Icon</label>
      <Select
        onChange={option => {
          onInput({
            nativeEvent: {
              target: {
                id: 'icon',
                value: option.value
              }
            }
          });
        }}
        className="mb-3"
        id="icon"
        value={options.find(option => option.value === icon)}
        options={options}
        components={{ Option }}
      />
      <label>Title</label>
      <Input
        type="text"
        id="title"
        className="form-control mb-3"
        placeholder="Enter Title"
        value={title}
        onChange={onInput}
      />
      <label>Short Description</label>
      <Input
        type="text"
        id="shortDescription"
        className="form-control mb-3"
        placeholder="Enter Short Description"
        value={shortDescription}
        onChange={onInput}
      />
      <label>External Link</label>
      <Input
        type="text"
        id="link"
        className="form-control mb-3"
        placeholder="Enter URL"
        value={link}
        onChange={onInput}
      />
      <label>Button text</label>
      <Input
        type="text"
        id="linkText"
        className="form-control mb-3"
        placeholder="e.g. More info"
        value={linkText}
        onChange={onInput}
      />
      <div style={{ textAlign: 'right' }} className="mb-3">
        <Button color="danger" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default QuickLinkOptionForm;
