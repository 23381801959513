/**
 * Card to show in the location details page
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'reactstrap';
import moment from 'moment';

import { isWithInTime, toLocal } from '../Helpers';
import EventCard from './EventCard';
import Start from '../trivia/Start';
import { Button } from '../../components';
import { db } from '../../../firebase/firebase';
import Leaderboard from '../trivia/Leaderboard';
import Play from '../trivia/Play';

class TriviaCard extends React.Component {
  state = {
    isLoading: true,
    isOpen: false
  };

  async componentDidMount() {
    try {
      const {
        event,
        match: {
          params: { uid }
        }
      } = this.props;
      const progressSnap = await db
        .collection('progress')
        .where('eventId', '==', event.id)
        .where('uid', '==', uid)
        .limit(1)
        .get();
      this.setState({
        triviaText: progressSnap.docs.length ? 'Edit Trivia' : 'Start Trivia'
      });
    } catch (exp) {
      // ignore
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      event,
      history,
      showMessage,
      match: {
        params: { uid }
      },
      eventLocation,
      ...rest
    } = this.props;
    const now = moment();
    const utcEnd = toLocal(event.utcEnd);
    const utcStart = toLocal(event.utcStart);
    const isComing = now.isBefore(utcStart);
    const showResult = now.isAfter(utcEnd) && !now.diff(utcEnd, 'days'); // show +2 after event is over
    const isMessageShown = showMessage && !showResult;
    const { isOpen, isLoading, triviaText } = this.state;
    return (
      <EventCard
        {...rest}
        event={event}
        eventLocation={eventLocation}
        showMessage={showMessage}
      >
        {isComing && !isMessageShown && (
          <small className="d-block mb-2 text-left">
            Starts at <strong>{utcStart.format('hh:mm A')}</strong>
          </small>
        )}
        {isWithInTime(event) && !isMessageShown && (
          <Button
            onClick={() => this.setState({ isOpen: true })}
            className="btn btn-primary"
            color="primary"
            isBusy={isLoading}
          >
            {triviaText}
          </Button>
        )}
        {triviaText === 'Edit Trivia' &&
          now.isBefore(utcEnd) &&
          !isMessageShown && (
            <small className="d-block mt-2 text-left">
              Ends at <strong>{utcEnd.format('hh:mm A')}</strong>
            </small>
          )}
        {!!showResult && (
          <Button
            onClick={() => this.setState({ isOpen: true })}
            color="primary"
            outline
          >
            View Trivia results
          </Button>
        )}
        <Modal isOpen={isOpen}>
          {!showResult && (
            <Start
              event={event}
              uid={uid}
              Play={Play}
              triviaText={triviaText}
              onStart={() => this.setState({ triviaText: 'Edit Trivia' })}
              onDone={() => this.setState({ isOpen: false })}
            />
          )}
          {!!showResult && (
            <Leaderboard
              uid={uid}
              onClose={() => this.setState({ isOpen: false })}
              event={event}
            />
          )}
        </Modal>
      </EventCard>
    );
  }
}

export default withRouter(TriviaCard);
