import React from 'react';
import { Input, FormGroup, Label, Progress, Form } from 'reactstrap';
// import moment from 'moment';
import { withRouter } from 'react-router-dom';

// import { toLocal, isWithInTime } from '../Helpers';
import EventCard from './EventCard';
import { Button } from '../../components';
import { savePollVote, getVote } from './VoteService';

class PollCard extends React.Component {
  constructor(props) {
    super(props);
    // const { event } = props;
    // const utcEnd = toLocal(event.utcEnd);
    //const hasEnded = utcEnd.isBefore(moment());
    this.state = {
      hasEnded: false,
      oldAnswer: null,
      isLoading: true,
      hasVoted: false
    };
  }

  async componentDidMount() {
    const {
      event: { id },
      match: {
        params: { uid }
      }
    } = this.props;
    const oldAnswer = await getVote({ eventId: id, userId: uid });
    this.setState({
      isLoading: false,
      hasVoted: oldAnswer !== null,
      oldAnswer
    });
  }

  renderResult = () => {
    const {
      event: { pollVotes }
    } = this.props;
    const { hasEnded, oldAnswer } = this.state;
    const entries = Object.keys(pollVotes);
    const total = entries.reduce((total, vote) => {
      total += parseInt(pollVotes[vote] || '0');
      return total;
    }, 0);
    return (
      <>
        {entries.map(entry => {
          const received =
            parseInt(pollVotes[entry] || '0') / Math.max(total, 1); // prevent 0 division
          const cent = Math.floor(received * 100);
          return (
            <div key={entry}>
              <div style={{ display: 'flex' }}>
                <span style={{ flex: 1 }}>{entry}</span>
                <span>{cent}%</span>
              </div>
              <Progress value={cent} />
            </div>
          );
        })}
        {oldAnswer && (
          <small>
            You voted for <strong>{oldAnswer}</strong>
          </small>
        )}
        <div className="text-center mt-2">
          <div className="mb-2">{total} votes</div>
          <p>
            <strong>
              {hasEnded ? 'Voting has ended' : 'Thanks for voting'}
            </strong>
          </p>
        </div>
      </>
    );
  };

  handleVote = ({ nativeEvent: { target } }) => {
    const key = target.getAttribute('data-answer').trim();
    this.setState({ key });
  };

  handleVoteSubmit = () => {
    const {
      event: { id, pollVotes },
      match: {
        params: { uid }
      }
    } = this.props;
    const { key } = this.state;
    if (!key) {
      return;
    }
    pollVotes[key] += 1;
    this.setState({ hasVoted: true, oldAnswer: key });
    savePollVote({ answer: key, userId: uid, eventId: id });
  };

  renderForm = () => {
    const { event, showMessage } = this.props;
    const { Poll } = event;
    const pollAnswers = Poll.answer;
    return (
      <>
        <Form onSubmit={this.handleVoteSubmit}>
          {pollAnswers &&
            Poll.answer.split(',').map(answer => (
              <FormGroup check key={answer}>
                <Label check>
                  <Input
                    onChange={this.handleVote}
                    data-answer={answer}
                    name="answer"
                    type="radio"
                  />{' '}
                  {answer}
                </Label>
              </FormGroup>
            ))}
          {!showMessage && (
            <Button
              block
              className="mt-2"
              color="primary"
              onClick={this.handleVoteSubmit}
            >
              Vote
            </Button>
          )}
        </Form>
      </>
    );
  };

  renderLoading = () => (
    <p className="text-muted text-center">Please wait...</p>
  );

  render() {
    const { event, showMessage, eventLocation } = this.props;
    const { Poll } = event;
    const { hasVoted, hasEnded, isLoading } = this.state;
    let renderView = this.renderForm;
    if (isLoading) {
      renderView = this.renderLoading;
    }
    if (hasEnded || hasVoted) {
      renderView = this.renderResult;
    }
    //const now = moment();
    //const utcStart = toLocal(event.utcStart);
    //const isComing = now.isBefore(utcStart);
    return (
      <div className="card-poll">
        <EventCard
          eventLocation={eventLocation}
          showMessage={showMessage}
          event={event}
          hasEnded={hasEnded}
        >
          <div className="text-center poll-Question">{Poll.question}</div>
          <div className="pl-4 pr-4 pt-2 pb-2">{renderView()}</div>
          {/* {isComing && (
          <div className="mb-2 font-weight-bold text-center">
            Voting starts at {utcStart.format('hh:mm A')}
          </div>
        )} */}
        </EventCard>
      </div>
    );
  }
}

export default withRouter(PollCard);
