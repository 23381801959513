import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const getConfig = () => {
  const { NODE_ENV, REACT_APP_USE_FIREBASE_DEV } = process.env;
  if (NODE_ENV === 'production' && REACT_APP_USE_FIREBASE_DEV === 'false') {
    return {
      apiKey: 'AIzaSyBtOfzpLV5m3c0QrCqyCJuVTfCFFxByQkk',
      authDomain: 'loop-prod-1000.firebaseapp.com',
      databaseURL: 'https://loop-prod-1000.firebaseio.com',
      projectId: 'loop-prod-1000',
      storageBucket: 'loop-prod-1000.appspot.com',
      messagingSenderId: '1089200037915'
    };
  }
  return {
    apiKey: 'AIzaSyCC8pUn-w7ng33Jg8VHMgI3eTiAD_3TYG8',
    authDomain: 'loophq-b2b.firebaseapp.com',
    databaseURL: 'https://loophq-b2b.firebaseio.com',
    projectId: 'loophq-b2b',
    storageBucket: 'loophq-b2b.appspot.com',
    messagingSenderId: '1070341692654'
  };
};

if (!firebase.apps.length) {
  firebase.initializeApp(getConfig());
}

const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage;

db.enablePersistence().catch(function(err) {
  console.log(err);
});

export { firebase, auth, db, functions, storage };
