import React from 'react';
import { Modal, ModalHeader, ModalBody, Input, ModalFooter } from 'reactstrap';
import Select from 'react-select';

import EventCard from './EventCard';
import { Button, withMessage } from '../../components';
import { saveFormAnswers } from '../Helpers';

class FormCard extends React.Component {
  state = {
    answers: [],
    isSaving: false,
    isModalOpen: false
  };

  componentDidMount() {
    const {
      event,
      match: { uid, locationId }
    } = this.props;
    const existing = localStorage.getItem(uid + locationId + event.id);
    let response;
    if (existing && (response = JSON.parse(existing)).answers) {
      this.setState({ answers: response.answers, existing: response.id });
    }
  }

  handleInput = (name, value, index) => {
    const { answers } = this.state;
    answers[index] = { name, value };
    this.setState({ answers });
  };

  handleSubmit = async () => {
    const { answers, existing } = this.state;
    const {
      event,
      showError,
      showSuccess,
      match: { uid, locationId }
    } = this.props;
    const {
      Forms: { fields }
    } = event;
    for (const index in fields) {
      const field = fields[index];
      const ans = (answers[index] ? answers[index].value : '') + '';
      const isRequired = field.isRequired === 'yes';
      if (isRequired && (!ans || !ans.trim().length)) {
        showError(
          `Field missing (${field.title})`,
          'Please fill all the required fields.'
        );
        return;
      }
      if (
        ans.trim().length &&
        field.type === 'email' &&
        !(ans.includes('@') && ans.includes('.'))
      ) {
        showError(
          `Invalid email (${field.title})`,
          'Please enter a valid email address.'
        );
        return;
      }
    }
    this.setState({ isSaving: true });
    try {
      const response = {
        answers,
        locationId,
        uid,
        event: event.id
      };
      const item = await saveFormAnswers(response, existing);
      response.id = item.id;
      showSuccess('Answers saved!', 'Your answers have been saved.');
      this.toggleModal();
      localStorage.setItem(
        response.uid + response.locationId + response.event,
        JSON.stringify(response)
      );
      this.setState({ existing: item.id });
    } catch (exp) {
      showError('Saving failed', "Your answers coudln't be saved.");
    } finally {
      this.setState({ isSaving: false });
    }
  };

  renderField = ({ title, isRequired, type, options }, index) => {
    const required = isRequired === 'yes';
    const key = title + '_' + index;
    const item = [];
    const { answers } = this.state;
    const defaultValue = answers[index] && answers[index].value;
    switch (type) {
      case 'textarea':
        item.push(
          <textarea
            onChange={event =>
              this.handleInput(title, event.target.value, index)
            }
            key={key}
            placeholder={title}
            className="form-control"
            required={required}
            defaultValue={defaultValue}
          />
        );
        break;
      case 'select':
        const formOptions = options.split(',').map(opt => ({
          label: opt,
          value: opt
        }));
        item.push(
          <Select
            key={key}
            options={formOptions}
            onChange={option => {
              this.handleInput(title, option.value, index);
            }}
            defaultValue={formOptions.find(opt => opt.value === defaultValue)}
          />
        );
        break;
      case 'text':
      case 'email':
      default:
        item.push(
          <Input
            placeholder={title}
            key={key}
            id={title}
            type={type}
            defaultValue={defaultValue}
            required={required}
            onChange={event =>
              this.handleInput(title, event.target.value, index)
            }
          />
        );
    }
    return (
      <div className="mb-3" key={'container_' + key}>
        <label>
          {title} {required && '(required)'}
        </label>
        {item}
      </div>
    );
  };

  toggleModal = () =>
    this.setState(state => ({
      isModalOpen: !state.isModalOpen
    }));

  render() {
    const { event, eventLocation } = this.props;
    const {
      Forms: { button, fields }
    } = event;
    const { isModalOpen, isSaving } = this.state;
    return (
      <>
        <EventCard
          showMessage={false}
          event={event}
          eventLocation={eventLocation}
        >
          <Button onClick={this.toggleModal} color="primary">
            {button}
          </Button>
        </EventCard>
        <Modal isOpen={isModalOpen}>
          <ModalHeader>
            {event.title}
            <div className="closeBtnTrivia">
              <ion-icon name="close" onClick={this.toggleModal} />
            </div>
          </ModalHeader>
          <ModalBody>
            {event.description}
            <div className="fields mt-4">{fields.map(this.renderField)}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              isBusy={isSaving}
              color="primary"
              block
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withMessage(FormCard);
