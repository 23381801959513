import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, ListGroup, Alert, Row } from 'reactstrap';
import { connect } from 'react-redux';
import ReactDragList from 'react-drag-list';

import { EventsAction } from '../actions';
import eventAction from '../actions/Event';
import Event from './Event';
import { Button } from '../components';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOrder: [],
      isDragDisabled: true,
      isChanged: false
    };
  }

  handleUpdate = (e, update) => {
    const newItem = [];
    update.forEach((item, index) => {
      item.order = index;
      newItem.push(item);
    });
    this.setState({ updatedOrder: newItem, isChanged: true });
  };

  handleSave = () => {
    const { updatedOrder, isDragDisabled } = this.state;
    if (isDragDisabled) {
      this.setState({ isDragDisabled: false });
      return;
    }
    const { updateEvent } = this.props;
    updatedOrder.forEach((item, index) => {
      updateEvent({
        ...item
      });
    });
    this.setState({ isChanged: false, isDragDisabled: true });
  };

  render() {
    const { events, ofLocation, history } = this.props;
    const { isDragDisabled } = this.state;
    const { isBusy } = events[EventsAction.Get];
    const { isUpdating } = events[EventsAction.Update].isBusy;
    const archived = [];
    const active = [];
    const set = item => {
      if (item.locationId === ofLocation.id) {
        (item.isArchived ? archived : active).push(item);
      }
    };
    events.data.forEach(set);
    return (
      <>
        <Card className="mb-3">
          <CardHeader>
            <Row style={{ alignItems: 'center' }}>
              <div className="col">
                <strong>Live modules</strong>
              </div>
              <div className="col col-auto">
                <Button
                  isBusy={isUpdating}
                  color="outline-primary btn-sm"
                  type="submit"
                  onClick={this.handleSave}
                >
                  {isDragDisabled ? 'Change order' : 'Save order'}
                </Button>
              </div>
            </Row>
          </CardHeader>
          <div className="p-3">
            <Button
              onClick={() => {
                history.push({
                  state: {
                    location: ofLocation
                  },
                  pathname: '/event/create'
                });
              }}
              color="primary"
              block
              outline
            >
              <ion-icon name="add" aria-label="add" />
              Add new module
            </Button>
          </div>
          <ListGroup>
            {isDragDisabled && (
              <ReactDragList
                dataSource={active.sort(
                  (a, b) => parseFloat(a.order) - parseFloat(b.order)
                )}
                rowKey="id"
                row={(event, index) => (
                  <div key={index}>
                    <Event showDrag={false} key={event.id} event={event} />
                  </div>
                )}
                handles={false}
                disabled
                className="simple-drag"
                rowClassName="simple-drag-row"
                onUpdate={this.handleUpdate}
              />
            )}
            {!isDragDisabled && (
              <ReactDragList
                dataSource={active.sort(
                  (a, b) => parseFloat(a.order) - parseFloat(b.order)
                )}
                rowKey="id"
                row={(event, index) => (
                  <div key={index}>
                    <Event showDrag key={event.id} event={event} />
                  </div>
                )}
                handles={false}
                className="simple-drag"
                rowClassName="simple-drag-row"
                onUpdate={this.handleUpdate}
              />
            )}
            {!isBusy && !active.length && (
              <Alert color="info" className="m-2 mb-0">
                <span>You don't have any active modules. Add a new module to your location page</span>
              </Alert>
            )}
            {isBusy && !active.length && (
              <span className="m-5 text-muted">Fetching...</span>
            )}
          </ListGroup>
        </Card>
        <Card className="mb-3 archivedEvents">
          <CardHeader>
            <strong>Archived modules</strong>
          </CardHeader>
          <ListGroup>
            {archived.map(event => (
              <Event key={event.id} event={event} />
            ))}
            {!isBusy && !archived.length && (
              <Alert color="info" className="m-2 mb-0">
                <span>Your archived modules is empty.</span>
              </Alert>
            )}
            {isBusy && !active.length && (
              <span className="m-2 text-muted">Fetching...</span>
            )}
          </ListGroup>
        </Card>
      </>
    );
  }
}
export default connect(
  ({ events }) => ({ events }),
  dispatch => ({
    updateEvent: event => dispatch(eventAction(EventsAction.Update, event))
  })
)(withRouter(List));
