import React from 'react';
import {
  Row,
  Col,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  Input,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import MultiSelect, { components } from 'react-select';

import { Button } from '../../components';
import {
  matchCategories,
  emailMethods,
  search,
  customMatchQuestions
} from '../Helpers';
import { firebase } from '../../../firebase';

const { Option } = components;

const CategoryOption = props => {
  let style = {};
  if (props.data.isTitle) {
    style.fontSize = 13;
    style.textTransform = 'uppercase';
    style.color = '#aaa';
  }
  return (
    <Option {...props}>
      <span style={style}>{props.data.label}</span>
    </Option>
  );
};

const emailOptions = emailMethods.map(option => ({
  label: option.name,
  value: option.value
}));

const questionOptions = Array.from(Array(30)).map((_, number) => ({
  value: number + 1,
  label: number + 1
}));

class Match extends React.Component {
  state = {
    activeTab: 'selected',
    query: '',
    matchData: [],
    isOpen: false
  };

  componentDidMount() {
    const {
      match: { category }
    } = this.props;
    if (Array.isArray(category)) {
      this.fetchData(category);
    }
  }

  handleSearch = ({
    nativeEvent: {
      target: { value }
    }
  }) => {
    this.setState({ query: value.toLowerCase() });
  };

  handleRandom = () => {
    const {
      methods: { onInput },
      match: { questionSize }
    } = this.props;
    const { matchData } = this.state;
    const randomMatch = [];
    let size = Math.min(questionSize, matchData.length);
    while (size) {
      const randIndex = Math.floor(Math.random() * matchData.length);
      const selected = matchData[randIndex];
      if (!randomMatch.find(q => q.id === selected.id)) {
        randomMatch.push(selected);
        --size;
      }
    }
    onInput({
      nativeEvent: {
        target: {
          id: 'questions',
          value: randomMatch
        }
      }
    });
  };

  addItem = (isSelected, item) => {
    const {
      methods: { onInput },
      match: { questionSize, questions }
    } = this.props;
    if (isSelected && this.isSelected(item)) {
      alert('You have already selected this question.');
      return;
    }
    if (isSelected) {
      if (questionSize <= questions.length) {
        alert('You have reached specified number of questions.');
        return;
      }
      // add
      questions.push(item);
      onInput({
        nativeEvent: {
          target: {
            id: 'questions',
            value: questions
          }
        }
      });
      this.forceUpdate();
      return;
    }
    // remove
    onInput({
      nativeEvent: {
        target: {
          id: 'questions',
          value: questions.filter(quest => item.id !== quest.id)
        }
      }
    });
    this.forceUpdate();
  };

  handleSelect = () => {
    this.setState({ isOpen: true });
  };

  isSelected = question => {
    const {
      match: { questions }
    } = this.props;
    return !!questions.find(q => q.id === question.id);
  };

  fetchData = async category => {
    if (!category || !category.length) {
      return;
    }
    this.setState({ isFetching: true });
    try {
      const customTypes = category
        .filter(cat => !cat.isSystem)
        .map(cat => cat.value);
      const customs = customMatchQuestions.filter(question =>
        customTypes.includes(question.category)
      );
      const result = await firebase.functions.httpsCallable('getNewQuestions')({
        category: category.map(cat => cat.value),
        limit: 100,
        match: true
      });
      this.setState({
        matchData: result.data.concat(customs)
      });
    } catch (exp) {
      console.log(exp);
    }
    this.setState({ isFetching: false });
  };

  render() {
    const {
      methods: { onInput },
      match: { category, collectEmail, questionSize, questions }
    } = this.props;
    const { isOpen, activeTab, query, matchData } = this.state;
    return (
      <>
        <Alert color="info">
          <span>
            Now you can upload your custom match questions and mix and match
            with system questions.
          </span>
          <div className="p-2 text-center">
            <Link to="/match/upload" className="btn btn-outline-primary">
              Upload match questions
            </Link>
          </div>
        </Alert>
        <div className="mb-4">
          <label>Category</label>
          <MultiSelect
            isMulti
            onChange={cateTypes => {
              onInput({
                nativeEvent: {
                  target: {
                    id: 'category',
                    value: cateTypes
                  }
                }
              });
              this.fetchData(cateTypes);
            }}
            className="mb-3"
            id="category"
            components={{ Option: CategoryOption }}
            value={category}
            options={matchCategories}
            isOptionDisabled={opt => opt.isTitle}
          />
          <Row>
            <Col sm={8} className="d-flex">
              <div style={{ alignSelf: 'center' }}>
                <div className="text-muted mb-3">
                  Select number of questions
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <MultiSelect
                onChange={option => {
                  onInput({
                    nativeEvent: {
                      target: {
                        id: 'questionSize',
                        value: option.value
                      }
                    }
                  });
                }}
                className="mb-3"
                name="questionSize"
                isSearchable
                defaultValue={{ value: questionSize, label: questionSize }}
                options={questionOptions}
              />
            </Col>
          </Row>
          <label>Collect email from users</label>
          <MultiSelect
            onChange={option => {
              onInput({
                nativeEvent: {
                  target: {
                    id: 'collectEmail',
                    value: option.value
                  }
                }
              });
            }}
            className="mb-3"
            id="collectEmail"
            value={emailOptions.find(option => option.value === collectEmail)}
            options={emailOptions}
          />
          {questionSize !== -1 && (
            <Card className="p-4">
              <h6 className="text-center mb-3">Questions</h6>
              <Row>
                <Col sm={6} className="mb-2">
                  <Button
                    onClick={this.handleRandom}
                    block
                    outline
                    color="primary"
                  >
                    Random pick
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    onClick={this.handleSelect}
                    block
                    outline
                    color="primary"
                  >
                    Select
                  </Button>
                </Col>
              </Row>
              {!!questions.length && (
                <Alert color="success" className="mt-2">
                  <span className="text-center">
                    You have selected {questions.length} questions.
                  </span>
                </Alert>
              )}
            </Card>
          )}
        </div>
        <Modal isOpen={isOpen} className="matchOverlay">
          <div style={{ padding: 16 }}>
            <ModalHeader>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 'selected' ? 'active' : ''}
                    onClick={() => {
                      this.setState({ activeTab: 'selected' });
                    }}
                  >
                    Selected ({questions.length})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab !== 'selected' ? 'active' : ''}
                    onClick={() => {
                      this.setState({ activeTab: 'all' });
                    }}
                  >
                    All ({matchData.length})
                  </NavLink>
                </NavItem>
              </Nav>
            </ModalHeader>
            <ModalBody>
              <input
                style={{
                  padding: 8,
                  marginBottom: 8,
                  width: '100%',
                  boxSizing: 'border-box',
                  border: '0 none',
                  borderBottom: '2px solid #dadada'
                }}
                onChange={this.handleSearch}
                placeholder="Search here"
              />
              <TabContent activeTab={activeTab}>
                <TabPane tabId="selected">
                  <ListGroup>
                    {search(questions, query).map((question, index) => (
                      <ListGroupItem key={'selected_' + index}>
                        <Row>
                          <Col>
                            {unescape(question.question)}
                            <strong className="correct-answer">
                              {question.correct_answer}
                            </strong>
                          </Col>
                          <Col className="d-flex col-auto">
                            <ion-icon
                              onClick={() => this.addItem(false, question)}
                              name="close"
                            />
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </TabPane>
                <TabPane tabId="all">
                  <ListGroup>
                    {search(matchData, query).map((question, index) => (
                      <ListGroupItem key={'all_' + index}>
                        <Row>
                          <Col className="d-flex col-auto">
                            <Input
                              onChange={({ nativeEvent: { target } }) => {
                                this.addItem(target.checked, question);
                              }}
                              type="checkbox"
                              checked={this.isSelected(question)}
                              style={{ marginLeft: 0 }}
                            />
                          </Col>
                          <Col>
                            {unescape(question.question)}
                            <strong className="correct-answer">
                              {question.correct_answer}
                            </strong>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </TabPane>
              </TabContent>
            </ModalBody>
          </div>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => this.setState({ isOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Match;
