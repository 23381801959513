import { MessageActions } from '.';

let watchId = -1;

const messageAction = (type, data) => {
  return dispatch => {
    dispatch({
      type,
      data
    });
    if (watchId !== -1) {
      window.clearTimeout(watchId);
    }
    // eslint-disable-next-line default-case
    switch (type) {
      case MessageActions.Show:
        // hide the message after 5 seconds
        watchId = window.setTimeout(
          () => dispatch(messageAction(MessageActions.Hide)),
          5000
        );
        break;
    }
  };
};

const showWarning = (title, content) =>
  messageAction(MessageActions.Show, {
    style: 'warning',
    title,
    content
  });

const showError = (title, content) =>
  messageAction(MessageActions.Show, {
    style: 'danger',
    title,
    content
  });

const showSuccess = (title, content) =>
  messageAction(MessageActions.Show, {
    style: 'success',
    title,
    content
  });

export { showWarning, showError, showSuccess };

export default messageAction;
