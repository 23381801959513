import React from 'react';
import { Container, Form } from 'reactstrap';

import logo from '../assets/images/loophq-icon.svg';
import { Button, withMessage } from '../components';
import { firebase } from '../../firebase';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const { history, showError } = props;
    const {
      location: { state }
    } = history;
    const code = state ? state.code : null;
    this.state = {
      isSaving: false,
      isValidating: true,
      email: '',
      password: '',
      confirmPassword: '',
      code
    };
    if (!code) {
      history.push('/');
    }
    firebase.auth
      .verifyPasswordResetCode(code)
      .then(email => {
        this.setState({ email, isValidating: false });
      })
      .catch(err => {
        showError('Oops', err.message);
        history.push({
          pathname: '/forgot-password'
        });
      });
  }

  handleResetSubmit = async event => {
    event.preventDefault();
    const { password, code, confirmPassword } = this.state;
    const { history, showError, showWarning, showSuccess } = this.props;
    if (!password || !password.length) {
      showWarning('Field missing', 'Please enter new password.');
      return;
    }
    if (!confirmPassword || !confirmPassword.length) {
      showWarning('Field missing', 'Please re-enter new password.');
      return;
    }
    if (confirmPassword !== password) {
      showWarning('Field missing', 'Please make sure passwords match.');
      return;
    }
    try {
      this.setState({ isSaving: true });
      await firebase.auth.confirmPasswordReset(code, password);
      showSuccess('Success!', 'Your new passwor has been saved.');
      history.push('/');
    } catch (exp) {
      showError('Oops', exp.message);
      this.setState({ isSaving: false });
    }
  };

  handleInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const {
      password,
      email,
      confirmPassword,
      isSaving,
      isValidating
    } = this.state;
    return (
      <Container className="text-center">
        <div className="pb-2 pt-5 text-center">
          <img
            className="d-block mx-auto mt-2 mb-2"
            src={logo}
            alt=""
            width="72"
            height="72"
          />
          <h1 className="mb-4 pb-2">loopHQ</h1>
          <p className="lead">
            Please enter new password to update your password.
          </p>
          <h2>Update password</h2>
          <Form autoComplete="off" onSubmit={this.handleResetSubmit}>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleInput}
                  className="form-control mb-3"
                  disabled
                />
                <input
                  type="password"
                  maxLength="40"
                  name="password"
                  value={password}
                  onChange={this.handleInput}
                  className="form-control mb-3"
                  placeholder="New password"
                />
                <input
                  type="password"
                  maxLength="40"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handleInput}
                  className="form-control mb-3"
                  placeholder="Re-enter password"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col col-md-4 mt-3">
                <Button
                  color="primary"
                  block
                  className="btn btn-lg btn-primary btn-block"
                  isBusy={isSaving || isValidating}
                  disabled={isSaving}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    );
  }
}

export default withMessage(ResetPassword);
