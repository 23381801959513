import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import Service from '../extras/services/Review';

const renderAutoComplete = inputProps => ({
  getInputProps,
  getSuggestionItemProps,
  suggestions
}) => (
  <div className="autocomplete-root">
    <input
      className="form-control mb-3"
      autoComplete="false"
      {...getInputProps()}
      {...inputProps}
    />

    {!!suggestions.length && (
      <div className="autocomplete-dropdown-container">
        {suggestions.map(suggestion => (
          <div {...getSuggestionItemProps(suggestion)} className="suggestion">
            <span>
              <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
              {suggestion.formattedSuggestion.secondaryText}
            </span>
          </div>
        ))}
      </div>
    )}
  </div>
);

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: props.locationName || '' };
  }

  handleChange = address => {
    this.setState({ address });
    const changedContext = {
      field: this.props.fieldName,
      value: address
    };
    this.props.onLocationQueryChanged(changedContext);
  };

  componentWillReceiveProps(props) {
    this.setState({
      address: props.locationName || ''
    });
  }

  // When the user selects an autocomplete suggestion...
  handleSelect = address => {
    // Pull in the setFormLocation function from the parent ReportForm
    const { setFormLocation } = this.props;
    Service.getLocationDetails(address)
      .then(setFormLocation)
      .catch(console.log);
  };

  render() {
    // Limit the suggestions to show only specific area or type
    const { fieldName, inputProps } = this.props;
    const searchOptions =
      fieldName === 'address'
        ? { types: ['geocode'] }
        : { types: ['establishment'] };

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {renderAutoComplete(inputProps)}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
