import React from 'react';

import { Button } from '../../components';
import QuickLinkOptionForm from './QuickLinkOptionForm';
import { quickLinkPresets, allPreset } from '../Helpers';

class QuickLink extends React.Component {
  componentDidMount() {
    this.checkPreset();
  }

  componentDidUpdate(props, state) {
    const { preset } = this.props;
    if (preset !== props.preset) {
      this.checkPreset();
    }
  }

  checkPreset = () => {
    const {
      links,
      preset,
      methods: { onInput }
    } = this.props;
    let isUsingDefault = false;
    let presets = quickLinkPresets[preset];
    if (!presets) {
      isUsingDefault = true;
      presets = allPreset;
    }
    if (!isUsingDefault && presets && !links.length) {
      const value = presets.map(option => ({
        ...option,
        icon: option.id
      }));
      onInput({
        nativeEvent: {
          target: {
            id: 'links',
            value
          }
        }
      });
      return;
    }
    if (!links.length) {
      this.addRow();
    }
  };

  addRow = (defaultPreset = null) => {
    const {
      links,
      preset,
      methods: { onInput }
    } = this.props;
    const presets = quickLinkPresets[preset];
    if (!defaultPreset) {
      defaultPreset = Array.isArray(presets) ? presets[0] : {};
    }
    onInput({
      nativeEvent: {
        target: {
          id: 'links',
          value: links.concat(defaultPreset)
        }
      }
    });
  };

  handleInput = (index, node) => {
    const {
      links,
      preset,
      methods: { onInput }
    } = this.props;
    const presets = quickLinkPresets[preset] || allPreset;
    if (node.id === 'icon') {
      links[index] = {
        ...links[index],
        ...presets[node.value],
        icon: node.value
      };
    } else {
      links[index][node.id] = node.value;
    }
    onInput({
      nativeEvent: {
        target: {
          id: 'links',
          value: links
        }
      }
    });
  };

  removeItem = index => {
    const {
      links,
      methods: { onInput }
    } = this.props;
    onInput({
      nativeEvent: {
        target: {
          id: 'links',
          value: links.filter((link, i) => i !== index)
        }
      }
    });
  };

  render() {
    const { links, preset } = this.props;
    return (
      <div className="mb-2">
        {links.map((link, index) => (
          <QuickLinkOptionForm
            key={'link_' + index}
            preset={preset}
            methods={{
              onDelete: () => this.removeItem(index),
              onInput: event =>
                this.handleInput(index, event.nativeEvent.target)
            }}
            link={link}
          />
        ))}
        <Button
          block
          className="mb-4"
          color="outline-primary"
          onClick={() => this.addRow()}
        >
          Add new link
        </Button>
      </div>
    );
  }
}

export default QuickLink;
