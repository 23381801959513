import React from 'react';
import { makeExternalLink } from '../Helpers';

const VideoCard = ({ event, eventLocation }) => {
  const { Video } = event;
  return (
    <div className="embed-responsive embed-responsive-16by9 mb-4 videoCard">
      <iframe
        title="Video"
        className="embed-responsive-item"
        src={makeExternalLink(Video.link)}
        allowFullScreen
      />
    </div>
  );
};

export default VideoCard;
