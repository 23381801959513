import React, { Component } from 'react';
import { Container, Form, Alert, Row, Col, Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';

import { firebase } from '../../firebase/firebase';
import { Button, PageLoading, withMessage, Footer } from '../components';
import LocationSearchInput from './LocationSearchInput';
import Banner from '../components/OnBoardBanner';
import logo from './../assets/images/loophq-icon.svg';
import { CustomerActions, LocationActions } from '../actions';
import locationAction from '../actions/Location';
import { isAllowed } from '../components/Subscriptions';
import Service from '../extras/services/Review';

const getInitialInput = () => ({
  name: '',
  description: '',
  promoText: '',
  floor: '0',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  image: '',
  showWebPage: 'Yes',
  redirectUrl: '',
  isOrphan: false,
  radius: 0.05,
  coordinates: {
    latitude: '',
    longitude: ''
  }
});

class HomeEdit extends Component<{
  locations: Object,
  customers: Object,
  saveLocation: Function,
  updateLocation: Function
}> {
  userEmail = null;

  constructor(props) {
    super(props);

    this.userEmail = localStorage.getItem('authUser');
    const { history, locations } = props;
    if (!this.userEmail) {
      history.push('/');
    }
    const { locationId } = history.location.state || {};
    const existingLocation = locations.data.find(loc => loc.id === locationId);
    this.state = {
      hasChanges: false, // to make sure something has been entered
      isLoading: true,
      disableSave: true,
      isClaimFetching: true,
      existingId: existingLocation ? existingLocation.id : null,
      location: {
        ...getInitialInput(),
        ...existingLocation
      }
    };
  }

  async componentDidMount() {
    let { claimLocation } = this.state;
    const profile = JSON.parse(localStorage.getItem('profile')) || {};
    const { locationID } = profile;
    if (locationID) {
      try {
        claimLocation = await Service.fetchLocation(locationID);
        this.setState(state => ({
          locationID,
          location: {
            ...state.location,
            ...claimLocation
          }
        }));
      } catch (exp) {
        // ignore
      }
    }
    this.setState({ claimLocation, isClaimFetching: false });
  }

  getSnapshotBeforeUpdate = props => {
    const { locations } = props;
    return {
      isFetching: locations[LocationActions.Get].isBusy,
      isAdded: locations[LocationActions.Add].isSuccess,
      isUpdated: locations[LocationActions.Update].isSuccess
    };
  };

  componentDidUpdate(props, state, snapshot) {
    const { resetState, history, showSuccess } = props;
    // const { locations } = this.props;
    // const { existingId } = this.state;
    // if (!snapshot.isFetching && !!locations.data.length && !existingId) {
    //   // coming to edit page directly
    //   // remove this if want to support multiple location
    //   history.push('/#locations');
    //   return;
    // }
    // this prevents popping up of message
    // because of data being added from other sources
    const { hasChanges } = state;
    if (snapshot.isAdded) {
      resetState();
      if (hasChanges) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        profile.locationID = null;
        // TODO: cleanup
        localStorage.setItem('profile', JSON.stringify(profile));
        this.setState(
          {
            hasChanges: false,
            location: getInitialInput()
          },
          () => history.push('/#locations')
        );
        showSuccess('Location saved!', 'The location has been saved.');
      }
    }
    if (snapshot.isUpdated) {
      resetState();
      if (hasChanges) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        profile.locationID = null;
        // TODO: cleanup
        localStorage.setItem('profile', JSON.stringify(profile));
        this.setState(
          {
            hasChanges: false,
            location: getInitialInput()
          },
          () => history.push('/#locations')
        );
        showSuccess('Location updated!', 'The location has been updated.');
      }
    }
  }

  cancel = event => {
    this.props.history.push('/#locations');
  };

  checkLatLng = async location => {
    this.setState({ isChecking: true });
    const { showWarning } = this.props;
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (!profile || !profile.locationID) {
      /**
       * If the user is editing or adding new location
       * reset this
       */
      this.setState({
        locationID: null,
        claimLocation: null
      });
    }
    try {
      const found = await Service.checkExisting(location, this.userEmail);
      if (found) {
        if (typeof found === 'object') {
          this.setState(state => ({
            locationID: found.id,
            location: {
              ...state.location,
              ...found
            },
            claimLocation: found
          }));
        } else {
          showWarning(
            'Location already used.',
            'This location is already in use by some other user.'
          );
        }
      }
      return found;
    } catch (exp) {
      showWarning(
        'Oops!',
        `Something went wrong. Please try again.${'\n'}${exp.message}`
      );
    }
    this.setState({ isChecking: false });
    return false;
  };

  onSubmit = async event => {
    event.preventDefault();
    const { hasChanges, existingId, location, claimLocation } = this.state;
    const { customers, saveLocation, updateLocation, showWarning } = this.props;
    if (!claimLocation && !hasChanges) {
      showWarning('No changes!', "You haven't made any changes to be saved.");
      return;
    }
    const { customerID } = customers.data[customers.data.length - 1];
    if (!existingId && claimLocation && claimLocation.isOrphan) {
      /**
       * Not editing, the user is claiming the location from signup
       */
      this.setState({
        hasChanges: true
      });
      claimLocation.isOrphan = false;
      claimLocation.userEmail = this.userEmail; // new prop
      claimLocation.customerID = customerID;
      updateLocation(claimLocation);
      return;
    }
    const { coordinates, ...locationData } = location;
    locationData.promoWebview = true;
    locationData.radius = parseFloat(locationData.radius);
    locationData.customerID = customerID;
    locationData.userEmail = this.userEmail; // new prop
    locationData.showIncomingRequests = false;
    const { redirectUrl, showWebPage } = location;
    if (showWebPage === 'No' && (!redirectUrl || !redirectUrl.includes('.'))) {
      showWarning('Valid URL needed', 'Please enter a valid URL.');
      return;
    }
    if (
      isNaN(coordinates.latitude || coordinates._lat) ||
      isNaN(coordinates.longitude || coordinates._long)
    ) {
      showWarning(
        'Location required',
        'Location with valid latitude and longitude is required.'
      );
      return;
    }
    locationData.coordinates = new firebase.firestore.GeoPoint(
      coordinates.latitude || coordinates._lat,
      coordinates.longitude || coordinates._long
    );
    // /**
    //  * 2 cases
    //  * i) User is updating the location
    //  * ii) User is adding the location
    //  */
    if (!this.validate(locationData)) {
      showWarning(
        'Incomplete information.',
        'Please fill all the required fields.'
      );
      return;
    }
    // the user changed the location, we should show them message
    // check if the location exists, if not allow saving
    // or the user is saving the location
    this.setState({ isSubmitting: true });
    const found = await this.checkLatLng(locationData);
    if (found) {
      this.setState({ isSubmitting: false });
      return;
    }
    if (existingId) {
      // the location exists, its being edited
      location.id = existingId;
      updateLocation(locationData);
    } else {
      // add a new entry
      locationData.stars = 0;
      locationData.starsCount = 0;
      saveLocation(locationData);
    }
    this.setState({ isSubmitting: false });
  };

  onChange = event => {
    event.preventDefault();
    const { location, claimLocation } = this.state;
    const modifiedLocationData = {
      ...location
    };
    const prop = event.target.name;
    modifiedLocationData[prop] = event.target.value;
    if (claimLocation) {
      claimLocation[prop] = event.target.value;
    }
    this.setState({
      hasChanges: true,
      location: modifiedLocationData
    });
  };

  validate(modifiedLocationData) {
    return (
      modifiedLocationData.name !== '' &&
      modifiedLocationData.address !== '' &&
      //modifiedLocationData.description !== '' &&
      modifiedLocationData.promoText !== '' &&
      modifiedLocationData.city !== '' &&
      //modifiedLocationData.state !== '' &&
      modifiedLocationData.zip !== '' &&
      modifiedLocationData.country !== ''
    );
  }

  setFormLocation = googleLocation => {
    this.mapLocation(googleLocation, false);
  };

  setFormAddress = googleAddress => {
    this.mapLocation(googleAddress, true);
  };

  onLocationQueryChanged = event => {
    const modifiedLocationData = {
      ...this.state.location
    };
    modifiedLocationData[event.field] = event.value;
    this.setState({
      hasChanged: true,
      hasChanges: true,
      location: modifiedLocationData
    });
  };

  mapLocation(locationData, isAddressChanged) {
    const modifiedLocationData = Service.buildLocationFromRaw(
      locationData,
      isAddressChanged,
      this.state.location
    );
    this.checkLatLng(modifiedLocationData);
    this.setState({
      hasChanges: true,
      hasChanged: true,
      location: modifiedLocationData
    });
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    const { customers, locations } = this.props;
    const isFetching =
      customers[CustomerActions.Get].isBusy ||
      locations[LocationActions.Get].isBusy;

    if (isFetching) {
      return <PageLoading description="Fetching previously saved data." />;
    }

    const isSaving =
      locations[LocationActions.Add].isBusy ||
      locations[LocationActions.Update].isBusy;

    const {
      location,
      locationID,
      claimLocation,
      isClaimFetching,
      existingId,
      hasChanged,
      isSubmitting
    } = this.state;
    const {
      name,
      description,
      promoText,
      address,
      city,
      state,
      zip,
      country,
      isOrphan,
      radius,
      showWebPage,
      redirectUrl
    } = location;
    const alreadyClaimed =
      claimLocation &&
      !claimLocation.isOrphan &&
      claimLocation.userEmail !== this.userEmail;
    /**
     * If the user is adding new location, but the location is already in DB
     * then we will not let them edit #64
     */
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (!profile) {
      return <PageLoading description="" />;
    }
    const disableInput =
      existingId || (profile.locationID !== locationID && !!claimLocation);
    return (
      <>
        <Banner order={4} />
        <Container>
          <div className="pb-2 pt-5 text-center">
            <img
              className="d-block mx-auto mt-2 mb-2"
              src={logo}
              alt=""
              width="72"
              height="72"
            />

            <h4>
              Your location information.
            </h4>
          </div>

          <Form
            className="form-location"
            autoComplete="off"
            onSubmit={this.onSubmit}
          >
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <label>Location Name</label>
                <LocationSearchInput
                  setFormLocation={this.setFormLocation}
                  onLocationQueryChanged={this.onLocationQueryChanged}
                  inputProps={{
                    disabled: disableInput,
                    placeholder: 'Search by location name'
                  }}
                  locationName={name}
                  fieldName="name"
                />
                <label>Location Description</label>
                <textarea
                  //required
                  id="location-description"
                  maxLength="500"
                  name="description"
                  value={description}
                  onChange={this.onChange}
                  className="form-control mb-4"
                  rows="4"
                  placeholder="Enter location description"
                />
                <label>Location Tagline
                  <ion-icon
                    class="tooltipicon"
                    name="help-circle"
                    id="taglineTooltip"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpen}
                    target="taglineTooltip"
                    toggle={this.toggle}
                  >
                  This is your location's CTA/button label on the loopHQ app home screen
                  </Tooltip>




                </label>
                <input
                  required
                  type="text"
                  maxLength="20"
                  id="promo-text"
                  name="promoText"
                  value={promoText}
                  onChange={this.onChange}
                  className="form-control mb-3"
                  placeholder="Enter location tagline"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                {isAllowed() && (
                  <Row>
                    <Col xs={6}>
                      <label>Is Orphan?</label>
                      <select
                        value={isOrphan ? 'true' : 'false'}
                        onChange={event =>
                          this.onChange({
                            preventDefault: () => false,
                            target: {
                              name: 'isOrphan',
                              value: JSON.parse(event.target.value)
                            }
                          })
                        }
                        className="form-control mb-3"
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </Col>
                    <Col xs={6}>
                      <label>Radius</label>
                      <input
                        required
                        type="text"
                        id="radius"
                        name="radius"
                        value={radius}
                        onChange={this.onChange}
                        className="form-control mb-3"
                        placeholder="Enter location radius"
                      />
                    </Col>
                  </Row>
                )}
                <label>Location Address</label>
                <LocationSearchInput
                  setFormLocation={this.setFormAddress}
                  onLocationQueryChanged={this.onLocationQueryChanged}
                  inputProps={{
                    disabled: disableInput,
                    placeholder: 'Search by address'
                  }}
                  locationName={address}
                  fieldName="address"
                />

                <input
                  required
                  type="text"
                  maxLength="100"
                  id="location-city"
                  name="city"
                  value={city}
                  disabled={disableInput}
                  onChange={this.onChange}
                  className="form-control mb-3"
                  placeholder="City"
                />

                <div className="row">
                  <div className="col col-md-7">
                    <input
                      type="text"
                      maxLength="100"
                      id="location-state"
                      name="state"
                      value={state}
                      disabled={disableInput}
                      onChange={this.onChange}
                      className="form-control mb-3"
                      placeholder="State"
                    />
                  </div>

                  <div className="col col-md-5">
                    <input
                      required
                      type="text"
                      maxLength="6"
                      id="location-zip"
                      name="zip"
                      value={zip}
                      disabled={disableInput}
                      onChange={this.onChange}
                      className="form-control mb-3"
                      placeholder="Zip"
                    />
                  </div>
                </div>
                <input
                  required
                  type="text"
                  maxLength="100"
                  id="location-country"
                  name="country"
                  value={country}
                  disabled={disableInput}
                  onChange={this.onChange}
                  className="form-control mb-3"
                  placeholder="Country"
                />
                <div>
                  <label>Create location mini-web page</label>
                  <Select
                    defaultValue={{ label: showWebPage, value: showWebPage }}
                    options={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    onChange={opt => {
                      this.onChange({
                        preventDefault: () => false,
                        target: {
                          name: 'showWebPage',
                          value: opt.value
                        }
                      });
                    }}
                    className="mb-3"
                  />
                  {showWebPage === 'No' && (
                    <>
                      <label>Redirect all the trafic to</label>
                      <input
                        required
                        type="text"
                        maxLength="100"
                        name="redirectUrl"
                        value={redirectUrl}
                        onChange={this.onChange}
                        className="form-control mb-3"
                        placeholder="Enter your website URL"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-6">
                {locationID && isClaimFetching && (
                  <div>
                    <p>Loading...</p>
                  </div>
                )}
                {!hasChanged && locationID && alreadyClaimed && (
                  <Alert color="warning">
                    <span>
                      The location at <strong>{claimLocation.name}</strong> is
                      already claimed by someone, if you think this is an error,
                      please contact us at{' '}
                      <a href="mailto:info@loophereandnow.com">
                        info@loophereandnow.com
                      </a>
                    </span>
                  </Alert>
                )}
                {!hasChanged &&
                  locationID &&
                  !claimLocation &&
                  !isClaimFetching && (
                    <Alert color="danger">
                      <span>The location doesn't exist.</span>
                    </Alert>
                  )}
                {((!hasChanged &&
                  locationID &&
                  claimLocation &&
                  claimLocation.isOrphan) ||
                  (disableInput &&
                    claimLocation &&
                    claimLocation.isOrphan)) && (
                  <Alert color="primary">
                    <span>
                      You're claiming <strong>{claimLocation.name}</strong>
                    </span>
                  </Alert>
                )}
              </div>
            </div>

            <Row className="justify-content-center">
              <Col className="mt-3" md={4}>
                <Button
                  color="primary"
                  block
                  className="btn btn-lg btn-primary btn-block"
                  isBusy={isSaving || isSubmitting}
                  type="submit"
                >
                  Save
                </Button>
              </Col>
              <Col className="mt-3" md={4}>
                <Button
                  color="secondary"
                  block
                  className="btn btn-lg btn-outline-primary btn-block"
                  type="button"
                  onClick={this.cancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
          <Footer />
        </Container>
      </>
    );
  }
}

export default withMessage(
  connect(
    ({ locations, customers }) => ({ locations, customers }),
    dispatch => ({
      resetState: () => {
        dispatch({ type: LocationActions.Add, what: LocationActions.Reset });
        dispatch({ type: LocationActions.Update, what: LocationActions.Reset });
      },
      saveLocation: location =>
        dispatch(locationAction(LocationActions.Add, location)),
      updateLocation: location =>
        dispatch(locationAction(LocationActions.Update, location))
    })
  )(HomeEdit)
);
