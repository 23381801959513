import { firebase } from '../../firebase';

let cachedPlans = [];

class StripeApi {
  publicToken = process.env.REACT_APP_STRIPE_PUBLIC;
  getSubscriptionPlans = async () => {
    // since plans do not change frequently
    // we will cache the result of 1st calls of plans
    // so, that we can prevent further calls related to plans
    if (!cachedPlans.length) {
      const response = await firebase.functions.httpsCallable(
        'getSubscriptionPlans'
      )();
      if (response.data.length) {
        cachedPlans = response.data;
      }
    }
    return cachedPlans;
  };
  createCustomer = firebase.functions.httpsCallable('createCustomer');
  getCustomer = firebase.functions.httpsCallable('getCustomer');
  updateCustomer = firebase.functions.httpsCallable('updateCustomer');
  createSubscription = firebase.functions.httpsCallable('createSubscription');
}

const stripeApi = new StripeApi();
stripeApi.getSubscriptionPlans(); // make initial call to fetch the plans

export { stripeApi };
