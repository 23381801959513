import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

// import { stripeApi } from './StripeApi.js';
import logo from './../assets/images/loophq-icon.svg';
import { PageLoading } from '../components/index.js';
import { CustomerActions } from '../actions/index.js';
import Banner from '../components/OnBoardBanner';

class Subscribe extends Component {
  email = null;

  constructor(props) {
    super(props);
    this.email = localStorage.getItem('authUser');
    if (!this.email) {
      this.props.history.push('/');
    }

    this.state = {
      isLoaded: true,
      plans: []
    };
  }

  navigateTo(subscription, trialDays) {
    this.props.history.push({
      pathname: `/pay/${subscription}`,
      state: {
        email: this.email,
        trialPeriod: trialDays
      }
    });
  }

  componentDidMount() {
    // stripeApi
    //   .getSubscriptionPlans()
    //   .then(plans => {
    //     this.setState({ isLoaded: true, plans });
    //   })
    //   .catch(err => {
    //     this.setState({
    //       isLoaded: true
    //     });
    //   });
  }

  getSnapshotBeforeUpdate = ({ customers }) =>
    customers[CustomerActions.Get].isBusy;

  componentDidUpdate(props, state, snapshot) {
    const { customers, history } = this.props;
    if (!snapshot && !!customers.data.length) {
      // coming to subscribe page directly
      // remove this if want to support multiple location
      history.push('/#subscriptions');
      return;
    }
  }

  render() {
    const { isLoaded } = this.state;
    if (!isLoaded) {
      return <PageLoading description="Checking available plans." />;
    }
    // const { customers } = this.props;
    return (
      <>
      <Banner order={2}/>
      <Container className="text-center pt-5 pb-2">
        <div className="">
          <img
            className="d-block mx-auto mb-4"
            src={logo}
            alt=""
            width="72"
            height="72"
          />
          <h6><u>All plans come with a 30-day free trial</u></h6>
          <p className="lead">Select your subscription plan</p>
          <h2>Plans</h2>
        </div>

        <div className="row justify-content-center">
          <div className="col col-md-10 col-lg-8">
            <div className="card-deck mb-1 text-center">
              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Yearly Membership</h4>
                </div>
                <div className="card-body">
                  <h6>Save $30 a month</h6>
                  <h1 className="card-title pricing-card-title">
                    $49 <small className="text-muted">/ mo</small>
                  </h1>
                  <p>
                    <i>After <b>30-day free trial</b></i>
                  </p>

                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Unlimited loopHQ events and tools</li>
                    <li>Paid annually $588</li>
                    <li>Cancel anytime</li>
                  </ul>
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() =>
                      this.navigateTo(process.env.REACT_APP_YEARLY_PLAN_ID, 30)
                    }
                  >
                    Get started
                  </button>
                </div>
              </div>

              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    Monthly Membership
                  </h4>
                </div>
                <div className="card-body">
                  <h6>Short term commitment</h6>
                  <h1 className="card-title pricing-card-title">
                    $79 <small className="text-muted">/ mo</small>
                  </h1>
                  <p>
                    <i>After <b>30-day free trial</b></i>
                  </p>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Unlimited loopHQ events and tools</li>
                    <li>Paid monthly</li>
                    <li>Cancel anytime</li>
                  </ul>
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary"
                    onClick={() =>
                      this.navigateTo(process.env.REACT_APP_MONTHLY_PLAN_ID, 30)
                    }
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div>
            <p>
              <small>
                Upon cancellation, service access and use will end upon the
                completion of a paid billing period. Service access will end
                immediately upon cancellation during the free trial period.
                There is no refund for early termination.
              </small>
            </p>
            <p className="mt-5 mb-3 text-muted">© loopHQ</p>
          </div>
        </div>
      </Container>
      </>
    );
  }
}

export default connect(({ customers }) => ({ customers }))(Subscribe);
