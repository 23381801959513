import React from 'react';
import { Input } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import { processFile } from '../Helpers';

class Ad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(image) {
    const { pictures } = this.imagePicker.state;
    const {
      methods: { onInput }
    } = this.props;

    if (image.length > 1) {
      image.shift();
    }

    if (pictures.length > 0) {
      this.imagePicker.setState({
        pictures: [pictures.pop()]
      });
    }
    if (image && image.length) {
      processFile(image[0], data => {
        if (!data) {
          return;
        }
        onInput({
          nativeEvent: {
            preventDefault: () => false,
            target: {
              id: 'image',
              value: {
                data
                // type
              }
            }
          }
        });
      });
    } else {
      onInput({
        nativeEvent: {
          preventDefault: () => false,
          target: {
            id: 'image',
            value: -1
          }
        }
      });
    }
  }

  render() {
    const {
      methods: { onInput },
      ad: { link, image }
    } = this.props;
    let defaultImage = null;
    if (typeof image === 'string' && image.startsWith('http')) {
      defaultImage = image;
    }
    return (
      <>
        <ImageUploader
          singleImage={true}
          withIcon={!image}
          defaultImage={defaultImage}
          ref={imagePicker => (this.imagePicker = imagePicker)}
          buttonText="Choose image"
          onChange={this.onDrop}
          imgExtension={['.jpg', '.gif', '.png', '.svg', '.jpeg']}
          maxFileSize={10242880}
          label="Max file size: 10mb, accepted: jpg | gif | png | svg"
        />
        <label>Ad URL</label>
        <Input
          type="text"
          id="link"
          className="form-control mb-3"
          placeholder="Enter URL"
          value={link}
          onChange={onInput}
        />
      </>
    );
  }
}

export default Ad;
