import React from 'react';
import { Container } from 'reactstrap';
import Select from 'react-select';

import { firebase, Collections } from '../../firebase';
import Service from '../extras/services/Review';

const places = ['Bars', 'Restaurant', 'Hospital', 'Museum', 'Book Store'].map(
  type => ({
    value: type.toLowerCase().replace(/\s/g, '_'),
    label: type
  })
);

const makeIcon = place => ({
  url: place.icon,
  // eslint-disable-next-line no-undef
  size: new google.maps.Size(71, 71),
  // eslint-disable-next-line no-undef
  origin: new google.maps.Point(0, 0),
  // eslint-disable-next-line no-undef
  anchor: new google.maps.Point(17, 34),
  // eslint-disable-next-line no-undef
  scaledSize: new google.maps.Size(25, 25)
});

const makeMarker = (map, place) =>
  // eslint-disable-next-line no-undef
  new google.maps.Marker({
    map,
    icon: makeIcon(place),
    title: place.name,
    position: place.geometry.location
  });

const makeContent = (place, isBusy) => `
<h6>${place.name}</h6>
<p>${place.vicinity}</p>
<button ${
  isBusy ? 'disabled' : ''
} onClick="window.handleMarker()" class="btn btn-primary btn-block">${
  isBusy ? 'Please wait...' : 'More info'
}</button>
`;

class Maps extends React.Component {
  markers = [];
  constructor(props) {
    super(props);
    this.userEmail = localStorage.getItem('authUser');
    this.state = {};
  }

  componentDidMount() {
    if (window.mapInitialized || this.map) {
      return;
    }
    this.initMaps();
  }

  initMaps = () => {
    window.handleMarker = () => {
      if (!this.existing) {
        alert('No location found for moving in. Please wait');
      } else {
        const { history } = this.props;
        history.push(`/${this.existing.id}/${this.userEmail}/0/0/loopHQ`);
      }
    };
    // eslint-disable-next-line no-undef
    this.map = initMaps();
    // eslint-disable-next-line no-undef
    this.placeService = new google.maps.places.PlacesService(this.map);
    // this.setUpMap();
    this.readUserLocation();
  };

  componentWillUnmount() {
    window.mapInitialized = false;
  }

  readUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        // eslint-disable-next-line no-undef
        const location = new google.maps.LatLng(
          coords.latitude,
          coords.longitude
        );
        if (this.map) {
          this.map.setCenter(location);
          this.map.setZoom(13);
        }
        this.setState({ location });
      },
      error => alert(error.message)
    );
  };

  handleLocationCreation = (result, status) => {
    // eslint-disable-next-line no-undef
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const locationData = {
        stars: 0,
        floor: 0,
        image: null,
        radius: 0.05,
        starsCount: 0,
        isOrphan: true,
        redirectUrl: '',
        description: '',
        customerID: null,
        promoWebview: true,
        promoText: 'More info',
        userEmail: 'info@2mann.com',
        showIncomingRequests: false,
        ...Service.buildLocationFromRaw({
          ...window.chosenPlace,
          ...Service.mapAddressComponents(result)
        })
      };
      firebase.db
        .collection(Collections.Locations)
        .add(locationData)
        .then(docRef => {
          locationData.id = docRef.id;
          this.existing = locationData;
          this.popup.setContent(makeContent(window.chosenPlace, false));
        });
    }
  };

  handlePlaceType = option => {
    const { location } = this.state;
    if (!location) {
      alert('No location');
      return;
    }
    const request = {
      location,
      radius: 1000,
      type: option.value
    };
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();
    this.placeService.nearbySearch(request, (results, status) => {
      if (
        // eslint-disable-next-line no-undef
        status !== google.maps.places.PlacesServiceStatus.OK ||
        !results.length
      ) {
        return;
      }
      this.markers.forEach(marker => marker.setMap(null));
      this.markers = [];
      // eslint-disable-next-line no-undef
      this.popup = new google.maps.InfoWindow({
        content: ''
      });
      results.forEach(place => {
        // eslint-disable-next-line no-undef
        const marker = makeMarker(this.map, place);
        marker.place = place;
        marker.addListener('click', async evt => {
          window.chosenPlace = marker.place;
          this.popup.setContent(makeContent(place, true));
          this.popup.open(this.map, marker);
          const geo = new firebase.firebase.firestore.GeoPoint(
            marker.place.geometry.location.lat(),
            marker.place.geometry.location.lng()
          );
          this.existing = false;
          firebase.db
            .collection(Collections.Locations)
            .where('coordinates', '==', geo)
            .get()
            .then(itemsSnap => {
              if (itemsSnap.docs.length) {
                const item = itemsSnap.docs[0];
                this.popup.setContent(makeContent(place, false));
                this.existing = {
                  id: item.id,
                  ...item.data()
                };
              } else {
                this.placeService.getDetails(
                  {
                    placeId: marker.place.place_id,
                    fields: ['address_component']
                  },
                  this.handleLocationCreation
                );
              }
            })
            .catch(() => null /* ignore */);
        });
        this.markers.push(marker);
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  };

  render() {
    return (
      <Container>
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <Select options={places} onChange={this.handlePlaceType} />
        </div>
        <div id="map" style={{ height: 500, width: '100%' }} />
      </Container>
    );
  }
}

export default Maps;
