/**
 * Provides abstraction over the real Button
 */
import React from 'react';
import { Button } from 'reactstrap';
import spinner from '../assets/images/hq-loading-spinner.svg';

const CustomButton = ({ isBusy, children, ...props }) => (
  <Button {...props} disabled={isBusy || props.disabled}>
    {isBusy ? (
      <img alt="Loading" src={spinner} style={{ width: 28, height: 28 }} />
    ) : (
      children
    )}
  </Button>
);

export default CustomButton;
