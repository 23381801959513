import React from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import XLSX from 'xlsx';

import { Button, withMessage } from '../components';
import HomeNav from '../components/HomeNav';
import { db } from '../../firebase/firebase';

class Uploader extends React.Component {
  state = {
    fileInfo: null,
    questions: [],
    isReading: false,
    isClicked: false,
    isUploading: false
  };

  handleFileSelect = ({
    nativeEvent: {
      target: { files }
    }
  }) => {
    if (!files.length) {
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    const { showError, isValidCSV } = this.props;
    reader.onload = ({ target: { result } }) => {
      this.setState({ isReading: true });
      setTimeout(() => {
        const workbook = XLSX.read(result, { type: 'string', raw: true });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const questions = XLSX.utils.sheet_to_json(sheet);
        if (!isValidCSV(questions)) {
          showError('CSV Invalid', 'Please make sure your CSV is valid.');
        }
        this.setState({ isReading: false, questions });
      });
    };
    reader.readAsText(file);
    this.setState({
      fileInfo: {
        name: file.name,
        size: file.size,
        lastModified: file.lastModified
      }
    });
  };

  selectFile = () => {
    this.setState({ isClicked: true });
    this.csvPicker.click();
    setTimeout(() => {
      this.setState({ isClicked: false });
    }, 1400);
  };

  uploadFile = async () => {
    const { questions } = this.state;
    const owner = localStorage.getItem('authUser');
    const {
      showSuccess,
      showError,
      history,
      isValidCSV,
      categories,
      info,
      customQuestions
    } = this.props;
    if (!isValidCSV(questions)) {
      showError('CSV Invalid', 'Please make sure your CSV is valid.');
      return;
    }
    try {
      this.setState({ isUploading: true });
      const insertPoint =
        categories.findIndex(cat => cat.value === 'Custom') + 1;
      const now = Date.now();
      const uploadable = questions.map((question, index) => {
        const customizedQuestion = {
          level: 'medium',
          type: 'Trivia',
          points: 10,
          ...question,
          answer: question.answer || '', // for matches
          question: question.question
        };
        if (
          !categories.find(
            category =>
              !category.isSystem &&
              category.value.toLowerCase() ===
                customizedQuestion.category.toLowerCase()
          )
        ) {
          categories.splice(insertPoint, 0, {
            value: customizedQuestion.category,
            label: customizedQuestion.category,
            isSystem: false,
            isTitle: false
          });
        }
        customizedQuestion.id = now + '_id_' + index;
        customQuestions.push(customizedQuestion);
        return customizedQuestion;
      });
      await db.collection(info.table).add({
        owner,
        questions: uploadable
      });
      this.setState({
        fileInfo: null,
        questions: []
      });
      showSuccess('Data uploaded!', 'The data has been saved successfully.');
      history.push('/event/create');
    } catch (exp) {
      console.log(exp);
      showError('Upload failed!', 'An error occurred while saving the data.');
      this.setState({ isUploading: false });
    }
  };

  render() {
    const {
      isClicked,
      fileInfo,
      isReading,
      questions,
      isUploading
    } = this.state;
    let btnText = 'Select CSV file';
    if (isClicked) {
      btnText = 'Opening picker...';
    } else if (isReading) {
      btnText = 'Checking file...';
    }
    const { info } = this.props;
    return (
      <>
        <HomeNav />
        <Container>
          <Row className="justify-content-center pt-4 pb-4">
            <Col sm={9} md={7} lg={5}>
              {!fileInfo && (
                <>
                  <Alert color="warning">
                    <span>You haven't selected any file.</span>
                  </Alert>
                  <Alert color="info">
                    <span className="d-block mb-2">
                      The CSV file must be structured as{' '}
                      <strong>{info.cols}</strong> and first row must have these
                      titles.
                    </span>
                    <small>
                      Supported files are: <strong>CSV</strong>,
                      <strong> XLS</strong>, and
                      <strong> XLSX</strong>
                    </small>
                  </Alert>
                </>
              )}
              {fileInfo && (
                <Alert color="success">
                  <span>
                    You have selected <strong>{fileInfo.name}</strong> (
                    {Number(fileInfo.size / 1024).toFixed(2)} KB)
                    {!!questions.length && (
                      <small className="d-block">
                        The file has {questions.length} rows.
                      </small>
                    )}
                  </span>
                </Alert>
              )}
              <input
                type="file"
                className="d-none"
                accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={this.handleFileSelect}
                ref={csvPicker => (this.csvPicker = csvPicker)}
              />
              <Button
                disabled={isClicked || isReading}
                color="primary"
                block
                onClick={this.selectFile}
              >
                {btnText}
              </Button>
              <Button
                isBusy={isUploading}
                disabled={!fileInfo}
                color="success"
                block
                onClick={this.uploadFile}
              >
                {info.btn}
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withMessage(Uploader);
