import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import { CustomerActions, LocationActions } from '../actions';
import { withMessage, PageLoading, Footer } from '../components';
import HomeNav from '../components/HomeNav';
import Banner from '../components/OnBoardBanner';

class Home extends Component<{
  history: Object,
  customers: Object,
  locations: Object,
  listenCustomer: Function,
  listenLocation: Function
}> {
  userEmail = null;

  constructor(props) {
    super(props);
    this.userEmail = localStorage.getItem('authUser');
    if (!this.userEmail) {
      props.history.push('/');
    }
    this.state = {};
  }

  gotoPage = (pathname, state = {}) => {
    const { history } = this.props;
    history.push({
      pathname,
      state: {
        email: this.userEmail,
        ...state
      }
    });
  };

  edit = (locationId = null) =>
    this.gotoPage('/edit', {
      locationId
    });

  render() {
    const { customers, locations, children } = this.props;
    const isLoading =
      customers[CustomerActions.Get].isBusy ||
      locations[LocationActions.Get].isBusy;

    if (isLoading && !customers.data.length && !locations.data.length) {
      return <PageLoading description="Building your experience ;)" />;
    }

    /**
     * The customers and locations data has been loaded here
     */
    if (!customers.data.length) {
      // the customer profile doesn't exist
      setTimeout(() => this.gotoPage('/subscribe'), 0); // prevent react's complain about unmounted component
      return (
        <PageLoading description="Your customer profile is missing. Redirecting to subscribe." />
      );
    }

    if (!locations.data.length) {
      // the customer location doesn't exist
      setTimeout(this.edit, 0); // prevent react's complain about unmounted component
      return (
        <PageLoading description="Your location information is missing. Redirecting to location." />
      );
    }
    return (
      <>
        <Banner order={5} />
        <HomeNav />
        <Container className="mainContainer">
          <Row className="text-left justify-content-center">
            <Col sm={8} md={6} lg={4}>
              {children}
              <Footer />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withMessage(
  connect(({ locations, customers }) => ({ locations, customers }))(Home)
);
