import React from 'react';
import { makeExternalLink, showLoading } from '../Helpers';

const AdCard = ({ event, eventLocation }) => {
  const { Ad } = event;
  let image;
  if (Ad.image && Ad.image.data){
    image = Ad.image.data;
  } else {
    image = Ad.image;
  }
  // if (!Ad.image || !Ad.link) {
  //   return null;
  // }
  if (Ad.link) {
    Ad.link = makeExternalLink(Ad.link);
  }
  return (
    <div className="ad mb-4">
      <a href={Ad.link} className="external-link" onClick={showLoading}>
        <img alt="Advertisement" src={image} className="img-fluid" />
      </a>
    </div>
  );
};

export default AdCard;
