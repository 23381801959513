import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Form, Input } from 'reactstrap';

import { auth, Collections, firebase } from '../../firebase';
import logo from './../assets/images/loophq-icon.svg';
import { Button } from '../components';

import Banner from '../components/OnBoardBanner';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  isLoading: false
};

class SignUp extends Component {
  email;

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      ...INITIAL_STATE,
      locationID: urlParams.get('locationID'),
      isFetching: true
    };
    this.navigateTo = this.navigateTo.bind(this);
  }

  navigateTo(e) {
    e.preventDefault();
    this.props.history.push('/');
  }

  onSubmit = event => {
    const { email, password, locationID } = this.state;
    const { history } = this.props;
    event.preventDefault();
    this.setState({
      isLoading: true
    });

    auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        this.setState({
          ...INITIAL_STATE
        });
        const profile = {
          locationID,
          userEmail: authUser.user.email,
          userFname: '',
          userLname: '',
          customerID: ''
        };
        // TODO: clean up these logics, move them to single function
        // from login and here
        localStorage.setItem('profile', JSON.stringify(profile));
        firebase.db
          .collection(Collections.Users)
          .doc(authUser.user.uid)
          .set(profile);
        history.push({
          pathname: '/subscribe',
          state: { email: email }
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false
        });
      });
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { email, password, error, isLoading } = this.state;

    const isInvalid = password === '' || email === '' || isLoading;

    return (
      <>
        <Banner order={1} />
        <Container className="text-center">
          <div className="row justify-content-center">
            <div className="col col-sm-8 col-md-6 col-lg-4">
              <div className="pb-1 pt-5 text-center">
                <img
                  className="d-block mx-auto mt-2 mb-4"
                  src={logo}
                  alt=""
                  width="72"
                  height="72"
                />
                <h5>
                  Sign up to organize hassle-free pop-up events and social
                  events
                </h5>
              </div>
              <Form
                className="form-login"
                autoComplete="off"
                onSubmit={this.onSubmit}
              >
                <Input
                  name="email"
                  type="email"
                  id="email"
                  value={email}
                  onChange={this.onChange}
                  bsSize="lg"
                  className="form-control mb-3"
                  placeholder="Email address"
                  autoFocus="autoFocus"
                />
                <Input
                  name="password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={this.onChange}
                  bsSize="lg"
                  className="form-control mb-3"
                  placeholder="Password"
                />
                <Button
                  color="primary"
                  block
                  type="submit"
                  isBusy={isLoading}
                  disabled={isInvalid}
                >
                  Sign up
                </Button>

                {error && (
                  <p className="text-danger">
                    <br />
                    {error.message}
                  </p>
                )}
                <p className="mt-4 mb-4 text-muted">
                  By creating an account, you agree to our{' '}
                  <a href="https://loophereandnow.com/terms">terms of use</a>{' '}
                  and{' '}
                  <a href="https://loophereandnow.com/privacy">
                    privacy policy
                  </a>
                </p>

                <Button
                  onClick={this.navigateTo}
                  color="secondary"
                  block
                  type="button"
                >
                  Already registered? Login
                </Button>
                <p className="mt-5 mb-3 text-muted">&copy; loopHQ</p>
              </Form>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default withRouter(SignUp);
