import React from 'react';
import { Input } from 'reactstrap';

const Video = ({ methods: { onInput }, video: { link } }) => (
  <>
    <label>Youtube/Vimeo</label>
    <Input
      type="text"
      id="link"
      className="form-control mb-3"
      placeholder="Enter embed URL"
      value={link}
      onChange={onInput}
    />
  </>
);

export default Video;
