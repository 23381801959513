import React from 'react';
import { connect } from 'react-redux';

import { PageLoading } from '../components';
import { auth } from '../../firebase';
import { LocationActions, ReviewActions, CustomerActions } from '../actions';
import createStore from '../../configStore';

const { persistor } = createStore();

class Logout extends React.Component {
  componentDidMount() {
    const { clearState } = this.props;
    persistor.purge();
    clearState();
    auth.doSignOut();
  }

  render() {
    return <PageLoading description="Bye! Hope to see you soon." />;
  }
}

export default connect(
  null,
  dispatch => ({
    clearState: () => {
      dispatch({ type: LocationActions.Clear, what: LocationActions.Success });
      dispatch({ type: ReviewActions.Clear, what: ReviewActions.Success });
      dispatch({ type: CustomerActions.Clear, what: CustomerActions.Success });
    }
  })
)(Logout);
