import React, { Component } from 'react';
import { Form, Button, Input, Row, Col } from 'reactstrap';

class Play extends Component {
  constructor(props) {
    super(props);
    const { answers } = props;
    this.state = {
      answer: '',
      index: Math.max(0, answers.length - 1)
    };
  }

  handleChange = ({ nativeEvent: { target } }) => {
    this.setState({
      answer: target.value
    });
  };

  handlePrev = () => {
    document.querySelector('#form').reset();
    this.setState(state => ({ index: state.index - 1 }));
  };

  handleNext = event => {
    if (event) {
      event.preventDefault();
    }
    const { index, answer } = this.state;
    const { onAnswer } = this.props;
    onAnswer(index, answer);
    document.querySelector('#form').reset();
    this.setState({ index: index + 1, answer: '' });
  };

  render() {
    const { questions, answers, onDone } = this.props;
    const { index } = this.state;
    const trivia = questions[index];
    let answer = this.state.answer;
    if (index < answers.length && answers[index]) {
      answer = answers[index];
    }
    if (index === questions.length) {
      return (
        <div className="m-4">
          <p>You have completed the trivia.</p>
          <Button block color="primary" className="mt-2 mb-2" onClick={onDone}>
            Done
          </Button>
        </div>
      );
    }
    return (
      <div className="m-4">
        <div className="closeBtnTrivia">
          <ion-icon name="close" onClick={onDone} />
        </div>
        <p
          className="text-center m-4"
          style={{ color: 'gray', fontWeight: 'bold' }}
        >
          {index + 1}/{questions.length}
        </p>
        <h4 className="text-center">{unescape(trivia.question)}</h4>
        <Form id="form" className="m-2" onSubmit={this.handleNext}>
          <Input
            className="mb-2 mt-2"
            defaultValue={answer}
            type="text"
            onChange={this.handleChange}
          />
          <Row>
            <Col className="col" md={6} sm={12}>
              <Button
                outline
                block
                color="primary"
                className="mb-2"
                onClick={this.handlePrev}
                disabled={!index}
              >
                &lt; Back
              </Button>
            </Col>
            <Col className="col" md={6} sm={12}>
              <Button
                block
                color="primary"
                className="mb-2"
                onClick={this.handleNext}
              >
                {index === questions.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Play;
