import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

class Error extends Component {
  render() {
    return (
      <Container className="text-center p-5 mb-2">
        Page not found or not authorised to access!
        <Link to="/" className="mt-4" color="secondary" size="lg" block="block">
          {' '}
          Go to home page
        </Link>
      </Container>
    );
  }
}

export default Error;
