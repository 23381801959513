import { LocationActions } from '.';
import { Collections } from '../../firebase';
import firebaseAction from './FirebaseAction';

const locationAction = (type, params) => {
  return dispatch => {
    firebaseAction({
      type,
      dispatch,
      data: params,
      conditions: [
        {
          method: 'where',
          name: 'userEmail',
          operation: '==',
          value: params ? params.userEmail : null
        }
      ],
      action: LocationActions,
      collection: Collections.Locations
    });
  };
};

export default locationAction;
