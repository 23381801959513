import React from 'react';
import { Input } from 'reactstrap';

import FormField from './FormField';
import { Button } from '../../components';

class Form extends React.Component {
  componentDidMount() {
    const { fields } = this.props;
    if (!fields.length) {
      this.addRow();
    }
  }

  addRow = () => {
    const {
      fields,
      methods: { onInput }
    } = this.props;
    onInput({
      nativeEvent: {
        target: {
          id: 'fields',
          value: fields.concat({ type: 'text', title: '', isRequired: 'yes' })
        }
      }
    });
  };

  handleRemove = index => {
    const {
      fields,
      methods: { onInput }
    } = this.props;
    onInput({
      nativeEvent: {
        target: {
          id: 'fields',
          value: fields.filter((link, i) => i !== index)
        }
      }
    });
  };

  handleInput = (index, node) => {
    const {
      fields,
      methods: { onInput }
    } = this.props;
    fields[index][node.id] = node.value;
    onInput({
      nativeEvent: {
        target: {
          id: 'fields',
          value: fields
        }
      }
    });
  };

  render() {
    const {
      button,
      fields,
      methods: { onInput }
    } = this.props;
    return (
      <div className="mb-2">
        <label>Button text</label>
        <Input
          className="form-control mb-3"
          required
          type="text"
          id="button"
          placeholder="Eg. Sign up"
          defaultValue={button}
          onChange={onInput}
        />
        {fields.map((field, index) => {
          return (
            <FormField
              key={index}
              method={{
                onInput: event =>
                  this.handleInput(index, event.nativeEvent.target),
                onDelete: () => this.handleRemove(index)
              }}
              field={field}
            />
          );
        })}
        <Button
          block
          className="mb-4"
          color="outline-primary"
          onClick={() => this.addRow()}
        >
          Add new field
        </Button>
      </div>
    );
  }
}

export default Form;
