import moment from 'moment';
import 'moment-timezone';

import * as loadImage from 'blueimp-load-image';

import TriviaCard from './categories/TriviaCard';
import PollCard from './categories/PollCard';
// import PromoCard from './categories/PromoCard';
import StickyCard from './categories/StickyCard';
import Service from '../extras/services/Review';
import { firebase } from '../../firebase';
import AdCard from './categories/AdCard';
import VideoCard from './categories/VideoCard';
import QuickLinkCard from './categories/QuickLinkCard';
import { db } from '../../firebase/firebase';
import FormCard from './categories/FormCard';
import MatchCard from './categories/MatchCard';

const Folder = {
  Events: 'events/',
  Location: 'location/'
};

/**
 * DONOT modify this, this is for internal use
 * Also, it is used by cloud functions (copy pasted from here)
 */
export const RecurringType = {
  None: 'none',
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Year: 'year',
  Weekday: 'weekday',
  Weekend: 'weekend'
};

const recurringTypes = [
  { name: 'Does not repeat', value: RecurringType.None },
  { name: 'Every day', value: RecurringType.Day },
  { name: 'Every week', value: RecurringType.Week },
  { name: 'Every month', value: RecurringType.Month },
  { name: 'Every year', value: RecurringType.Year },
  {
    name: 'Every weekday (Monday - Friday)',
    value: RecurringType.Weekday
  },
  {
    name: 'Every weekend (Saturday - Sunday)',
    value: RecurringType.Weekend
  }
];

const eventTypes = [
  /**
   * Card -> The Card to show for this event
   * name -> The display name shown to user
   * value -> The internal name to be used by the code (must be unique)
   */
  // { Card: null, name: 'Select Event Type', value: 'none' },
  { Card: StickyCard, name: 'Info Card', value: 'Sticky' },
  //{ Card: PromoCard, name: 'Promo Event', value: 'Promo' },
  { Card: PollCard, name: 'Quick Poll', value: 'Poll' },
  { Card: AdCard, name: 'Ad', value: 'Ad' },
  { Card: VideoCard, name: 'Video', value: 'Video' },
  { Card: QuickLinkCard, name: 'Quick links', value: 'QuickLink' },
  { Card: FormCard, name: 'Forms', value: 'Forms' },
  // {
  //   Card: QuickLinkCard,
  //   name: 'Quick links - bar preset',
  //   value: 'QuickLinkBar'
  // },
  { Card: TriviaCard, name: 'Trivia', value: 'Trivia' },
  { Card: MatchCard, name: 'Match Test (speed dating)', value: 'MatchTest' },
  { Card: null, name: 'Raffle - Coming soon', value: 'Raffle' }
  // { Card: null, name: 'Family Feud - Coming soon', value: 'Family' },
  // { Card: null, name: 'Survey - Coming soon', value: 'Survey' },
  // { Card: null, name: 'Speed dating - Coming soon', value: 'SpeedDating' },
  // { Card: null, name: 'Sign Up - Coming soon', value: 'SignUp' },
  // { Card: null, name: 'Contact information - Coming soon', value: 'Contact' },
  // { Card: null, name: 'Invite - Coming soon', value: 'Invite' },
  // { Card: null, name: 'Registration - Coming soon', value: 'Registration' },
  // { Card: null, name: 'Feedback - Coming soon', value: 'Feedback' },
  // { Card: null, name: 'Application - Coming soon', value: 'Application' }
];

const categories = [];
const matchCategories = [];
export const customQuestions = [];
export const customMatchQuestions = [];

const loadCategories = () => {
  loadBothCategories(
    'trivias',
    categories,
    customQuestions,
    'getEventCategories'
  );
  loadBothCategories(
    'match',
    matchCategories,
    customMatchQuestions,
    'getMatchCategories'
  );
};

const loadBothCategories = (collection, catCollector, quesCollector, func) => {
  if (catCollector.length) {
    return;
  }
  db.collection(collection)
    .where('owner', '==', localStorage.getItem('authUser'))
    .get()
    .then(questions => {
      catCollector.push({
        value: 'Custom',
        label: 'Custom',
        isSystem: false,
        isTitle: true
      });
      const unique = [];
      questions.docs.forEach(questionSnapshot => {
        questionSnapshot.data().questions.forEach((question, index) => {
          quesCollector.push({
            ...question,
            id: questionSnapshot.id + '_' + index
          });
          if (!unique.includes(question.category)) {
            unique.push(question.category);
          }
        });
      });
      unique.forEach(category =>
        catCollector.push({
          value: category,
          label: category,
          isSystem: false,
          isTitle: false
        })
      );
    });
  Service[func]()
    .then(response => {
      catCollector.push({
        value: 'System',
        label: 'System',
        isSystem: false,
        isTitle: true
      });
      response.data.forEach(category =>
        catCollector.push({
          value: category,
          label: category,
          isSystem: true,
          isTitle: false
        })
      );
    })
    .catch(() =>
      catCollector.push({ value: '-1', label: "Couldn't load categories" })
    );
};

const emailMethods = [
  { name: "Yes, it's required", value: 'required' },
  { name: "Yes, but it's not required", value: 'optional' },
  { name: 'No', value: 'no' }
];

const getInitialState = () => ({
  title: '',
  description: '',
  type: null,
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  startTime: moment().format('HH:mm'),
  endTime: moment().format('HH:mm'),
  order: 0,
  leaderboard: null,
  isArchived: false,
  isRecurring: false,
  isUploading: false,
  recurring: 'none',
  ...eventTypes
    .map(eventType => eventType.value)
    .reduce(
      (result, item) => ({
        ...result,
        [item]: {}
      }),
      {}
    )
});

const validate = ({ title, type, description, startDate }) => {
  if (!type || !type.trim().length) {
    return {
      title: 'Type is required',
      message: 'Please set a type for your event.'
    };
  }
  if (type.includes('QuickLink')) {
    return null;
  }
  if (!title || !title.trim().length) {
    return {
      title: 'Title is required',
      message: 'Please enter a title for the event.'
    };
  }
  // if (
  //   !description ||
  //   !description.trim().length ||
  //   description.split(/\s/gi).length < 2
  // ) {
  //   return {
  //     title: 'Description is required',
  //     message: 'Please describe your event in a few words.'
  //   };
  // }
  return null;
};

const isWithInTime = event => {
  const start = toLocal(event.utcStart);
  const endTime = toLocal(event.utcEnd);
  const current = moment();
  return current.isSameOrAfter(start) && current.isSameOrBefore(endTime);
};

const toUTC = (date, time, zone) =>
  moment
    .tz(date + ' ' + time + ':00', zone)
    .utc()
    .clone()
    .toDate()
    .toUTCString();

const toLocal = utcDate => {
  let date = utcDate;
  if (utcDate.seconds) {
    date = new Date(utcDate.toMillis());
  }
  return moment(date)
    .clone()
    .local();
};

const toOriginal = (utcDate, timeZone) => {
  let date = utcDate;
  if (utcDate.seconds) {
    date = new Date(utcDate.toMillis());
  }
  return moment(date).tz(timeZone);
};

const setTimes = event => {
  event.startDate = toLocal(event.utcStart).format('YYYY-MM-DD');
  event.startTime = toLocal(event.utcStart).format('HH:mm');
  event.endTime = toLocal(event.utcEnd).format('HH:mm');
  return event;
};

const search = (collection, query) => {
  if (!query || !query.trim().length) {
    return collection;
  }
  return collection.filter(entry => {
    return (
      (entry.question &&
        unescape(entry.question)
          .toLowerCase()
          .includes(query)) ||
      (entry.correct_answer &&
        entry.correct_answer.toLowerCase().includes(query))
    );
  });
};

const sendToApp = (action, data) => {
  const payload = {
    action,
    data
  };
  postMessage(JSON.stringify(payload), '*');
};

const shareToNative = (url, title, message) => {
  sendToApp('open-native-share', {
    url,
    title,
    message
  });
};

// const makeUrl = paths =>
//   [window.location.protocol + '/', window.location.host, ...paths].join('/');

const shareLocation = location => {
  //const url = makeUrl(['location', location.id]);
  const url = 'https://loophereandnow.com/app';
  const message = `Hey! I am here in ${location.name}, Wanna come? \n ${url}`;
  shareToNative(url, location.name, message);
};

const shareEvent = (event, location, upcomingType) => {
  //const url = makeUrl(['event', event.id]);
  const url = 'https://loophereandnow.com/app';
  let message;
  //const startsAt = toLocal(event.utcStart).format('hh:mm A');
  //const startsOn = toLocal(event.utcStart).format('MMM DD');
  /*if (upcomingType === 'upcoming') {
    message = `Hey! ${event.title} on ${startsOn}, ${startsAt} at ${location.name}. Wanna go?${'\n'}${url}`;
  } else if (upcomingType === 'today-upcoming') {
    message = `Hey! I am here in ${location.name}, the ${event.title} starts at ${startsAt}. Are you close? Wanna come?${'\n'}${url}`;
  } else {
    message = `Hey! I am here in ${location.name}, the ${event.title} started at ${startsAt}. Are you close? Wanna come?${'\n'}${url}`;
  }*/
  message = `Hey! I am here in ${location.name}, check out ${
    event.title
  }. ${'\n'}${url}`;
  shareToNative(url, event.title, message);
};

const uploadImage = async (path, image, isBase64) => {
  try {
    const imgRef = firebase.storage().ref(path);
    let imgTask;
    if (isBase64) {
      imgTask = await imgRef.putString(image.data, 'data_url', {
        contentType: 'image/' + image.type
      });
    } else {
      imgTask = await imgRef.put(image);
    }
    return await imgTask.task.snapshot.ref.getDownloadURL();
  } catch (err) {
    console.error('image upload error', err);
  }
  return null;
};

const uploadEventImage = async (image, isBase64 = true) => {
  return await uploadImage(
    Folder.Events + Date.now() + '' + Math.random() * 1000,
    image,
    isBase64
  );
};

const uploadLocationImage = async (image, isBase64 = true) => {
  return await uploadImage(
    Folder.Location + Date.now() + '' + Math.random() * 1000,
    image,
    isBase64
  );
};

const processFile = async (imageFile, onLoad) => {
  let base64;
  let dataURL;
  let type;
  const image = new Image();
  const maxWidth = 800;
  const maxHeight = 800;
  let orientation = 0;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  loadImage(
    imageFile,
    (canvas, imgdata) => {
      if (imgdata.exif) {
        if (typeof imgdata.exif.get('Orientation') !== 'undefined') {
          orientation = imgdata.exif.get('Orientation');
        }
      }

      base64 = canvas.toDataURL('image/jpeg', 0.7);
      dataURL = base64;
      type = base64.split(';')[0].split('/')[1];
      image.src = dataURL;
    },
    {
      canvas: true,
      //aspectRatio: true,
      downsamplingRatio: true,
      crop: false,
      orientation: true,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      meta: true
    }
  );
  image.onload = function() {
    const width = image.width;
    // console.log(width);
    const height = image.height;
    // console.log(height);
    const shouldResize = width > maxWidth || height > maxHeight;

    if (!shouldResize) {
      return onLoad(dataURL);
    }

    let newWidth;
    let newHeight;
    if (width > height) {
      newHeight = height * (maxWidth / width);
      newWidth = maxWidth;
    } else {
      newWidth = width * (maxHeight / height);
      newHeight = maxHeight;
    }

    canvas.width = newWidth;
    canvas.height = newHeight;
    context.drawImage(this, 0, 0, newWidth, newHeight);
    return onLoad(dataURL);
  };

  image.onerror = function() {
    alert('There was an error processing your file!');
    image.onLoad();
  };
};

const quickLinkPresets = {
  bar: [
    {
      id: 'bar_0',
      icon: require('../assets/ql-icons/ql-icon-menu.svg'),
      title: 'Menu',
      shortDescription: 'Satisfy your cravings',
      linkText: 'Order',
      link: '#'
    },
    {
      id: 'bar_1',
      icon: require('../assets/ql-icons/ql-icon-discount.svg'),
      title: 'Deals',
      shortDescription: 'Our money-saving offers',
      linkText: 'Save',
      link: '#'
    },
    {
      id: 'bar_2',
      icon: require('../assets/ql-icons/ql-icon-brunch.svg'),
      title: 'Brunch',
      shortDescription: 'Bottomless drinks all day',
      linkText: 'Book',
      link: '#'
    },
    {
      id: 'bar_3',
      icon: require('../assets/ql-icons/ql-icon-happyhour.svg'),
      title: 'Happy Hour',
      shortDescription: "Check out today's selection",
      linkText: 'Order',
      link: '#'
    },
    {
      id: 'bar_4',
      icon: require('../assets/ql-icons/ql-icon-info.svg'),
      title: 'Info',
      shortDescription: 'Delivery, pick-up, reservation, contact...',
      linkText: 'Open',
      link: '#'
    },
    {
      id: 'bar_5',
      icon: require('../assets/ql-icons/ql-icon-pay.svg'),
      title: 'herePay',
      shortDescription: 'No hassle pay, split, and tip',
      linkText: 'Start',
      link: '#'
    },
    {
      id: 'bar_6',
      icon: require('../assets/ql-icons/ql-icon-trivia.svg'),
      title: 'Trivia',
      shortDescription: 'Evening of fun, facts, and prizes',
      linkText: 'Play',
      link: '#'
    },
    {
      id: 'bar_7',
      icon: require('../assets/ql-icons/ql-icon-speeddating.svg'),
      title: 'Speed Dating',
      shortDescription: 'Ultimate spot to meet someone special',
      linkText: 'Tickets',
      link: '#'
    },
    {
      id: 'bar_8',
      icon: require('../assets/ql-icons/ql-icon-games.svg'),
      title: 'Game Night',
      shortDescription: 'Night of favorite drinking games',
      linkText: 'Open',
      link: '#'
    },
    {
      id: 'bar_9',
      icon: require('../assets/ql-icons/ql-icon-music.svg'),
      title: 'Live Events',
      shortDescription: 'See our upcoming great experiences',
      linkText: 'Tickets',
      link: '#'
    }
  ]
};

const makeExternalLink = link => {
  if (!link || link === '#') {
    return link;
  }
  for (const prot of ['//', 'http://', 'https://']) {
    if (link.startsWith(prot)) {
      return link;
    }
  }
  return 'https://' + link;
};

const getPresetByType = type => type.replace('QuickLink', '').toLowerCase();
const allPreset = Object.keys(quickLinkPresets).reduce(
  (acc, presetKey) => acc.concat(quickLinkPresets[presetKey]),
  []
);

const showLoading = event => {
  //const anchor = event.target;
  const spinners = document.getElementsByClassName('spinner');
  if (!spinners.length) {
    return;
  }
  spinners[0].style.display = 'block';
  setTimeout(() => {
    spinners[0].style.display = 'none';
  }, 3000);
};

const saveFormAnswers = async (response, existing) => {
  if (existing) {
    await db
      .collection('answers')
      .doc(existing)
      .update(response);
    return { id: existing };
  }
  return await db.collection('answers').add(response);
};

const downloadAnswer = async formId => {
  const records = await db
    .collection('answers')
    .where('event', '==', formId)
    .get();
  return records.docs.map(record => ({
    id: record.id,
    ...record.data()
  }));
};

export {
  toUTC,
  search,
  toLocal,
  validate,
  setTimes,
  sendToApp,
  allPreset,
  shareEvent,
  toOriginal,
  categories,
  eventTypes,
  showLoading,
  processFile,
  isWithInTime,
  emailMethods,
  shareLocation,
  recurringTypes,
  loadCategories,
  downloadAnswer,
  getPresetByType,
  getInitialState,
  matchCategories,
  saveFormAnswers,
  uploadEventImage,
  quickLinkPresets,
  makeExternalLink,
  uploadLocationImage
};
