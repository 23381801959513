import React from 'react';
import { Container, Table } from 'reactstrap';

const Result = ({ result, onClose }) => {
  return (
    <Container>
      <div className="closeBtnTrivia">
        <ion-icon name="close" onClick={onClose} />
      </div>
      <header className="pt-4 pb-2">
        <h3>Match result</h3>
        <p className="text-muted">
          {result.length ? 'Here is your match result.' : 'No match found.'}
        </p>
      </header>
      {!!result.length && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-right">Match</th>
            </tr>
          </thead>
          <tbody>
            {result.map(match => (
              <tr key={match.uid}>
                <td>{match.name}</td>
                <td className="text-right">{match.score}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default Result;
