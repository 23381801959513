import React from 'react';

import { getView } from '../extras/LocationInfo';

const PreviewCard = ({ type, location, event }) => {
  const previewables = [
    'Sticky',
    'Poll',
    'QuickLink',
    'Video',
    'Ad',
    'StickyCard'
  ];
  if (!previewables.includes(type)) {
    return null;
  }
  const View = getView(event);
  return (
    <div style={{ position: 'relative' }}>
      <label>Card Preview</label>
      <View eventLocation={location} event={event} />
      <div
        onClick={e => e.stopPropagation()}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
      />
    </div>
  );
};

export default PreviewCard;
