import React from 'react';
import { withRouter } from 'react-router-dom';

const NavBar = ({ children, title, history }) => (
  <nav className="navbar navbar-light bg-light">
    <span onClick={history.goBack}>&lt; Back </span>
    <div className="ml-auto mr-auto">{children}</div>
    <span className="navbar-text text-uppercase">
      {title && title.toUpperCase()}
    </span>
  </nav>
);

export default withRouter(NavBar);
