import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'reactstrap';
/**
 * Once done, we will call the calculate result function
 * For every complete match, we will need to calculate the users result
 */

class Play extends Component {
  constructor(props) {
    super(props);
    const { answers } = props;
    this.state = {
      answer: '',
      index: Math.max(0, answers.length - 1)
    };
  }

  handlePrev = () => {
    document.querySelector('#form').reset();
    this.setState(state => ({ index: state.index - 1 }));
  };

  handleNext = event => {
    if (event) {
      event.preventDefault();
    }
    const { index, answer } = this.state;
    const { onAnswer } = this.props;
    onAnswer(index, answer);
    document.querySelector('#form').reset();
    this.setState({ index: index + 1, answer: '' });
  };

  render() {
    const { questions, answers, onDone } = this.props;
    const { index } = this.state;
    const test = questions[index];
    let answer = this.state.answer;
    if (index < answers.length && answers[index]) {
      answer = answers[index];
    }
    if (index === questions.length) {
      return (
        <div className="m-4">
          <div className="closeBtnquestion mb-2">
            <ion-icon name="close" onClick={() => onDone(true)} />
          </div>
          <p>You have completed the match test.</p>
          <Button
            block
            color="primary"
            className="mt-2 mb-2"
            onClick={() => onDone(true)}
          >
            Done
          </Button>
        </div>
      );
    }
    return (
      <div className="m-4">
        <div className="closeBtnquestion">
          <ion-icon name="close" onClick={onDone} />
        </div>
        <p
          className="text-center m-4"
          style={{ color: 'gray', fontWeight: 'bold' }}
        >
          {index + 1}/{questions.length}
        </p>
        <h4 className="text-center">{unescape(test.question)}</h4>
        <Form id="form" className="m-2" onSubmit={this.handleNext}>
          <Row>
            <Col className="col" md={6} sm={12}>
              <Button
                block
                color="primary"
                className="mb-2"
                onClick={() => this.setState({ answer: 'yes' })}
                outline={answer !== 'yes'}
              >
                Yes
              </Button>
            </Col>
            <Col className="col" md={6} sm={12}>
              <Button
                block
                color="primary"
                className="mb-2"
                onClick={() => this.setState({ answer: 'no' })}
                outline={answer !== 'no'}
              >
                No
              </Button>
            </Col>
          </Row>
          <Button
            block
            outline
            color="primary"
            className="mb-2 mt-4"
            onClick={this.handleNext}
          >
            {index === questions.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Form>
      </div>
    );
  }
}

export default Play;
