const dispatchByType = (dispatch, actionTypes, docSnap) => {
  // eslint-disable-next-line default-case
  switch (docSnap.type) {
    case 'added':
      dispatch({
        type: actionTypes.Add,
        what: actionTypes.Success,
        data: {
          id: docSnap.doc.id,
          ...docSnap.doc.data()
        }
      });
      break;
    case 'modified':
      dispatch({
        type: actionTypes.Update,
        what: actionTypes.Success,
        data: {
          id: docSnap.doc.id,
          ...docSnap.doc.data()
        }
      });
      break;
    case 'removed':
      dispatch({
        type: actionTypes.Remove,
        what: actionTypes.Success,
        data: {
          id: docSnap.doc.id,
          ...docSnap.doc.data()
        }
      });
      break;
  }
};

export { dispatchByType };
