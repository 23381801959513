import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import logo from '../assets/images/loophq-icon.svg';

const routes = [
  { title: 'Location', to: '/locations' },
  { title: 'Subscription', to: '/subscriptions' },
  { title: 'Contact Us', to: '/contact', a: 'mailto:info@loophereandnow.com' },
  { title: 'Logout', to: '/logout' }
];

class HomeNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  render() {
    const { isExpanded } = this.state;
    return (
      <Navbar light className="bg-light">
        <span
          className={`navbar-toggler ${!!isExpanded ? 'collapsed' : ''}`}
          onClick={() => this.setState(state => ({ isExpanded: !isExpanded }))}
          aria-expanded={isExpanded}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </span>
        <NavbarBrand>
          <img
            className="d-inline-block align-top"
            src={logo}
            alt="loopHQ"
            width="30"
            height="30"
          />{' '}
          loopHQ
        </NavbarBrand>
        <div className={`navbar-collapse ${!isExpanded ? 'collapse' : ''}`}>
          <Nav className="navbar-nav">
            {routes.map((route, index) => (
              <NavItem key={route.to} className={`m-2 ${!index && 'mt-4'}`}>
                {route.a && <a href={route.a}>{route.title}</a>}
                {!route.a && <Link to={route.to}>{route.title}</Link>}
              </NavItem>
            ))}
          </Nav>
        </div>
      </Navbar>
    );
  }
}

export default HomeNav;
