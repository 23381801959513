import * as auth from './auth';
import * as firebase from './firebase';

const Collections = {
  Users: 'users',
  Customers: 'customers',
  Locations: 'locations',
  Reviews: 'reviews',
  Events: 'events',
  Votes: 'votes',
  Claims: 'claims'
};

export { auth, firebase, Collections };
