import React from 'react';
import { connect } from 'react-redux';

import Home from './Home';
import { Locations } from '../components';
import { LocationActions } from '../actions';
import locationAction from '../actions/Location';
import { processFile, uploadLocationImage } from '../events/Helpers';

const remove = (location, removeLocation) => {
  if (window.confirm(`Are you sure you want to remove "${location.name}"`)) {
    removeLocation(location);
  }
};

const LocationPage = ({ locations, history, removeLocation, props }) => {
  return (
    <Home history={history} {...props}>
      <Locations
        locations={locations}
        processFile={processFile}
        uploadLocationImage={uploadLocationImage}
        onEdit={locationId => {
          history.push({
            pathname: '/edit',
            state: {
              email: localStorage.getItem('authUser'),
              locationId: locationId
            }
          });
        }}
        onRemove={location => remove(location, removeLocation)}
      />
    </Home>
  );
};

export default connect(
  ({ locations }) => ({ locations }),
  dispatch => ({
    removeLocation: location =>
      dispatch(locationAction(LocationActions.Remove, location))
  })
)(LocationPage);
