import React from 'react';
import { Input } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import Select, { components } from 'react-select';
import Switch from 'react-switch';

import { processFile } from '../Helpers';

const { Option } = components;
const icons = {
  none: require('../../assets/card-icons/card-icon-01.svg'),
  top: require('../../assets/card-icons/card-icon-02.svg'),
  small: require('../../assets/card-icons/card-icon-03.svg'),
  large: require('../../assets/card-icons/card-icon-04.svg'),
  largeTop: require('../../assets/card-icons/card-icon-05.svg')
  // large2: require('../../assets/card-icons/card-icon-04.svg'),
  // large2Top: require('../../assets/card-icons/card-icon-05.svg'),
};

const iconOption = props => (
  <Option {...props}>
    <img
      alt={props.data.label}
      style={{ width: 40, height: 40 }}
      src={icons[props.value]}
    />
    &nbsp;
    {props.data.label}
  </Option>
);
const options = [
  { label: 'Text only card', value: 'none' },
  { label: 'Basic card', value: 'top' },
  { label: 'Image card - small', value: 'small' },
  { label: 'Image card - Text bottom', value: 'large' },
  { label: 'Image card - Text top', value: 'largeTop' }
  // { label: 'Image card 2 - Text bottom', value: 'large2' },
  // { label: 'Image card 2 - Text top', value: 'large2Top' }
  //{ label: 'Light Background Image', value: 'smallLight' },
  //{ label: 'Light Background Image Large', value: 'largeLight' }
];

class Sticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(image) {
    const { pictures } = this.imagePicker.state;
    const {
      methods: { onInput }
    } = this.props;

    if (image.length > 1) {
      image.shift();
    }

    if (pictures.length > 1) {
      this.imagePicker.setState({
        pictures: [pictures.pop()]
      });
    }

    if (image && image.length) {
      processFile(image[0], data => {
          onInput({
            nativeEvent: {
              preventDefault: () => false,
              target: {
                id: 'image',
                value: {
                  data,
                  // type
                }
              }
            }
          });
      //   });
      });
    } else {
      onInput({
        nativeEvent: {
          preventDefault: () => false,
          target: {
            id: 'image',
            value: -1
          }
        }
      });
    }
  }

  render() {
    const {
      methods: { onInput },
      sticky: { externalLink, externalText, imageType, image, canShare }
    } = this.props;
    let defaultImage = null;
    if (typeof image === 'string' && image.startsWith('http')) {
      defaultImage = image;
    }
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          className="mb-4"
        >
          <span style={{ flex: 1 }}>Enable sharing for this event</span>
          <Switch
            id="canShare"
            onChange={checked =>
              onInput({
                nativeEvent: {
                  target: {
                    id: 'canShare',
                    value: checked
                  }
                }
              })
            }
            checked={!!canShare}
            height={20}
          />
        </div>
        <label>Button text</label>
        <Input
          type="text"
          id="externalText"
          className="form-control mb-3"
          placeholder="e.g. More info"
          value={externalText}
          onChange={onInput}
        />
        <label>Button link</label>
        <Input
          type="text"
          id="externalLink"
          className="form-control mb-3"
          placeholder="Enter URL"
          value={externalLink}
          onChange={onInput}
        />
        <label>Card Style</label>
        <Select
          onChange={option => {
            onInput({
              nativeEvent: {
                target: {
                  id: 'imageType',
                  value: option.value
                }
              }
            });
          }}
          className="mb-3"
          id="imageType"
          value={options.find(option => option.value === imageType)}
          options={options}
          components={{ Option: iconOption }}
        />
        {imageType && imageType !== 'none' && (
          <ImageUploader
            singleImage={true}
            defaultImage={defaultImage}
            ref={imagePicker => (this.imagePicker = imagePicker)}
            buttonText="Choose image"
            onChange={this.onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.svg', '.jpeg']}
            maxFileSize={10242880}
            label="Max file size: 10mb, accepted: jpg | gif | png | svg"
            withIcon={false}
          />
        )}
        
      </>
    );
  }
}

export default Sticky;
