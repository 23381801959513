/**
 * Card to show in the location details page
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardBody, Alert } from 'reactstrap';
import moment from 'moment';

import { toLocal } from './Helpers';

const LocationCard = ({ event, history, showMessage, eventLocation }) => {
  const momentTime = toLocal(event.utcStart);
  const isComing =
    momentTime.isAfter(moment()) && momentTime.diff(moment(), 'days') === 0;
  return (
    <Card className="mb-4">
      <CardHeader>
        <h4 className="my-0 font-weight-normal text-center">{event.title}</h4>
      </CardHeader>
      <CardBody>
        <p className="text-center">{event.description}</p>
        {isComing && !showMessage && (
          <div className="mb-2 text-center font-weight-normal">
            Voting starts at <strong>{momentTime.format('hh:mm A')}</strong>
          </div>
        )}
        {showMessage && (
          <Alert color="info">
            <span>
              You must be at {eventLocation.name} to
              <strong> participate</strong>
            </span>
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

export default withRouter(LocationCard);
