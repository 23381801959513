import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Input } from 'reactstrap';

import { Button, withMessage } from '../components';
import logo from './../assets/images/loophq-icon.svg';
import { auth } from '../../firebase';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: null
    };
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { email } = this.state;
    const { history, showError, showWarning, showSuccess } = this.props;
    if (!email || !email.trim().length) {
      showWarning('Invalid email.', 'Please re-check your email.');
      return;
    }
    this.setState({ isLoading: true });
    try {
      await auth.doPasswordReset(email);
      showSuccess(
        'Link sent!',
        'A link to reset the password has been mailed to you.'
      );
      history.push('/');
    } catch (exp) {
      showError('Error occurred', exp.message);
      this.setState({ isLoading: false });
    }
  };

  handleInput = event => {
    this.setState({
      email: event.target.value
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Container className="text-center">
        <div className="pb-2  pt-5  text-center">
          <img
            className="d-block mx-auto mt-2 mb-4"
            src={logo}
            alt=""
            width="72"
            height="72"
          />
          
          
          <h3>Forgot password</h3>
          <p className="lead">
            Enter your email address to receive a password rest link
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col col-sm-8 col-md-6 col-lg-4">
            <Form
              onSubmit={this.handleSubmit}
              className="form-login"
              autoComplete="off"
            >
              <Input
                type="email"
                id="email"
                bsSize="lg"
                className="form-control mb-3"
                placeholder="Email address"
                required
                autoFocus
                onChange={this.handleInput}
              />

              <Button
                isBusy={isLoading}
                color="primary"
                block
                type="submit"
              >
                Reset password
              </Button>
              <Link to="/" className="btn btn-light btn-block mt-4">
                Back
              </Link>

              <p className="mt-5 mb-3 text-muted">&copy; loopHQ</p>
            </Form>
          </div>
        </div>
      </Container>
    );
  }
}

export default withMessage(ForgotPassword);
