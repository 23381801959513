import React from 'react';
import {
  Alert,
  Container,
  Form,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';
import moment from 'moment';

import { PageLoading, Button, withMessage } from '../components';
import StarView from './components/Star';
import Review from './components/Review';
import ReviewService from './services/Review';
import {
  eventTypes,
  toLocal,
  shareEvent,
  shareLocation,
  makeExternalLink
} from '../events/Helpers';
import LocationCard from '../events/LocationCard';
import PollCard from '../events/categories/PollCard';
import TriviaCard from '../events/categories/TriviaCard';
import placeIcon from '../assets/images/loophq-place-icon.svg';
import shareIcon from '../assets/images/share-icon.svg';
import { excludeDates, isQuickLink } from '../events/Create';
import NavBar from '../events/NavBar';

export const getView = event => {
  const eventType = eventTypes.find(eve => eve.value === event.type);
  if (eventType && eventType.Card) {
    return eventType.Card;
  }
  return LocationCard;
};

class LocationInfo extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = props;
    this.state = {
      isOpen: false,
      isSaving: false,
      match: params,
      error: null,
      location: null,
      distance: -1,
      reviews: [],
      isLoading: true,
      isFetchingReview: false,
      isEdit: null,
      willRenderNav: false,
      events: {
        today: [],
        ended: [],
        ad: [],
        poll: [],
        video: [],
        sticky: [],
        upcoming: [],
        quickLink: [],
        forms: [],
        matchtest: []
      },
      review: {
        name: '',
        description: '',
        stars: 5
      }
    };
  }

  async componentDidMount() {
    const { match } = this.state;
    if (!match.locationId) {
      // handle error for this case
      this.setState({
        error: 'Location was not specified.',
        isLoading: false
      });
      return;
    }
    this.fetchLocationInfo(match);
  }

  fetchLocationInfo = async match => {
    try {
      const { locationId, lat, lng, uid } = match;
      const location = await ReviewService.fetchLocation(locationId);
      let distance = ReviewService.calculateDistance(location, lat, lng);
      const userEmail = localStorage.getItem('authUser');
      if (distance === false && userEmail === uid) {
        distance = 0;
        this.setState({
          willRenderNav: true
        });
      }

      this.fetchLocationReviews(locationId, uid);
      if (location.showWebPage === 'No') {
        document.location.href = makeExternalLink(location.redirectUrl);
        return;
      }
      this.setState({
        location,
        distance,
        isLoading: false
      });
    } catch (exp) {
      this.setState({
        isLoading: false,
        error: exp.message
      });
    }
  };

  fetchLocationReviews = async (locationId, uid) => {
    this.setState({
      error: null,
      isFetchingReview: true
    });
    try {
      const reviews = await ReviewService.fetchReviews(locationId);
      const events = await ReviewService.fetchEvents(locationId);
      const review = reviews.find(review => review.uid === uid); // old review of the user
      const eventsList = {
        today: [],
        upcoming: [],
        ended: [],
        sticky: [],
        video: [],
        ad: [],
        poll: [],
        quickLink: [],
        forms: [],
        matchtest: []
      };
      let date,
        todayDate = moment();
      for (const event of events) {
        date = toLocal(event.utcStart);
        if (isQuickLink(event.type) && !event.isArchived) {
          eventsList.quickLink.push(event);
          continue;
        }
        if (excludeDates.includes(event.type)) {
          if (!event.isArchived) {
            eventsList[event.type.toLowerCase()].push(event);
          }
          continue;
        }
        if (date.isSame(todayDate, 'D')) {
          if (
            moment().isAfter(toLocal(event.utcEnd)) &&
            !['MatchTest', 'Trivia'].includes(event.type)
          ) {
            eventsList.ended.push(event);
          } else {
            eventsList.today.push(event);
          }
        } else if (date.isAfter(todayDate)) {
          eventsList.upcoming.push(event);
        }
      }
      // eventsList.sticky = eventsList.sticky.sort(
      //   (a, b) => moment(a.utcStart).unix() - moment(b.utcStart).unix()
      // );
      this.setState(state => ({
        isFetchingReview: false,
        isEdit: !!review,
        review: review || state.review,
        events: eventsList,
        reviews
      }));
    } catch (exp) {
      this.setState({
        isFetchingReview: false,
        error: exp.message
      });
    }
  };

  handleFormSubmit = async event => {
    event.preventDefault();
    const { review, isEdit, reviews, location } = this.state;
    const {
      showError,
      showSuccess,
      showWarning,
      match: {
        params: { locationId, uid }
      }
    } = this.props;
    if (!review.name || !review.name.trim().length) {
      showWarning('Name missing', 'Please fill your name.');
      return;
    }
    if (!review.description || !review.description.trim().length) {
      showWarning('Description missing', 'Please fill the review description.');
      return;
    }
    try {
      /**
       * Since this is a standalone page, we are not connecting it with redux
       */
      this.setState({ isSaving: true });
      review.uid = uid;
      review.locationId = locationId;
      if (isEdit) {
        const { id, ...updates } = review;
        await ReviewService.updateReview(id, updates);
        showSuccess('Review updated', 'Your review has been saved.');
      } else {
        const savedReview = await ReviewService.addReview(review);
        reviews.unshift(savedReview);
        location.stars =
          location.stars + review.stars / location.starsCount + 1;
        showSuccess('Review added', 'Your review has been saved.');
      }
      location.stars =
        reviews.reduce((stars, rvw) => stars + rvw.stars, 0) / reviews.length;
      location.starsCount = reviews.length;
      this.setState(state => ({
        isSaving: false,
        isOpen: false,
        isEdit: true, // after saving we can edit it
        reviews
      }));
    } catch (exp) {
      this.setState({ isSaving: false });
      showError('Oopps', "Your review couldn't be been saved.");
    }
  };

  handleInput = event => {
    const { review } = this.state;
    review[event.target.name] = event.target.value;
    this.setState({ review });
  };

  renderEnded = events => {
    const { location } = this.state;
    return (
      <>
        {events.map(event => {
          if (event.type === 'Poll') {
            return (
              <PollCard
                key={event.id}
                showMessage={false}
                event={event}
                eventLocation={location}
              />
            );
          }
          if (event.type === 'Trivia') {
            return (
              <TriviaCard
                key={event.id}
                showMessage={false}
                event={event}
                hasEnded
                eventLocation={location}
              />
            );
          }
          if (event.type.includes('QuickLink')) {
            return null;
          }
          return (
            <Card key={event.id} className="ended mb-4 mt-4">
              <CardHeader className="text-center">
                <strong>{event.title}</strong>
              </CardHeader>
              <CardBody>
                <p className="m-0">{event.description}</p>
                <strong className="text-center d-block">Ended</strong>
              </CardBody>
            </Card>
          );
        })}
      </>
    );
  };

  renderUpcoming = upcoming => {
    if (!upcoming.length) {
      return null;
    }
    const { location } = this.state;
    return (
      <Card className="mb-5">
        <CardHeader className="text-center">
          <strong>Upcoming events</strong>
        </CardHeader>
        <ListGroup flush>
          {upcoming.map(event => (
            <ListGroupItem key={event.id}>
              <Button
                onClick={() => shareEvent(event, location, 'upcoming')}
                className="btn-sm float-right shareBtn"
              >
                <span className="shareBtnIcon">
                  <img alt="Share event" className="img-flex" src={shareIcon} />
                </span>
              </Button>
              <strong>{event.title}</strong>
              <p className="m-0">{event.description}</p>
              <strong>Starts: </strong>{' '}
              {toLocal(event.utcStart).format('MMM DD, hh:mm A')}
            </ListGroupItem>
          ))}
        </ListGroup>
      </Card>
    );
  };

  handleClaim = async event => {
    event.preventDefault();
    const { review, location } = this.state;
    const { showError, showSuccess, showWarning } = this.props;
    const { claimerName, claimerPhone, claimerEmail } = review;
    if (!claimerName || !claimerName.length) {
      showWarning('Field missing', 'Please enter your full name.');
      return;
    }
    if (!claimerPhone || !claimerPhone.length) {
      showWarning('Field missing', 'Please enter your phone number.');
      return;
    }
    if (!claimerEmail || !claimerEmail.length) {
      showWarning('Field missing', 'Please enter your email address.');
      return;
    }
    if (!claimerEmail.includes('.') || !claimerEmail.includes('@')) {
      showWarning('Invalid email', 'Please make sure your email is valid.');
      return;
    }
    try {
      this.setState({ isClaiming: true });
      await ReviewService.saveClaimMail({
        claimerName,
        claimerEmail,
        claimerPhone,
        location
      });
      showSuccess(
        'Claiming success',
        'Your claim has been saved successfully.'
      );
      this.setState({ claimSent: true });
    } catch (exp) {
      showError('Claiming failed', exp.message);
    } finally {
      this.setState({ isClaiming: false });
    }
  };

  renderClaimForm = () => {
    const { location, claimSent, isClaimOpen, isClaiming } = this.state;
    if (!location || !location.isOrphan || claimSent) {
      return null;
    }
    return (
      <Row className="justify-content-center">
        <Col sm={8} md={6} lg={4} className=" pt-3 pb-3 claimRow">
          {isClaimOpen && (
            <div className="border-top p-3 bg-light">
              <p>
                1. Built-in mini-web
                <br />
                2. Starter layout
                <br />
                3. Customizable content modules
                <br />
                4. Event management
                <br />
                5. Monetization platform
                <br />
                <a href="https://loophereandnow.com/loophq">
                  Read more about loopHQ
                </a>
                .<br />
                <br />
                To claim this location, enter your name, phone number and email
                address to receive further instructions.
              </p>
              <Form className="form-claim" onSubmit={this.handleClaim}>
                <Input
                  name="claimerName"
                  className="mb-3"
                  placeholder="Enter your name"
                  required
                  autoFocus
                  onChange={this.handleInput}
                />
                <Input
                  name="claimerPhone"
                  className="mb-3"
                  placeholder="Enter your phone number"
                  required
                  onChange={this.handleInput}
                />
                <Input
                  name="claimerEmail"
                  className="mb-3"
                  placeholder="Enter your email"
                  required
                  type="email"
                  onChange={this.handleInput}
                />
                <Button isBusy={isClaiming} color="primary" block type="submit">
                  Claim now
                </Button>
              </Form>
            </div>
          )}
          <Button
            block
            color={isClaimOpen ? 'secondary' : 'primary'}
            onClick={() =>
              this.setState(state => ({ isClaimOpen: !state.isClaimOpen }))
            }
          >
            {isClaimOpen ? 'Cancel' : 'Claim this location'}
          </Button>
        </Col>
      </Row>
    );
  };

  renderNav = () => {
    const title = 'Location Preview';
    const { willRenderNav } = this.state;
    if (willRenderNav) {
      return <NavBar title={title} />;
    }
    return null;
  };

  render() {
    const {
      isLoading,
      error,
      location,
      match,
      review,
      isOpen,
      isSaving,
      reviews,
      isEdit,
      isFetchingReview,
      distance,
      events: {
        today,
        upcoming,
        ended,
        sticky,
        video,
        ad,
        quickLink,
        poll,
        forms,
        matchtest
      }
    } = this.state;
    if (isLoading) {
      return <PageLoading description="Pulling the information you need ;)." />;
    }
    const isUserLocal =
      distance !== false &&
      match.view &&
      ['here', 'tags'].includes(match.view.toLowerCase());
    let image = require('../assets/images/location-header-img2.png');
    if (location && location.image) {
      image = location.image;
    }

    return (
      <>
        {this.renderNav()}
        <Container className="mainContainer">
          {this.renderClaimForm()}

          <div className="row no-gutter justify-content-center">
            <div className="col col-sm-8 col-md-6 col-lg-4 p-0">
              <div
                className="headerImg p-0"
                style={{
                  position: 'relative'
                  // backgroundImage: `url(${image})`
                }}
              >
                {location && (
                  <Button
                    onClick={() => shareLocation(location)}
                    className="btn-sm mt-3"
                    style={{
                      border: '0 none',
                      background: 'transparent',
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      zIndex: 999
                    }}
                  >
                    <span className="shareBtnIcon">
                      <img
                        alt="Share location"
                        className="img-flex"
                        src={shareIcon}
                      />
                    </span>
                  </Button>
                )}
                <img
                  alt={location.name}
                  className="locationImage img-fluid m-0"
                  src={image}
                />
              </div>
            </div>
          </div>

          <Row className="pb-2 justify-content-center text-center">
            <Col sm={8} md={6} lg={4}>
              {error && (
                <>
                  <img
                    className="d-block mx-auto mt-2 mb-2"
                    src={placeIcon}
                    alt=""
                    width="72"
                    height="72"
                  />
                  <h1 className="mb-5 pb-2">loopHQ</h1>
                  <Alert color="warning">
                    <span>{error}</span>
                  </Alert>
                </>
              )}
              {location && (
                <>
                  <Row className="text-left mt-4">
                    <div className="col-auto">
                      <img
                        className="d-block mx-auto mt-2 mb-2"
                        src={placeIcon}
                        alt=""
                        width="50"
                        height="50"
                      />
                    </div>
                    <Col style={{ paddingLeft: 0, paddingTop: 8 }}>
                      <h4 className="mb-0">{location.name}</h4>
                      <p>
                        <small>
                          {['address', 'city', 'state']
                            .map(key => location[key])
                            .join(', ')}{' '}
                          {location.zip}
                        </small>
                      </p>
                    </Col>
                  </Row>
                  <div className="mb-3">
                    <StarView
                      large
                      onStar={() => false /* ignore */}
                      stars={location.stars}
                    />
                    <p>{location.description}</p>
                  </div>
                </>
              )}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} md={6} lg={4}>
              {isFetchingReview && (
                <Card style={{ opacity: 0.5 }} className="mb-4">
                  <CardHeader>&nbsp;</CardHeader>
                  <CardBody>
                    <p className="m-5"> &nbsp; </p>
                  </CardBody>
                </Card>
              )}
              {[
                ...sticky,
                ...video,
                ...ad,
                ...today,
                ...quickLink,
                ...poll,
                ...forms,
                ...matchtest
              ]
                .sort((eve, nt) => eve.order - nt.order)
                .map(event => {
                  const View = getView(event);
                  return (
                    <View
                      showMessage={!isUserLocal}
                      eventLocation={location}
                      key={event.id}
                      event={event}
                      match={match}
                    />
                  );
                })}
              {this.renderEnded(ended)}
              {this.renderUpcoming(upcoming)}
              {location && !isUserLocal && (
                <Alert color="info">
                  <span>
                    You must be at {location.name} to{' '}
                    <strong>add review</strong>.
                  </span>
                </Alert>
              )}
            </Col>
          </Row>

          {location && isUserLocal && (
            <Row className="justify-content-center">
              <Col sm={8} md={6} lg={4}>
                <div className={isOpen ? '' : 'collapse'}>
                  <Form onSubmit={this.handleFormSubmit}>
                    <div className="mb-3">
                      <span className="text-muted">Rating</span>
                      <StarView
                        large
                        onStar={stars =>
                          this.handleInput({
                            target: {
                              name: 'stars',
                              value: parseInt(stars)
                            }
                          })
                        }
                        stars={review.stars}
                      />
                    </div>
                    <Input
                      type="text"
                      name="name"
                      className="form-control mb-3"
                      placeholder="Enter your name"
                      required
                      autoFocus
                      value={review.name}
                      onChange={this.handleInput}
                    />
                    <textarea
                      name="description"
                      className="form-control mb-3"
                      rows="3"
                      placeholder="Enter review"
                      required
                      value={review.description}
                      onChange={this.handleInput}
                    />
                    <Button
                      block
                      isBusy={isSaving}
                      color="primary"
                      className="mb-3"
                      type="submit"
                    >
                      Submit review
                    </Button>
                  </Form>
                </div>
                <Button
                  block
                  color={isOpen ? 'secondary' : 'primary'}
                  onClick={() =>
                    this.setState(state => ({ isOpen: !state.isOpen }))
                  }
                >
                  {isOpen
                    ? 'Cancel'
                    : isEdit
                    ? 'Update review'
                    : 'Add a review'}
                </Button>
              </Col>
            </Row>
          )}
          <Row className="justify-content-center mt-3">
            <Col sm={8} md={6} lg={4}>
              <>
                {reviews.map(review => (
                  <Review key={review.id} review={review} />
                ))}
                {isFetchingReview && (
                  <Alert color="info">
                    <span>Retrieving users reviews</span>
                  </Alert>
                )}
                {!isFetchingReview && !reviews.length && (
                  <Alert color="warning">
                    <span>No reviews yet.</span>
                  </Alert>
                )}
              </>
              <p className="mt-5 mb-3 text-muted text-center">© loopHQ</p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withMessage(LocationInfo);
