import React from 'react';
import { connect } from 'react-redux';
import { setHideBanner } from '../actions/NewUser';



const mapStateToProps = state => {
	return {
		isNewUser: state.newUser.isNewUser,
		showBanner: state.newUser.showBanner,
		locations: state.locations.data.length,
		events: state.events.data.length
	}
}


const displayMessage = (message, order) => {
	return (
		<div>
		{order !== 5 && <div>
			<span className="navbar-text text-uppercase"><strong>STEP <span>{order}</span> OF 4</strong></span>
		</div>
		}
		<span className="text-center">{message}</span>
		</div>
	);
}

const getMessage = (order,  locations, events, isNewUser) => {
	let message;
	if (order === 1) {
		message = 'Create loopHQ account';
		return displayMessage(message, order);
	} else if (order === 2 && isNewUser) {
		message = 'Select Subscription Plan';		
		return displayMessage(message, order);
	} else if (order === 3 && isNewUser) {
		message = 'Set up your payment';
		return displayMessage(message, order);
	} else if (order === 4 && isNewUser && locations === 0) {
		message = 'Enter location address';
		return displayMessage(message, order);
	} else if (order === 5 && isNewUser && locations === 1 && events === 0) {
		message = 'Congratulations! Your location page is created. Tell your users to download loopHQ app.';
		return displayMessage(message, order);
	// } else if (isNewUser && locations === 1 && events === 1) {
	// 	order = order +1;
	// 	message = 'Congratulations! Your location page is created. Tell your users to download loopHQ app.';
	// 	return displayMessage(message, order);
	}
}

const OnBoardBanner = ({ order, isNewUser, locations, events, showBanner, hideBanner }) => {
	if (showBanner) {
		return (
			<>
			{isNewUser &&
				<div className="alert banner mb-0">
					<div className="banner-content text-center navbar-text">
						{getMessage(order, locations, events, isNewUser)}
					</div>
					<div className="banner-close" onClick={hideBanner} >X</div>
				</div>
			}
			</>
		)
	} else {
		return null;
	}
}

export default connect(mapStateToProps,
		dispatch => ({
			hideBanner: () => {
				dispatch(setHideBanner(false))
			}
		})
	)(OnBoardBanner);
