import ReducerFactory from './ReducerFactory';
import {
  LocationActions,
  CustomerActions,
  ReviewActions,
  EventsAction
} from '../actions';
import { Collections } from '../../firebase';
import messageReducer from './Message';
import newUserReducer from './NewUser';
import { setTimes } from '../events/Helpers';

const make = ReducerFactory.makeReducer;

export default {
  [Collections.Customers]: make(CustomerActions),
  [Collections.Locations]: make(LocationActions),
  [Collections.Reviews]: make(ReviewActions),
  [Collections.Events]: make(EventsAction, [], {
    add: (all, event) => {
      if (!all.find(e => e.id === event.id)) {
        all.push(setTimes(event));
      }
      return all;
    },
    get: events => events.map(setTimes)
  }),
  message: messageReducer,
  newUser: newUserReducer
};
