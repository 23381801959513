import React from 'react';
import {
  Alert,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ImageUploader from 'react-images-upload';

import { Button } from '.';
import { isAllowed } from './Subscriptions';
import EventList from '../events/List';
import placeIcon from '../assets/images/loophq-place-icon.svg';
import { db } from '../../firebase/firebase';
import withMessage from './WithMessage';

class LocationHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      isModalOpen: false
    };
    this.onDrop = this.onDrop.bind(this);
    this.handleImageRemove = this.handleImageRemove.bind(this);
  }

  onToggle = () =>
    this.setState(state => ({
      isModalOpen: !state.isModalOpen
    }));

  onDrop(image) {
    const { processFile, uploadLocationImage, showError } = this.props;
    const { pictures } = this.imagePicker.state;
    if (pictures.length > 0) {
      this.imagePicker.setState({
        pictures: [pictures.pop()]
      });
    }
    if (image && image.length) {
      processFile(image[0], data => {
        if (!data) {
          return;
        }
        this.setState({
          image: data
        });
        uploadLocationImage(
          {
            type: 'image/jpeg',
            data
          },
          true
        ).then(url => {
          if (!url) {
            showError('Oops! upload failed', 'The image upload failed.');
          } else {
            this.updateImage(url);
          }
        });
      });
    } else {
      this.setState({ image: null });
    }
  }

  updateImage = url => {
    const { location } = this.props;
    location.image = url;
    db.collection('locations')
      .doc(location.id)
      .update({ image: url });
  };

  handleImageRemove() {
    this.setState({ image: null });
    this.updateImage('');
    this.onToggle();
  }

  render() {
    const { location, onEdit, onView, onRemove } = this.props;
    const { image, isModalOpen } = this.state;
    const defImage = image || location.image;
    return (
      <>
        {defImage && location.showWebPage !== 'No' && (
          <div
            style={{ marginLeft: -15, marginRight: -15, textAlign: 'center' }}
          >
            <div className="closeIcon">
              <ion-icon
                onClick={this.onToggle}
                name="close"
                style={{
                  color: 'white',
                  borderRadius: '50%',
                  padding: 4,
                  // position: 'relative',
                  // zIndex: 999,
                  // top: 8,
                  // right: 8,
                  textAlign: 'center',
                  // display: 'inline-block',
                  backgroundColor: '#763AAE'
                }}
              />
            </div>
            <img
              alt={location.name}
              className="locationImage img-fluid mx-0 mt-0 mb-3"
              src={defImage}
            />
          </div>
        )}
        {!defImage && location.showWebPage !== 'No' && (
          <div
            className="col-md-12 col-lg-8, pt-2 pb-2"
            style={{ marginRight: 'auto', padding: 0, marginLeft: 'auto' }}
          >
            <ImageUploader
              singleImage
              withPreview={!!image}
              withIcon={!image}
              defaultImage={defImage}
              ref={imagePicker => (this.imagePicker = imagePicker)}
              buttonText="Upload location image or logo"
              onChange={this.onDrop}
              imgExtension={['.jpg', '.gif', '.png', '.svg', '.jpeg']}
              maxFileSize={10242880}
              label="Max file size: 10mb, accepted: jpg | gif | png | svg. Landscape format recommended (16x9)"
            />
          </div>
        )}

        {location.showWebPage === 'No' && (
          <Alert color="info" className="text-center">
            <span>All your traffic is redirected to:</span>
            <a className="d-block mb-2" href={location.redirectUrl}>
              <strong>{location.redirectUrl}</strong>
            </a>
            <span>
              to create a mini-web page go to edit location info and select{' '}
              <strong>Yes</strong> on the "Create location mini-web page
              dropdown"
            </span>
          </Alert>
        )}
        <Row>
          <Col className="col-auto">
            <img src={placeIcon} alt="logo" width="30" height="50" />
          </Col>
          <Col className="p-0">
            <h5 className="list-group-item-heading">
              {location.name}
              <br /> <small>{location.address}</small>
            </h5>
          </Col>
          <Col className="col-auto mb-3">
            <ion-icon
              style={{ fontSize: 28 }}
              name="create"
              aria-label="create"
              onClick={() => onEdit(location.id)}
            />
          </Col>
        </Row>
        {location.showWebPage !== 'No' && (
          <Button className="mb-3" onClick={onView} block color="primary">
            <ion-icon name="eye" aria-label="eye" />
            View location page
          </Button>
        )}
        {isAllowed() && (
          <Button
            className="mb-3"
            block
            color="secondary"
            onClick={() => onRemove(location)}
          >
            <ion-icon name="trash" aria-label="trash" />
            Delete location
          </Button>
        )}
        <Modal isOpen={isModalOpen}>
          <ModalHeader>Remove location image?</ModalHeader>
          <ModalBody>Are you sure you want to remove this image?</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.onToggle}>
              No
            </Button>
            <Button color="primary" onClick={this.handleImageRemove}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const Locations = ({
  uploadLocationImage,
  locations: { data },
  processFile,
  history,
  onEdit,
  onRemove,
  ...rest
}) => (
  <>
    {!data.length && (
      <Alert color="warning">
        <span>You haven't added any locations yet.</span>
      </Alert>
    )}
    {data.map(location => (
      <div key={location.id} className="mb-2 pb-2">
        <LocationHeader
          {...rest}
          location={location}
          onRemove={onRemove}
          onEdit={onEdit}
          processFile={processFile}
          uploadLocationImage={uploadLocationImage}
          onView={() => {
            const email = localStorage.getItem('authUser');
            const pathname = `/${location.id}/${email}/0/0/loopHQ`;
            history.push({
              state: {
                location
              },
              pathname
            });
          }}
        />
        {location.showWebPage !== 'No' && <EventList ofLocation={location} />}
        <hr />
      </div>
    ))}
    {isAllowed() && (
      <Button
        block
        outline
        className="mt-3"
        onClick={() => onEdit()}
        color="primary"
      >
        <ion-icon name="add" /> Add new
      </Button>
    )}
  </>
);

export default withRouter(withMessage(Locations));
