import { Collections } from '../../firebase';

export type BasicActionType = {
  Add: string,
  Remove: string,
  Update: string,
  Get: string,
  Fail: string,
  Clear: string,
  Reset: string,
  Start: string,
  Success: string
};

const makeAction = key => ({
  Add: `${key}_Add`,
  Remove: `${key}_Remove`,
  Update: `${key}_Update`,
  Get: `${key}_Get`,
  Start: `${key}_Start`,
  Success: `${key}_Success`,
  Fail: `${key}_Fail`,
  Clear: `${key}_Clear`,
  Reset: `${key}_Reset`
});

const LocationActions = makeAction(Collections.Locations);
const CustomerActions = makeAction(Collections.Customers);
const ReviewActions = makeAction(Collections.Reviews);
const EventsAction = makeAction(Collections.Events);

const MessageActions = {
  Show: 'Message_Show',
  Hide: 'Message_Hide'
};

export {
  LocationActions,
  CustomerActions,
  ReviewActions,
  MessageActions,
  EventsAction
};
