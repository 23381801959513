import React from 'react';
import {
  Alert,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from 'reactstrap';
import { auth } from '../../firebase/firebase';

import { Button } from '.';

export const isAllowed = () =>
  ['onwithhari@gmail.com', 'info@2mann.com', 'bars@2mann.com'].includes(
    auth.currentUser.email
  );

const Subscriptions = ({ customers: { data }, onAddClick, onRenewClick }) => {
  return (
    <>
      {/* {isAllowed() && (
        <Button
          className="mb-3"
          onClick={onAddClick}
          color="primary"
        >
          <ion-icon name="add"></ion-icon> Add new
        </Button>
      )} */}
      <ListGroup>
        {!data.length && (
          <Alert color="warning">
            <span>You haven't added any subscriptions yet.</span>
          </Alert>
        )}
        {data.map(subscription => (
          <ListGroupItem key={subscription.id}>
            <ListGroupItemHeading>
              {subscription.customerPlanNickname} Plan
            </ListGroupItemHeading>
            <ListGroupItemText className="mb-3">
              Email address: <strong>{subscription.customerEmail} </strong>
              <br />
              Subscription ID:{' '}
              <strong>{subscription.customerSubscriptionID}</strong>
            </ListGroupItemText>
            <ListGroupItemText>
            Subscription type:{' '}
              <strong>{subscription.customerPlanNickname} Plan</strong>
              <br />
              Status:{' '}
              <strong>{subscription.customerSubscriptionStatus} </strong> |
              Since:{' '}
              <strong>
                {new Date(
                  subscription.customerSubscriptionCreated * 1000
                ).toDateString()}{' '}
                &nbsp;{' '}
              </strong>
              {subscription.customerSubscriptionCanceled && (
                <Button
                  className="pull-right"
                  color="primary"
                  onClick={() => onRenewClick(subscription)}
                >
                  Renew
                </Button>
              )}
            </ListGroupItemText>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
};

export default Subscriptions;
