import React from 'react';
import { Table } from 'reactstrap';

const Leaderboard = ({ event, onClose, uid }) => {
  return (
    <div className="leaderboard mt-4 mb-4">
      <div className="closeBtnTrivia">
        <ion-icon name="close" onClick={onClose} />
      </div>
      <header className="pt-4 pb-2">
        <h3>{event.title}</h3>
        <h4>Leaderboard</h4>
      </header>
      <Table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {event.leaderboard &&
            event.leaderboard.map((user, rank) => (
              <tr
                key={'rank_' + rank}
                className={user.uid === uid ? 'self' : 'user'}
              >
                <td>{rank + 1}</td>
                <td>{user.name}</td>
                <td>{user.points}</td>
              </tr>
            ))}
          {!event.leaderboard && (
            <tr>
              <td colSpan={3}>
                Leaderboard is in progress. Please check again after a few
                minutes.
              </td>
            </tr>
          )}
          {event.leaderboard && !event.leaderboard.length && (
            <tr>
              <td colSpan={3}>Leaderboard is empty.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Leaderboard;
