import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroupItem } from 'reactstrap';

import withMessage from '../components/WithMessage';

const EventIcons = {
  Poll: 'list',
  Trivia: 'help-circle-outline',
  Promo: 'pricetag',
  Sticky: 'information-circle-outline',
  Video: 'videocam',
  Ad: 'browsers',
  QuickLink: 'link',
  QuickLinkBar: 'cafe',
  Forms: 'list-box',
  MatchTest: 'book'
};

class Event extends React.Component {
  state = {
    isDownloading: false
  };

  downloadCSV = async e => {
    e.preventDefault();
    const { isDownloading } = this.state;
    if (isDownloading) {
      return;
    }
    this.setState({ isDownloading: true });
    const { event, showError, showWarning } = this.props;
    try {
      const { downloadAnswer } = require('./Helpers');
      const records = await downloadAnswer(event.id);
      if (!records.length) {
        showWarning('No answers yet', 'Please wait until you have some data.');
        return;
      }
      const csv = [
        'uid,' + records[0].answers.map(answer => answer.name).join(',')
      ];
      records.forEach(record => {
        csv.push(
          record.uid +
            ',' +
            record.answers.map(answer => answer.value).join(',')
        );
      });
      const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.className = 'd-none';
      link.setAttribute('download', event.title + '.csv');
      document.body.appendChild(link);
      link.click();
    } catch (exp) {
      showError('Download failed!', exp.message);
    } finally {
      this.setState({ isDownloading: false });
    }
  };

  render() {
    const { event, showDrag } = this.props;
    const { isDownloading } = this.state;
    return (
      <ListGroupItem>
        <Link
          to={`/event/${event.id}/edit`}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ion-icon
            name={EventIcons[event.type]}
            role="img"
            aria-label="settings"
            style={{ paddingBottom: 0 }}
          />{' '}
          <span style={{ flex: 1 }}>{event.title || 'Quick Links'}</span>
          {showDrag && (
            <ion-icon
              name="menu"
              class="dragIcon"
              aria-label="Drag"
              style={{ cursor: 'move', paddingBottom: 0 }}
            />
          )}
          {!showDrag && event.type === 'Forms' && (
            <span
              onClick={this.downloadCSV}
              className={isDownloading ? 'text-muted' : 'downloadIcon'}
            >
              <ion-icon
                name="download"
                aria-label="Download"
                style={{ cursor: 'pointer', paddingBottom: 0 }}
              />
              {isDownloading ? 'Downloading' : 'Data'}
            </span>
          )}
        </Link>
      </ListGroupItem>
    );
  }
}

export default withMessage(Event);
