import React from 'react';

import { customMatchQuestions, matchCategories } from '../Helpers';
import Uploader from '../Uploader';

const isValidCSV = questions => {
  if (!Array.isArray(questions) || !questions.length) {
    return false;
  }
  const picks = Math.min(questions.length, 5);
  const used = [];
  /**
   * Randomly checking few questions to make sure it has correct form
   */
  while (used.length < picks) {
    const pick = Math.round(Math.random() * picks);
    if (!used.includes(pick)) {
      used.push(pick);
    }
    const picked = questions[pick];
    if (!picked) {
      continue;
    }
    if (!picked.question || !picked.category) {
      return false;
    }
  }
  return true;
};

const UploadTrivia = props => {
  return (
    <Uploader
      categories={matchCategories}
      customQuestions={customMatchQuestions}
      isValidCSV={isValidCSV}
      info={{
        table: 'match',
        cols: 'question, answer, category',
        btn: 'Upload questions'
      }}
      {...props}
    />
  );
};

export default UploadTrivia;
