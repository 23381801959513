import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { PageLoading, withMessage, Footer, Button } from '../components';
import { EventsAction } from '../actions';
import { toLocal } from './Helpers';
import eventAction from '../actions/Event';
import HomeNav from '../components/HomeNav';

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      history,
      match: { params }
    } = props;
    let eventsData = null;
    const {
      location: { state }
    } = history;
    if (state && state.event) {
      eventsData = [state.event];
    }
    this.state = {
      eventsData,
      userEmail: localStorage.getItem('authUser')
    };
    if (!params || !params.id) {
      history.push('/#locations');
    }
  }

  handleArchive = event => {
    if (
      window.confirm('Are you sure you want to archive "' + event.title + '"')
    ) {
      const { updateEvent } = this.props;
      updateEvent({
        ...event,
        isArchived: true
      });
    }
  };

  render() {
    const {
      events,
      match: { params }
    } = this.props;
    const { userEmail, eventsData } = this.state;
    const { isBusy } = events[EventsAction.Get];
    /**
     * To let anonymous user use this page
     * when accessing from the Add review page (location info)
     */
    let useEvents = eventsData || events.data;
    if (isBusy && !useEvents.length) {
      return <PageLoading description="" />;
    }
    if (!useEvents.length) {
      return <Redirect to="/#locations" />;
    }
    const event = useEvents.find(item => item.id === params.id);
    if (!event) {
      return <Redirect to={eventsData ? '/' : '/#locations'} />;
    }
    const startTime = toLocal(event.utcStart).format('hh:mm A');
    const endTime = toLocal(event.utcEnd).format('hh:mm A');
    const isArchiving = events[EventsAction.Update].isBusy;
    const isOwner = userEmail === event.userEmail;
    return (
      <>
        <HomeNav />
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={5}>
              <h4 className="mt-2 mb-4 text-center">{event.title}</h4>
              <p>{event.description}</p>
              <div>
                <strong>Starts: </strong> {startTime}
              </div>
              <div>
                <strong>Ends: </strong> {endTime}
              </div>
              {/* <Button className="mt-3 mb-4" outline block color="primary">
                Preview Event 
              </Button> */}

              {isOwner && (
                <Link
                  to={`/event/${event.id}/edit`}
                  className="btn btn-block btn-outline btn-primary mt-3 mb-3"
                >
                  Edit
                </Link>
              )}
              {!event.isArchived && isOwner && (
                <>
                  <p>
                    If you no longer need to have this event live, then you can
                    archive it
                  </p>
                  <Button
                    isBusy={isArchiving}
                    onClick={() => this.handleArchive(event)}
                    color="secondary"
                    block
                  >
                    Archive event
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default connect(
  ({ events }) => ({ events }),
  dispatch => ({
    updateEvent: event => dispatch(eventAction(EventsAction.Update, event))
  })
)(withMessage(EventDetails));
