import React from 'react';

const MAX_STARS = 5;

const StarView = ({ onStar, stars, large }) => {
  const numbers = String(stars).split('.');
  const fullStars = parseInt(numbers[0], 10);
  const views = [];
  let starsCount = 1;
  for (let i = 0; i < fullStars; i++, starsCount++) {
    views.push(
      <ion-icon
        key={`full_${i}`}
        size={large ? 'large' : 'small'}
        stars={starsCount}
        onClick={event => onStar(event.target.getAttribute('stars'))}
        style={{ color: '#763AAE' }}
        name="star"
      />
    );
  }

  if (numbers.length > 1 && parseInt(numbers[1], 10) > 0) {
    views.push(
      <ion-icon
        key="half_star"
        size={large ? 'large' : 'small'}
        stars={starsCount}
        onClick={event => onStar(event.target.getAttribute('stars'))}
        style={{ color: '#763AAE' }}
        name="star-half"
      />
    );
    starsCount++;
  }

  const remaining = MAX_STARS - views.length;
  for (let rem = 0; rem < remaining; rem++, starsCount++) {
    views.push(
      <ion-icon
        key={`empty_${rem}`}
        size={large ? 'large' : 'small'}
        stars={starsCount}
        onClick={event => onStar(event.target.getAttribute('stars'))}
        name="star-outline"
      />
    );
  }

  return <div className="mb-3">{views}</div>;
};

export default StarView;
