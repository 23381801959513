import React from 'react';
import { Row, Col } from 'reactstrap';

const Footer = () => (
  <Row className="justify-content-center">
    <Col sm={8} md={6} lg={4}>
      <p className="mt-5 mb-3 text-muted text-center">&copy; loopHQ</p>
    </Col>
  </Row>
);

export default Footer;
