import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './bootstrap.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
const isDev = process.env.REACT_APP_USE_FIREBASE_DEV === 'true';

ReactDOM.render(
  <BrowserRouter>
    <div>
      {isDev && (
        <div
          className="sticky-top alert alert-warning mb-0 text-center"
          role="alert"
        >
          LoopHQ development environment!
        </div>
      )}
      <div
        className="d-none sticky-top alert alert-danger mb-0 text-center"
        role="alert"
        id="offline"
      />
      <App />
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);

const connection =
  navigator.connection || navigator.mozConnection || navigator.webkitConnection;

const updateConnectionStatus = () => {
  var offline = document.querySelector('#offline');
  if (!offline) {
    return;
  }
  if (!navigator.onLine) {
    offline.innerText = 'Your device is offline.';
    offline.className = 'sticky-top alert alert-danger mb-0 text-center';
  } else {
    offline.innerText = '';
    offline.className = 'd-none sticky-top alert alert-danger mb-0 text-center';
  }
};

document.onreadystatechange = function() {
  if (this.readyState === 'complete') {
    updateConnectionStatus();
  }
};

if (connection) {
  connection.addEventListener('change', updateConnectionStatus);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
