/* eslint-disable default-case */
/* eslint-disable no-lone-blocks */
import { BasicActionType } from '../actions';

class ReducerFactory {
  static stateProps = ['Add', 'Get', 'Remove', 'Update', 'Clear'];

  static makeInitialState(action: BasicActionType, data) {
    const commonState = {
      isBusy: false,
      message: null,
      isSuccess: false
    };
    const initialState = {
      data
    };
    ReducerFactory.stateProps.forEach((prop: String) => {
      if (action[prop]) {
        initialState[action[prop]] = { ...commonState };
      }
    });
    return initialState;
  }

  static makeReducer(
    basicAction: BasicActionType,
    dataStore = [],
    processors: {
      add: Function,
      get: Function,
      remove: Function,
      update: Function
    } = {}
  ) {
    return (
      state: Object = ReducerFactory.makeInitialState(basicAction, dataStore),
      action: Object
    ) => {
      const { type, what } = action;
      const newState = {};
      let data = state.data;
      switch (what) {
        case basicAction.Start:
          newState.isBusy = true;
          newState.isSuccess = false;
          newState.message = null;
          break;
        case basicAction.Success:
          {
            newState.isBusy = false;
            newState.isSuccess = true;
            newState.message = null;
            switch (type) {
              case basicAction.Add:
                if (processors.add) {
                  data = processors.add(data, action.data);
                } else {
                  const set = item => {
                    if (!data.find(entry => entry.id === item.id)) {
                      data = data.concat(item);
                    }
                  };
                  const items = Array.isArray(action.data)
                    ? action.data
                    : [action.data];
                  items.forEach(set);
                }
                break;
              case basicAction.Remove:
                if (processors.remove) {
                  data = processors.remove(data, action.data);
                } else {
                  data = data.filter(d => d.id !== action.data.id);
                }
                break;
              case basicAction.Update: {
                if (processors.update) {
                  data = processors.update(data, action.data);
                } else {
                  data = data.map(d => {
                    if (d.id === action.data.id) {
                      return action.data;
                    }
                    return d;
                  });
                }
                break;
              }
              case basicAction.Get:
                if (processors.get) {
                  data = processors.get(action.data);
                } else {
                  data = action.data;
                }
                break;
              case basicAction.Clear:
                if (processors.clear) {
                  data = processors.clear();
                } else {
                  data = [];
                }
                break;
            }
          }
          break;
        case basicAction.Fail:
          newState.isBusy = false;
          newState.isSuccess = false;
          newState.message = action.message;
          break;
        case basicAction.Reset:
          newState.isBusy = false;
          newState.isSuccess = false;
          newState.message = null;
          break;
        default:
          return state;
      }
      state[type] = newState;
      return { ...state, data };
    };
  }
}

export default ReducerFactory;
