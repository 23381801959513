import React from 'react';
import { connect } from 'react-redux';

import Home from './Home';
import { Subscriptions } from '../components';

const SubscriptionPage = ({ customers, history, ...props }) => {
  return (
    <Home history={history} {...props}>
      <Subscriptions
        customers={customers}
        onAddClick={() => history.push('/subscribe')}
        onRenewClick={subscription =>
          history.push({
            email: localStorage.getItem('authUser'),
            pathname: '/subscribe',
            state: { subscription }
          })
        }
      />
    </Home>
  );
};

export default connect(({ customers }) => ({
  customers
}))(SubscriptionPage);
