import { CustomerActions } from '.';
import { Collections } from '../../firebase';
import firebaseAction from './FirebaseAction';

const customerAction = (type, params) => {
  return dispatch => {
    firebaseAction({
      type,
      dispatch,
      data: params,
      conditions: [
        {
          method: 'where',
          name: 'userEmail',
          operation: '==',
          value: params ? params.userEmail : null
        }
      ],
      action: CustomerActions,
      collection: Collections.Customers
    });
  };
};

export default customerAction;
