import React from 'react';
import { Input } from 'reactstrap';

const Promo = ({
  methods: { onInput },
  promo: { externalLink, externalText }
}) => (
  <>
    <label>External link</label>
    <Input
      type="text"
      id="externalLink"
      className="form-control mb-3"
      placeholder="Enter URL"
      value={externalLink}
      onChange={onInput}
    />
    <label>Link text</label>
    <Input
      type="text"
      id="externalText"
      className="form-control mb-3"
      placeholder="e.g. More info"
      value={externalText}
      onChange={onInput}
    />
  </>
);

export default Promo;
