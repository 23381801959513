import geolib from 'geolib';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { firebase, Collections } from '../../../firebase';
import { setTimes } from '../../events/Helpers';

const fetchLocation = async locationId => {
  const locationSnapshot = await firebase.db
    .collection(Collections.Locations)
    .doc(locationId)
    .get();
  if (!locationSnapshot.exists) {
    throw new Error("Given location doesn't exist.");
  }
  return {
    id: locationId,
    ...locationSnapshot.data()
  };
};

const calculateDistance = (location, lat, lng) => {
  const distance =
    geolib.getDistance(
      {
        latitude: location.coordinates.latitude,
        longitude: location.coordinates.longitude
      },
      {
        latitude: lat,
        longitude: lng
      }
    ) / 1000;
  if (distance > location.radius) {
    return false;
  }
  return distance;
};

const fetchReviews = async locationId => {
  const reviewsSnapshot = await firebase.db
    .collection(Collections.Reviews)
    .where('locationId', '==', locationId)
    .get();
  return reviewsSnapshot.docs.map(reviewSnap => ({
    id: reviewSnap.id,
    ...reviewSnap.data()
  }));
};

const fetchEvents = async locationId => {
  const eventsSnapshot = await firebase.db
    .collection(Collections.Events)
    .where('locationId', '==', locationId)
    // .where('isArchived', '==', false) // This needs to have some more check for effective query
    .orderBy('utcStart')
    .get();
  return eventsSnapshot.docs.map(actSnap => {
    const event = actSnap.data();
    event.id = actSnap.id;
    return setTimes(event);
  });
};

const addReview = async review => {
  const doc = await firebase.db.collection(Collections.Reviews).add(review);
  review.id = doc.id;
  return review;
};

const updateReview = async (reviewId, updates) => {
  return await firebase.db
    .collection(Collections.Reviews)
    .doc(reviewId)
    .update(updates);
};

const removeOrphan = async locationId => {
  return await firebase.db
    .collection(Collections.Locations)
    .doc(locationId)
    .update({ isOrphan: false });
};

const getEventCategories = async () =>
  await firebase.functions.httpsCallable('getCategories')();

const getMatchCategories = async () =>
  await firebase.functions.httpsCallable('getMatchCategories')();

const checkExisting = async (location, email) => {
  if (!location || !location.coordinates) {
    throw new Error('The location must have valid latitude and longitude.');
  }
  let geo = location.coordinates;
  if (!geo.getLatitude) {
    geo = new firebase.firebase.firestore.GeoPoint(geo.latitude, geo.longitude);
  }
  const snap = await firebase.db
    .collection(Collections.Locations)
    .where('coordinates', '==', geo)
    .get();
  let found = 0;
  for (const doc of snap.docs) {
    const data = doc.data();
    if (data.userEmail === email) {
      continue;
    }
    if (data.isOrphan) {
      found = {
        id: doc.id,
        ...data
      };
      break;
    }
    found++;
  }
  return found;
};

const saveClaimMail = async claim => {
  return await firebase.db.collection(Collections.Claims).add(claim);
};

const mapAddressComponents = geoLocation => {
  const location = {};
  const fieldTypes = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'long_name',
    political: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  for (let i = 0; i < geoLocation.address_components.length; i++) {
    const addressType = geoLocation.address_components[i].types[0];
    if (fieldTypes[addressType]) {
      location[addressType] =
        geoLocation.address_components[i][fieldTypes[addressType]];
    }
  }
  return location;
};
const getLocationDetails = address =>
  new Promise((resolve, reject) => {
    let geoLocation = null;
    let locationData = {};
    geocodeByAddress(address)
      .then(results => {
        geoLocation = results;
        getLatLng(results[0])
          .then(latLng => {
            locationData['geometry'] = {
              lat: latLng.lat,
              lng: latLng.lng,
              bounds:
                geoLocation[0].geometry && geoLocation[0].geometry.bounds
                  ? geoLocation[0].geometry.bounds
                  : {}
            };
            locationData.name = address.split(',')[0]; // #6
            locationData.types = geoLocation[0].types; // #63
            // Set the location in the parent ReportFrom
            resolve({
              ...mapAddressComponents(geoLocation[0]),
              ...locationData
            });
          })
          .catch(reject);
      })
      .catch(reject);
  });

const defVal = (obj, prop, def = '') => (prop in obj ? obj[prop] : def);

const findGeo = geometry => {
  if (!geometry) {
    return {
      latitude: -1,
      longitude: -1
    };
  }
  if (geometry.location) {
    return {
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng()
    };
  }
  return {
    latitude: geometry.lat,
    longitude: geometry.lng
  };
};

const buildLocationFromRaw = (
  location,
  isAddressChanged = false,
  existing = {}
) => {
  const builtLocation = {
    ...existing
  };
  const address = `${defVal(location, 'street_number')} ${defVal(
    location,
    'route'
  )}`.trim();

  if (!isAddressChanged) {
    builtLocation.name = defVal(location, 'name').trim();
  }
  builtLocation.types = location.types;
  builtLocation.address = address;
  builtLocation.city =
    location['locality'] ||
    location['political'] ||
    location['administrative_area_level_2'] ||
    '';
  builtLocation.state = defVal(location, 'administrative_area_level_1');
  builtLocation.zip = defVal(location, 'postal_code');
  builtLocation.country = defVal(location, 'country');
  const geo = findGeo(location['geometry']);
  builtLocation.coordinates = new firebase.firebase.firestore.GeoPoint(
    geo.latitude,
    geo.longitude
  );
  return builtLocation;
};

export default {
  mapAddressComponents,
  buildLocationFromRaw,
  getEventCategories,
  getMatchCategories,
  getLocationDetails,
  calculateDistance,
  fetchLocation,
  checkExisting,
  saveClaimMail,
  updateReview,
  fetchReviews,
  removeOrphan,
  fetchEvents,
  addReview
};
