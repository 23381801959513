import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select';
import Switch from 'react-switch';

import { Button, Footer, withMessage, PageLoading } from '../components';
import {
  validate,
  getInitialState,
  // recurringTypes,
  toUTC,
  eventTypes,
  RecurringType,
  loadCategories,
  uploadEventImage,
  getPresetByType,
  toLocal
} from './Helpers';
import { EventsAction, LocationActions } from '../actions';
import eventAction from '../actions/Event';
import Trivia from './categories/Trivia';
import Poll from './categories/Poll';
import Promo from './categories/Promo';
import HomeNav from '../components/HomeNav';
import Sticky from './categories/Sticky';
import Video from './categories/Video';
import Ad from './categories/Ad';
import QuickLink from './categories/QuickLink';
import PreviewCard from './PreviewCard';
import Forms from './categories/Form';
import Match from './categories/Match';
import { db } from '../../firebase/firebase';

export const excludeDates = ['Sticky', 'Ad', 'Video', 'Poll', 'Forms'];
export const isQuickLink = type => type && type.includes('QuickLink');

const typeOptions = eventTypes.map(option => ({
  value: option.value,
  label: option.name
}));

// const recurringOptions = recurringTypes.map(recurringType => ({
//   label: recurringType.name,
//   value: recurringType.value
// }));

const canDelete = (isEdit, type) =>
  isEdit && !['Trivia', 'Poll', 'MatchTest', 'Forms'].includes(type);

class CreateEvent extends React.Component<{
  events: Object,
  saveEvent: Function,
  updateEvent: Function
}> {
  constructor(props) {
    super(props);
    let location = null;
    const {
      location: { state }
    } = props;
    if (state && state.location) {
      location = state.location;
    }
    this.state = {
      hasChanges: false,
      location,
      edit: null,
      wasSubmitted: false, // to know if the form was submitted to prevent showing message
      input: getInitialState()
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    loadCategories();
    this.checkEdit(true);
  }

  handleTypeInput = ({ nativeEvent: { target } }) => {
    const { input } = this.state;
    const { type } = input;
    input[type][target.id] = target.value;
    this.setState({ input, hasChanges: true });
  };

  handleDelete = () => {
    const { showConfirmDelete, input } = this.state;
    const { history } = this.props;
    if (showConfirmDelete) {
      // if already shown and called, delete it
      this.setState({ showConfirmDelete: false });
      db.collection('events')
        .doc(input.id)
        .delete();
      history.push('/');
      return;
    }
    this.setState({ showConfirmDelete: true });
  };

  renderExtraForm = () => {
    const {
      edit,
      input,
      input: { type, utcStart }
    } = this.state;
    if (!type) {
      return null;
    }
    const values = input[type];
    if (type === 'Trivia') {
      const hasTriviaGone =
        edit && moment().isAfter(toLocal(utcStart), 'seconds');
      if (hasTriviaGone) {
        return (
          <Alert color="warning" className="mt-2">
            <span className="text-center">
              The trivia has started. You can no longer modify it.
            </span>
          </Alert>
        );
      }
      return (
        <Trivia
          methods={{
            onInput: this.handleTypeInput
          }}
          trivia={{
            category: [],
            collectEmail: 'optional',
            questionSize: 1,
            questions: [],
            ...values
          }}
        />
      );
    }
    if (type === 'MatchTest') {
      return (
        <Match
          methods={{
            onInput: this.handleTypeInput
          }}
          match={{
            category: [],
            collectEmail: 'optional',
            questionSize: 1,
            questions: [],
            ...values
          }}
        />
      );
    }
    if (type === 'Forms') {
      return (
        <Forms
          methods={{
            onInput: this.handleTypeInput
          }}
          button={values.button || ''}
          fields={values.fields || []}
        />
      );
    }
    if (type === 'Poll') {
      return (
        <Poll
          methods={{
            onInput: this.handleTypeInput
          }}
          poll={{
            question: '',
            answer: '',
            ...values
          }}
        />
      );
    }
    if (type === 'Promo') {
      return (
        <Promo
          methods={{
            onInput: this.handleTypeInput
          }}
          promo={{
            externalLink: '',
            externalText: '',
            imageType: '',
            image: '',
            ...values
          }}
        />
      );
    }
    if (type === 'Sticky') {
      return (
        <Sticky
          methods={{
            onInput: this.handleTypeInput
          }}
          sticky={{
            externalLink: '',
            externalText: '',
            ...values
          }}
        />
      );
    }
    if (type === 'Video') {
      return (
        <Video
          methods={{
            onInput: this.handleTypeInput
          }}
          video={{
            link: '',
            ...values
          }}
        />
      );
    }
    if (type === 'Ad') {
      return (
        <Ad
          methods={{
            onInput: this.handleTypeInput
          }}
          ad={{
            link: '',
            image: '',
            ...values
          }}
        />
      );
    }
    if (isQuickLink(type)) {
      if (!Array.isArray(values.links)) {
        values.links = [];
      }
      const preset = getPresetByType(type);
      return (
        <QuickLink
          methods={{
            onInput: this.handleTypeInput
          }}
          preset={preset}
          links={values.links}
        />
      );
    }
    return null;
  };

  checkEdit(isFromDidMount = false) {
    // for handling the case where user directly access the
    // edit page using URL and the ids
    const { edit } = this.state;
    const {
      events,
      history,
      match: { params }
    } = this.props;
    const { data } = events;
    if (edit || !params || !params.id || !data.length) {
      return;
    }
    const existing = data.find(item => item.id === params.id);
    const set = () =>
      this.setState({
        edit: existing,
        input: {
          ...getInitialState(),
          ...existing
        }
      });
    if (existing) {
      if (isFromDidMount) {
        set();
      } else {
        setTimeout(set);
      }
      return;
    }
    if (!events[EventsAction.Get].isBusy) {
      setTimeout(() => history.push('/event/create'));
    }
  }

  getSnapshotBeforeUpdate = ({ events }) => {
    const wasAdded = events[EventsAction.Add].isSuccess;
    const wasUpdated = events[EventsAction.Update].isSuccess;
    return wasAdded || wasUpdated;
  };

  componentDidUpdate(props, state, snapshot) {
    const { showSuccess, resetState, history } = props;
    if (snapshot) {
      resetState();
      const { edit, hasChanges, wasSubmitted } = state;
      // const {
      //   events: { data }
      // } = this.props;
      if (hasChanges && wasSubmitted) {
        const word = edit ? 'Updated' : 'Saved';
        showSuccess(
          `${word}!`,
          `The event has been ${word.toLowerCase()} successfully.`
        );
        history.push('/');
        // const { id, ...event } = edit || data[data.length - 1];
        // this.setState({
        //   hasChanges: false,
        //   wasSubmitted: false,
        //   edit: { id, ...event },
        //   input: {
        //     ...getInitialState(),
        //     ...input
        //   }
        // });
      }
    }
  }

  handleInput = ({ nativeEvent: { target } }) => {
    this.setState(state => ({
      hasChanges: true,
      input: {
        ...state.input,
        [target.id]: target.value
      }
    }));
  };

  handleChange(checked, event, id) {
    const { input } = this.state;
    input[id] = checked;
    this.setState({ input, hasChanges: true });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { input, hasChanges, edit } = this.state;
    const { showWarning, saveEvent, updateEvent, locations } = this.props;
    if (!hasChanges) {
      showWarning('No changes', 'Please make changes before saving.');
      return;
    }
    let result;
    if ((result = validate(input))) {
      showWarning(result.title, result.message);
      return;
    }

    if (!edit) {
      input.order = this.props.events.data.length + 1;
    }

    this.setState({ wasSubmitted: true });
    input.userEmail = localStorage.getItem('authUser');
    const location = this.getCurrentLocation(locations);
    input.locationId = location.id;
    input.timeZone = moment.tz.guess();
    if (excludeDates.includes(input.type)) {
      /**
       * For sticky dates, we save these values to know when it was created
       */
      input.utcStart = moment
        .utc()
        .toDate()
        .toUTCString();
      input.utcEnd = moment
        .utc()
        .toDate()
        .toUTCString();
    } else {
      input.utcStart = toUTC(input.startDate, input.startTime, input.timeZone);
      input.utcEnd = toUTC(input.startDate, input.endTime, input.timeZone);
    }
    input.isRecurring = input.recurring !== RecurringType.None;
    eventTypes.forEach(eventType => {
      if (eventType.value !== input.type) {
        /**
         * Remove other input except the current event type
         */
        delete input[eventType.value];
      }
    });
    if (input.type === 'Poll') {
      /**
       * Set default vote 0, preserving previous votes
       */
      const defaults = input.Poll.answer.split(',').reduce(
        (defaults, item) => ({
          ...defaults,
          [item.trim()]: 0
        }),
        {}
      );
      input.pollVotes = {
        ...(input.pollVotes || {}),
        ...defaults
      };
    } else if (input.type === 'Trivia') {
      if (!input.Trivia.questions || !input.Trivia.questions.length) {
        showWarning(
          'Questions missing',
          'Please add some questions before saving trivia.'
        );
        return;
      }
    }
    if (['Sticky', 'Ad'].includes(input.type)) {
      this.setState({ isUploading: true });
      try {
        let url = null;
        const isValid = input.type === 'Sticky' && input.Sticky.type !== 'none';
        if (isValid || input.type === 'Ad') {
          const { image } = input[input.type];
          if (image === -1) {
            // remove the image
            input[input.type].image = '';
          } else if (
            typeof image.data === 'string' &&
            !image.data.startsWith('http')
          ) {
            url = await uploadEventImage(image, true);
            input[input.type].image = url;
          }
        }
      } catch (exp) {
        showWarning('Image upload failed', exp.message);
      } finally {
        this.setState({ isUploading: false });
      }
    }

    // donot set string startDate, startTime, endTime in firebase
    const { startDate, startTime, endTime, ...rest } = input;
    if (edit) {
      updateEvent({
        ...edit,
        ...rest
      });
    } else {
      saveEvent(rest);
    }
  };

  getCurrentLocation = locations => {
    const { location, edit } = this.state;
    if (location) {
      return location;
    }
    if (edit) {
      return locations.data.find(location => location.id === edit.locationId);
    }
    return locations.data[0];
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  //Card PReview
  renderCardPreview = location => {
    const { input } = this.state;
    if (input && input.type) {
      return (
        <PreviewCard eventLocation={location} event={input} type={input.type} />
      );
    }
    return null;
  };

  render() {
    const { events, locations } = this.props;
    const isSaving =
      events[EventsAction.Add].isBusy || events[EventsAction.Update].isBusy;
    const isFetching =
      locations[LocationActions.Get].isBusy || events[EventsAction.Get].isBusy;
    if (isFetching && !locations.data.length) {
      return <PageLoading description="" />;
    }
    if (!locations.data.length) {
      return <Redirect to="/" />;
    }
    this.checkEdit();
    const {
      edit,
      input: {
        title,
        description,
        type,
        startDate,
        endDate,
        startTime,
        endTime,
        isArchived,
        utcStart
        // recurring
      },
      isUploading,
      showConfirmDelete
    } = this.state;
    const location = this.getCurrentLocation(locations);
    const hasTriviaGone =
      edit &&
      type === 'Trivia' &&
      moment().isAfter(toLocal(utcStart), 'seconds');
    const isInputDisabled = hasTriviaGone;
    return (
      <>
        <HomeNav />
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={5}>
              <p className="mt-2">
                We are in Beta phase, so not all events are available at the
                moment
              </p>
              <Card className="mb-5 eventEdit">
                <CardHeader>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <strong style={{ flex: 1 }}>Details</strong>
                    {edit && (
                      <>
                        <span>Is Archived? &nbsp; </span>
                        <Switch
                          id="isArchived"
                          onChange={this.handleChange}
                          checked={!!isArchived}
                          height={20}
                        />
                      </>
                    )}
                  </div>
                  <div className="mt-2 text-muted">
                    <span>{edit ? 'Updating' : 'Adding'} event for: </span>
                    <strong>{location.name}</strong>
                  </div>
                </CardHeader>
                <ListGroup className="list-group-flush">
                  <ListGroupItem className="text-left">
                    <Form onSubmit={this.handleSubmit} className="add-event">
                      <label>Module Type</label>
                      <Select
                        onChange={option => {
                          this.handleInput({
                            nativeEvent: {
                              target: {
                                id: 'type',
                                value: option.value
                              }
                            }
                          });
                        }}
                        className="mb-3"
                        id="type"
                        autoFocus
                        value={typeOptions.find(
                          option => option.value === type
                        )}
                        placeholder="Select event type"
                        options={typeOptions}
                        isOptionDisabled={option =>
                          option.label.includes('Coming soon')
                        }
                        isDisabled={edit}
                      />
                      {!!type && !isQuickLink(type) && (
                        <>
                          <label>Event title</label>
                          <Input
                            id="title"
                            className="form-control mb-3"
                            placeholder="Title"
                            required
                            type="text"
                            value={title}
                            disabled={isInputDisabled}
                            onChange={this.handleInput}
                          />
                        </>
                      )}
                      {!!type &&
                        !['Video', 'Ad', 'Poll'].includes(type) &&
                        !isQuickLink(type) && (
                          <>
                            <label>Short Description</label>
                            <textarea
                              id="description"
                              className="form-control mb-3"
                              rows="3"
                              placeholder="Enter a short description"
                              value={description}
                              disabled={isInputDisabled}
                              onChange={this.handleInput}
                            />
                          </>
                        )}
                      {!!type &&
                        !excludeDates.includes(type) &&
                        !isQuickLink(type) && (
                          <>
                            <label>Start date &amp; time</label>
                            <Row>
                              <Col md={6} sm={6}>
                                <Input
                                  id="startDate"
                                  className="form-control mb-3"
                                  placeholder="Date"
                                  required
                                  type="date"
                                  value={startDate}
                                  disabled={isInputDisabled}
                                  onChange={this.handleInput}
                                />
                              </Col>
                              <Col md={6} sm={6}>
                                <Input
                                  id="startTime"
                                  className="form-control mb-3"
                                  placeholder="Time"
                                  required
                                  type="time"
                                  value={startTime}
                                  disabled={isInputDisabled}
                                  onChange={this.handleInput}
                                />
                              </Col>
                            </Row>
                            <label>End date &amp; time</label>
                            <Row>
                              <Col md={6} sm={6}>
                                <Input
                                  id="endDate"
                                  className="form-control mb-3"
                                  placeholder="Date"
                                  required
                                  type="date"
                                  value={endDate}
                                  disabled={isInputDisabled}
                                  onChange={this.handleInput}
                                />
                              </Col>
                              <Col md={6} sm={6}>
                                <Input
                                  id="endTime"
                                  className="form-control mb-3"
                                  placeholder="Time"
                                  required
                                  type="time"
                                  value={endTime}
                                  disabled={isInputDisabled}
                                  onChange={this.handleInput}
                                />
                              </Col>
                            </Row>
                            {/* <label>Recurring Activity</label>
                          <Select
                            onChange={option => {
                              this.handleInput({
                                nativeEvent: {
                                  target: {
                                    id: 'recurring',
                                    value: option.value
                                  }
                                }
                              });
                            }}
                            className="mb-3"
                            id="recurring"
                            value={recurringOptions.find(
                              option => option.value === recurring
                            )}
                            placeholder="Select recurring type"
                            options={recurringOptions}
                          /> */}
                          </>
                        )}
                      {this.renderExtraForm()}
                      {this.renderCardPreview(location)}
                      <Button
                        isBusy={isSaving || isUploading}
                        color="primary"
                        block
                        size="lg"
                        type="submit"
                      >
                        {edit ? 'Update' : 'Create'}
                      </Button>
                      <Button
                        color="secondary"
                        block
                        size="lg"
                        className="btn btn-lg btn-outline-primary btn-block"
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Form>
                    {canDelete(edit, type) && (
                      <span
                        onClick={this.handleDelete}
                        style={{ color: '#7400ae', fontWeight: 'bold' }}
                        className="d-block p-2 m-2 text-center"
                      >
                        Delete module
                      </span>
                    )}
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={showConfirmDelete}>
            <ModalHeader>Delete module?</ModalHeader>
            <ModalBody>
              Are you sure you want to delete
              {title ? ` module "${title}"` : ' this module'}?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ showConfirmDelete: false })}
              >
                No
              </Button>
              <Button color="secondary" outline onClick={this.handleDelete}>
                Yes
              </Button>
            </ModalFooter>
          </Modal>
          <Footer />
        </Container>
      </>
    );
  }
}

export default connect(
  ({ events, locations }) => ({ events, locations }),
  dispatch => ({
    saveEvent: event => dispatch(eventAction(EventsAction.Add, event)),
    updateEvent: event => dispatch(eventAction(EventsAction.Update, event)),
    resetState: () => {
      ['Update', 'Add'].forEach(type =>
        dispatch({
          what: EventsAction.Reset,
          type: EventsAction[type]
        })
      );
    }
  })
)(withMessage(CreateEvent));
