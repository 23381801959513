import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Banner from '../components/OnBoardBanner';
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement
} from 'react-stripe-elements';
import { connect } from 'react-redux';

import { stripeApi } from './StripeApi.js';
import logo from './../assets/images/loophq-icon.svg';
import './index.css';
import { PageLoading, withMessage } from '../components';
import { CustomerActions } from '../actions';
import customerAction from '../actions/Customer';

class Pay extends Component {
  PLAN_QUANTITY = '1';
  email = null;
  trialPeriod = null;
  customerId = null;

  constructor(props) {
    super(props);
    const { history, location } = props;
    this.email = localStorage.getItem('authUser');
    if (!this.email) {
      history.push('/');
    }
    this.trialPeriod = location.state.trialPeriod;
    this.state = {
      isLoading: true,
      isMakingRequest: false,
      hasChanges: false,
      cardError: null,
      planId: '',
      pay: 0,
      referral: ''
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { subscription }
      },
      resetState
    } = this.props;
    this.setState({ planId: subscription });
    const customerResponse = await stripeApi.getCustomer({ email: this.email });
    if (customerResponse.data.length > 0) {
      this.customerId = customerResponse.data.data[0].id;
    }
    const plans = await stripeApi.getSubscriptionPlans();
    const plan = plans.find(plan => plan.id === subscription);
    this.setState({
      isLoading: false,
      pay: plan ? plan.amount / 100 : 0
    });
    resetState();
  }

  getSnapshotBeforeUpdate = props => {
    const { customers } = props;
    return {
      add: customers[CustomerActions.Add],
      update: customers[CustomerActions.Update]
    };
  };

  componentDidUpdate(props, state, snapshot) {
    const { add, update } = snapshot;
    const { resetState, history, showSuccess } = props;
    if ((add.isSuccess || update.isSuccess) && state.hasChanges) {
      resetState();
      this.setState({ hasChanges: false });
      showSuccess(
        `Subcription ${update.isSuccess ? 'updated' : 'created'}`,
        'Your subscription has been saved successfully.'
      );
      history.push('/#subscriptions');
    }
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSubmit = async event => {
    event.preventDefault();
    const {
      stripe,
      createCustomer,
      updateCustomer,
      customers,
      resetState,
      showError
    } = this.props;
    resetState();
    const { referral, planId } = this.state;
    // create token to be passed as payment source to create customer object
    this.setState({ isCallingStripe: true, hasChanges: true });
    try {
      const response = await stripe.createToken();
      if (response.error) {
        this.setState({
          isCallingStripe: false,
          cardError: response.error.message
        });
        showError('Oops', response.error.message);
        return;
      }
      const customerData = {
        email: this.email,
        source: response.token.id
      };

      const subscriptionData = {
        items: {
          0: {
            plan: planId,
            quantity: this.PLAN_QUANTITY
          }
        },
        metadata: {
          customerReferral: referral,
          userEmail: this.email
        },
        customer: null,
        trial_period_days: this.trialPeriod
      };

      let stripeMethod;
      const customer = {
        isTrusted: false
      };
      if (this.customerId) {
        // customer update api call
        const updateResponse = await stripeApi.updateCustomer({
          customerId: this.customerId,
          customerData: { source: customerData.source }
        });
        subscriptionData.customer = updateResponse.data.id;
        stripeMethod = updateCustomer;
        let existing;
        if (
          (existing = customers.data.find(
            custom => custom.customerID === this.customerId
          ))
        ) {
          customer.id = existing.id;
        }
      } else {
        // customer create api call
        const createResponse = await stripeApi.createCustomer(customerData);
        // subscription create api call
        subscriptionData.customer = createResponse.data.id;
        stripeMethod = createCustomer;
      }
      // subscription create api call
      const resp = await stripeApi.createSubscription(subscriptionData);
      const json = resp.data;
      this.setState({ isCallingStripe: false });
      stripeMethod({
        ...customer,
        userEmail: json.metadata.userEmail,
        customerEmail: json.metadata.userEmail,
        customerID: json.customer,
        customerSubscriptionID: json.id,
        customerSubscriptionStatus: json.status,
        customerSubscriptionCreated: json.created,
        customerSubscriptionTrialEnd: json.trial_end,
        customerSubscriptionUpdated: json.current_period_start,
        customerSubscriptionCanceled: json.canceled_at,
        customerSubscriptionReferral: json.metadata.customerReferral || '',
        customerPlanID: json.plan.id,
        customerPlanNickname: json.plan.nickname
      });

    } catch (exp) {
      resetState();
      showError('Oopsies!', exp.message);
      this.setState({
        isCallingStripe: false,
        cardError: exp.message
      });
    }
  };

  render() {
    const { pay, referral, isLoading, isCallingStripe, cardError } = this.state;

    if (isLoading) {
      return (
        <PageLoading description="Checking your existing subscriptions." />
      );
    }
    const { customers } = this.props;
    const isMakingRequest =
      customers[CustomerActions.Add].isBusy ||
      customers[CustomerActions.Update].isBusy ||
      isCallingStripe;
    return (
      <>
      <Banner order={3} />
      <div className="container text-center pt-5 pb-2 stripe">
        <div className="row justify-content-center">
          <div className="col col-sm-8 col-md-6 col-lg-4">
            <div className="pt-1 text-center">
              <img
                className="d-block mx-auto mb-4"
                src={logo}
                alt=""
                width="72"
                height="72"
              />
              <p className="lead" />
              <h2>Payment Info</h2>
              <p>
                <b>Why Do I Need a Credit Card?</b>
                <br />A valid credit card is required to ensure you are a real
                person.{' '}
                <i>
                  Your card will not be charged as long as you cancel during
                  your Trial Period.
                </i>
              </p>
            </div>
            <form onSubmit={this.onSubmit} className="mb-4">
              <CardNumberElement />
              <div className="row">
                <div className="col">
                  <CardExpiryElement />
                </div>
                <div className="col">
                  <CardCVCElement />
                </div>
              </div>
              <input
                maxLength="25"
                type="text"
                name="referral"
                value={referral}
                onChange={this.onChange}
                className="form-control mb-3"
                placeholder="Referral"
              />
              <Button
                className="btn"
                color="primary"
                block
                disabled={isMakingRequest}
                type="submit"
              >
                {!isMakingRequest ? `Free for 30 days, pay $${pay} after.` : 'Processing card...'}
              </Button>

              {cardError && (
                <small className="form-text text-danger">{cardError}</small>
              )}
            </form>
            
            <small>
              By clicking the 'Pay' button, you agree to our{' '}
              <a href="https://loophereandnow.com/terms">Terms of Service</a>{' '}
              and{' '}
              <a href="https://loophereandnow.com/privacy">Privacy Policy</a>.
              You agree that your subscription will begin immediately and that
              you will not have a statutory right to cancel and receive a
              refund. This will not affect your free trial period and you can
              still cancel at any time. You agree that when your free trial
              ends, Cancellation will be effective at the end of each billing
              period. No refunds or credits are given for partially used
              periods.
            </small>
            <p className="mt-5 mb-3 text-muted">&copy; loopHQ</p>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect(
  ({ customers }) => ({ customers }),
  dispatch => ({
    createCustomer: customer =>
      dispatch(customerAction(CustomerActions.Add, customer)),
    updateCustomer: customer =>
      dispatch(customerAction(CustomerActions.Update, customer)),
    resetState: () => {
      dispatch({ type: CustomerActions.Add, what: CustomerActions.Reset });
      dispatch({ type: CustomerActions.Update, what: CustomerActions.Reset });
    }
  })
)(withMessage(injectStripe(Pay)));
