import React from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';
import { Button } from '../../components';

const inputOptions = [
  { value: 'text', label: 'Text Field' },
  { value: 'select', label: 'Dropdown' },
  { value: 'email', label: 'Email' },
  { value: 'textarea', label: 'Multi line field' }
];
const choiceOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
];

const FormField = ({
  method: { onInput, onDelete },
  field: { type, title, isRequired, options }
}) => {
  const defOption =
    inputOptions.find(option => option.value === type) || inputOptions[0];
  return (
    <div style={{ borderBottom: '3px solid #cfcfcf' }} className="mb-2">
      <label>Field type</label>
      <Select
        className="mb-3"
        options={inputOptions}
        defaultValue={defOption}
        onChange={option => {
          onInput({
            nativeEvent: {
              target: {
                id: 'type',
                value: option.value
              }
            }
          });
        }}
      />
      <label>Field title</label>
      <Input
        type="text"
        id="title"
        className="form-control mb-3"
        placeholder="Enter Title"
        defaultValue={title}
        onChange={onInput}
      />
      {type === 'select' && (
        <>
          <label>Options (separate with comma)</label>
          <textarea
            type="text"
            id="options"
            className="form-control mb-3"
            placeholder="E.g. Yes, No, Other"
            defaultValue={options}
            onChange={onInput}
          />
        </>
      )}
      <label>Is the field required?</label>
      <Select
        className="mb-3"
        options={choiceOptions}
        defaultValue={choiceOptions[+(isRequired === 'no')]}
        onChange={option => {
          onInput({
            nativeEvent: {
              target: {
                id: 'isRequired',
                value: option.value
              }
            }
          });
        }}
      />
      <div style={{ textAlign: 'right' }} className="mb-3">
        <Button color="danger" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default FormField;
