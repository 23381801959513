import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import EventCard from './EventCard';
import shareIcon from '../../assets/images/share-icon.svg';
import { shareEvent, makeExternalLink, showLoading } from '../Helpers';

const StickyCard = ({ eventLocation, event }) => {
  const { Sticky } = event;

  if (!Sticky.hasOwnProperty('canShare')) {
    Sticky.canShare = false;
  }
  const hasLink = Sticky.externalText;
  if (Sticky.hasOwnProperty('externalLink')) {
    Sticky.externalLink = makeExternalLink(Sticky.externalLink);
  }

  const renderLink = () =>
    hasLink && (
      <a
        className="btn btn-primary external-link"
        href={Sticky.externalLink}
        //target="_blank"
        rel="noopener noreferrer"
        onClick={showLoading}
      >
        {Sticky.externalText}
      </a>
    );

  if (Sticky.image) {
    const { imageType } = Sticky;
    let { image } = Sticky;
    if (image.data) {
      image = image.data;
    }

    const StyledDiv = (cls, image, imageType) => {
      let setBackgroundImg = false;
      if (imageType === 'small') {
        setBackgroundImg = true;
      } else {
        setBackgroundImg = false;
      }
      return (
        <Card
          className={cls}
          style={setBackgroundImg ? { backgroundImage: `url(${image})` } : {}}
        >
          {!setBackgroundImg && (
            <img className="img-fluid" src={image} alt="" />
          )}
          <CardBody className="text-left">
            {Sticky.canShare && (
              <div className="text-center">
                <Button
                  onClick={() => shareEvent(event, eventLocation)}
                  className="btn-sm float-right shareBtn"
                >
                  <span className="shareBtnIcon">
                    <img
                      alt="share event"
                      className="img-flex"
                      src={shareIcon}
                    />
                  </span>
                </Button>
              </div>
            )}
            <div className="textBG">
              <h4>{event.title}</h4>
              <p>{event.description}</p>
            </div>
            {renderLink()}
          </CardBody>
        </Card>
      );
    };

    if (image && imageType && imageType !== 'none') {
      //Cards with images
      const css = {
        top: 'card-topImg',
        small: 'card-bgImg',
        large: 'card-bgImgLrg',
        large2: 'card-bgImgLrg2',
        largeTop: 'card-bgImgLrg cardTextTop',
        large2Top: 'card-bgImgLrg2 cardTextTop'
        // smallLight: 'card-bgImg-Light',
        // largeLight: 'card-bgImgLrg-Light'
      }[imageType];
      const cls = `mb-4 shadow-sm ${css}`;
      //const cls = `mb-4 shadow-sm ${css} ${!Sticky.canShare && 'noSharing'}`;
      return <div>{StyledDiv(cls, image, imageType)}</div>;
    }
  }

  //Default card without image
  return (
    <EventCard
      showMessage={false} // this is visible to all users, always
      eventLocation={eventLocation}
      event={event}
      hasEnded={false}
    >
      {renderLink()}
    </EventCard>
  );
};

export default StickyCard;
