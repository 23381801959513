import { EventsAction } from '.';
import { Collections } from '../../firebase';
import firebaseAction from './FirebaseAction';

const eventAction = (type, params) => {
  return dispatch => {
    firebaseAction({
      type,
      dispatch,
      data: params,
      conditions: [
        {
          method: 'where',
          name: 'userEmail',
          operation: '==',
          value: params ? params.userEmail : null
        }
      ],
      action: EventsAction,
      collection: Collections.Events
    });
  };
};

export default eventAction;
