import React from 'react';
import { connect } from 'react-redux';

import { showError, showSuccess, showWarning } from '../actions/Message';

const withMessage = WrappedComponent => {
  const MessagePage = props => <WrappedComponent {...props} />;
  const ConnectedMessagePage = connect(
    null,
    dispatch => ({
      showError: (title, message) => dispatch(showError(title, message)),
      showSuccess: (title, message) => dispatch(showSuccess(title, message)),
      showWarning: (title, message) => dispatch(showWarning(title, message))
    })
  )(MessagePage);
  return props => <ConnectedMessagePage {...props} />;
};

export default withMessage;
