import React from 'react';
import moment from 'moment';
import { Card, CardBody, Alert, Button } from 'reactstrap';

import shareIcon from '../../assets/images/share-icon.svg';
import { shareEvent, toLocal } from '../Helpers';

const EventCard = ({
  event,
  eventLocation,
  showMessage,
  hasEnded,
  children
}) => {
  if (event.utcStart) {
    const date = toLocal(event.utcStart);
    const todayDate = moment();
    const isUpcoming = date.isAfter(todayDate);
  }
  const isUpcoming = false;
  const noShare = event.Sticky && event.Sticky.canShare === false;
  const cls = `text-left mb-4 ${hasEnded ? 'ended' : ''} ${noShare &&
    'noSharing'}`;
  return (
    <Card className={cls}>
      <CardBody>
        {!showMessage && !hasEnded && !noShare && (
          <div className="text-center">
            <Button
              onClick={() =>
                shareEvent(
                  event,
                  eventLocation,
                  isUpcoming ? 'today-upcoming' : 'active'
                )
              }
              className="btn-sm float-right shareBtn"
            >
              <span className="shareBtnIcon">
                <img alt="share event" className="img-flex" src={shareIcon} />
              </span>
            </Button>
          </div>
        )}
        <h4>{event.title}</h4>
        <p>{event.description}</p>
        {children}
        {showMessage && (
          <Alert color="info" className="mt-2">
            <span>
              You must be at {eventLocation.name} to
              <strong> participate</strong>
            </span>
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

export default EventCard;
