import React from 'react';

const Poll = ({ methods: { onInput }, poll: { question, answer } }) => {
  return (
    <div className="mb-2">
      <label>Poll Question</label>
      <textarea
        className="form-control mb-3"
        required
        rows="3"
        id="question"
        onChange={onInput}
        value={question}
        placeholder="Enter a short description of the event"
      />
      <label>Poll Answers (separate with comma)</label>
      <textarea
        className="form-control mb-3"
        required
        rows="3"
        id="answer"
        onChange={onInput}
        value={answer}
        placeholder="E.g. Yes,No,Other"
      />
    </div>
  );
};

export default Poll;
