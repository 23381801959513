/**
 * Communicates with firebase to save/update the vote of the user
 */
import { firebase, Collections } from '../../../firebase';

const makeId = input => input.eventId + '_' + input.userId;
/**
 *
 * @param Object response { eventId, userId, answer }
 */
const savePollVote = response =>
  firebase.db
    .collection(Collections.Votes)
    .doc(makeId(response))
    .set(response);

/**
 *
 * @param Object query { eventId, userId }
 */
const getVote = async query => {
  const vote = await firebase.db
    .collection(Collections.Votes)
    .doc(makeId(query))
    .get();
  if (vote.exists) {
    return vote.data().answer;
  }
  return null;
};

export { savePollVote, getVote };
