const setNewUser = isNewUser => ({
  type: 'UPDATE_NEW_USER',
  payload: isNewUser
});

export const setHideBanner = hideBanner => ({
  type: 'HIDE_BANNER',
  payload: hideBanner
});

export default setNewUser;
