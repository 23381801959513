import React from 'react';
import { Container } from 'reactstrap';

import logo from '../assets/images/loophq-icon.svg';
import spinner from '../assets/images/hq-loading-spinner.svg';

const PageLoading = ({ description }) => (
  <Container className="text-center">
    <div className="pb-2 pt-5 text-center">
      <img
        className="d-block mx-auto mt-2 mb-2"
        src={logo}
        alt=""
        width="72"
        height="72"
      />
      <h3>{description}</h3>
    </div>
    <div className="row justify-content-center">
      <div className="col col-sm-8 col-md-6 col-lg-4">
        <img src={spinner} alt="spinner" />
      </div>
    </div>
  </Container>
);

export default PageLoading;
