/**
 * Handle incoming URLs from firebase
 */
import React from 'react'; // eslint-disable-line

const Handle = ({ history }) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  switch (params.get('mode')) {
    case 'resetPassword':
      history.push({
        pathname: '/reset',
        state: {
          code: params.get('oobCode')
        }
      });
      break;
    default:
      history.push('/');
  }
  return null;
};

export default Handle;
