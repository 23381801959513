import { auth } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = async (email, password) =>
  await auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = async email =>
  await auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = async password =>
  await auth.currentUser.updatePassword(password);
